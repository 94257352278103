import React from "react";
import { Link } from "react-router-dom";
import { HashLink as LinkHash } from "react-router-hash-link";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Line } from "styles/Styled";
import logo from "media/icons/whiteLogo.png";

import Styled, { LinkClassName, Logo } from "./styles";

library.add(fab);

const FooterMobile = () => {
    return (
        <Styled.ContainerFooter
            id="BuyBitcoin"
            style={{ backgroundColor: "#1A4BEA" }}
        >
            <Styled.FooterNav>
                <Styled.ContainerSectionFooter>
                    <Styled.FooterHeading>Lima - Perú</Styled.FooterHeading>
                    <Styled.FooterMenu>
                        <Styled.LinkFooter> © 2022 AgenteBTC</Styled.LinkFooter>
                        <Styled.LinkFooter>
                            <img
                                style={{ width: 13 }}
                                src={require("../../media/icons/PhoneFooterIcon.png")}
                            />{" "}
                            975-183-710
                        </Styled.LinkFooter>
                        <Styled.LinkFooterA href="https://play.google.com/store/apps/details?id=com.agentebtc.appagentebtc&hl=es">
                            <img 
                                src="https://i.imgur.com/3ahEEX3.png" 
                                href="https://play.google.com/store/apps/details?id=com.agentebtc.appagentebtc&hl=es" 
                                style = {{width: 160, marginLeft: -13, marginTop: -10}}
                            >
                            </img>
                        </Styled.LinkFooterA>
                    </Styled.FooterMenu>
                </Styled.ContainerSectionFooter>
                <Styled.ContainerSectionFooter>
                    <Styled.FooterHeading>Productos</Styled.FooterHeading>
                    <Styled.FooterMenu>
                        <Styled.LinkFooter to="/como-empezar">
                            Cómo empezar
                        </Styled.LinkFooter>
                        <Styled.LinkHash to="/wallet">Wallet</Styled.LinkHash>
                        {/* link to what */}
                        {/* link to what */}
                        <Styled.LinkFooter to="">
                            USDT (Stablecoin)
                        </Styled.LinkFooter>
                    </Styled.FooterMenu>
                </Styled.ContainerSectionFooter>
                <Styled.ContainerSectionFooter>
                    <Styled.FooterHeading>Recursos</Styled.FooterHeading>
                    <Styled.FooterMenu>
                        <Styled.LinkHash to="/conoce-bitcoin#uses-cases-bitcoin">
                            Casos de uso
                        </Styled.LinkHash>
                        <Styled.LinkFooter to="/conoce-bitcoin">
                            Conoce Bitcoin
                        </Styled.LinkFooter>
                        <Styled.LinkFooterA
                            href={"https://blog.agentebtc.com/"}
                        >
                            Blog
                        </Styled.LinkFooterA>
                        <Styled.LinkFooter to="/desarrolladores">
                            Desarrolladores
                        </Styled.LinkFooter>
                        <Styled.LinkFooter to="/comisiones">
                            Comisiones
                        </Styled.LinkFooter>
                    </Styled.FooterMenu>
                </Styled.ContainerSectionFooter>
                <Styled.ContainerSectionFooter>
                    <Styled.FooterHeading>Compañia</Styled.FooterHeading>
                    <Styled.FooterMenu>
                        <Styled.LinkFooter to="/nosotros">
                            Nosotros
                        </Styled.LinkFooter>
                        <Styled.LinkFooter to="/politicas-kyc-aml">
                            KYC
                        </Styled.LinkFooter>
                        <Styled.LinkFooter to="/terminos-y-condiciones">
                            Terminos
                        </Styled.LinkFooter>
                        <Styled.LinkFooter to="/politica-de-privacidad">
                            Politica de protección
                        </Styled.LinkFooter>
                    </Styled.FooterMenu>
                </Styled.ContainerSectionFooter>
                <Styled.ContainerSectionFooter>
                    <Styled.FooterHeading>Comunidad</Styled.FooterHeading>
                    <Styled.FooterMenu>
                        {/* link to what */}
                        <Styled.LinkFooter>
                            Sigamos conectados a través <br /> de nuestras redes
                        </Styled.LinkFooter>
                        <div style={{ paddingTop: 25 }}>
                            <div style={{ justifyContent: "space-around" }}>
                                <Styled.SocialLinkFooter
                                    href="https://www.youtube.com/c/AgenteBTC"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="socialLink youtube"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "youtube"]}
                                        className="iconFooter"
                                        style={{ color: "white" }}
                                    />
                                </Styled.SocialLinkFooter>
                                <Styled.SocialLinkFooter
                                    href="https://www.facebook.com/agentebtc"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="socialLink facebook"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "facebook-square"]}
                                        className="iconFooter"
                                        style={{ color: "white" }}
                                    />
                                </Styled.SocialLinkFooter>
                                {/* Link to twitter */}
                                <Styled.SocialLinkFooter
                                    href="https://twitter.com/agentebtc?t=g-gFA4p7tFmgYHJO6sZTvQ&s=08/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="socialLink twitter"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "twitter"]}
                                        className="iconFooter"
                                        style={{ color: "white" }}
                                    />
                                </Styled.SocialLinkFooter>
                            </div>
                            <div>
                                <Styled.SocialLinkFooter
                                    href="https://www.linkedin.com/company/agentebtc/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="socialLink linkedin"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "linkedin"]}
                                        style={{ color: "white" }}
                                        className="iconFooter"
                                    />
                                </Styled.SocialLinkFooter>

                                <Styled.SocialLinkFooter
                                    href="https://api.whatsapp.com/send?phone=51975183710"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="socialLink WhatsApp"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "whatsapp"]}
                                        style={{ color: "white" }}
                                        className="iconFooter"
                                    />
                                </Styled.SocialLinkFooter>
                            </div>
                        </div>
                    </Styled.FooterMenu>
                </Styled.ContainerSectionFooter>
            </Styled.FooterNav>
            <Styled.FooterCopy>
                <Logo
                    style={{ width: 240, maxWidth: 240 }}
                    src={logo}
                    alt="AgenteBTC criptomonedas"
                />
            </Styled.FooterCopy>

            <Styled.FooterAviso>
                AgenteBTC y sus empresas filiales no son entidades reguladas
                ante el regulador financiero local. Sin perjuicio de lo
                anterior, mantenemos estándares de cumplimiento normativo
                basados en la regulación vigente en cada país que operamos, y
                estamos sujetos a la legislación pertinente en matería de
                protección de derechos del consumidor, tratamiento de datos e
                información privada y obligaciones tributarias, entre otras.
                <br></br>Con el objetivo de que la industria se desarrolle en
                forma responsable, es importante que todos nuestros usuarios
                sepan que: i. Las criptomonedas no son una moneda de curso legal
                ni están respaldadas por un Banco Central; ii. Una vez
                completada debidamente una transferencia de criptomonedas, es
                imposible revertirla; iii. Las criptomonedas son activos cuyo
                precio es alcanzado exclusivamente por oferta y demanda,
                pudiendo ser altamente volátil; iv. Las criptomonedas están
                afectas a los riesgos tecnológicos, cibernéticos y de fraude
                inherentes a cualquier actividad transaccional virtual.
            </Styled.FooterAviso>
        </Styled.ContainerFooter>
    );
};

export default FooterMobile;
