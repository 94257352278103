import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import colors from "styles/common/colors";
import img from "../../media/home/media_background.png";
import img2 from "../../media/media_icons/carusele_section_bcg.png";

export const CalculatorContainer = styled.div`
    max-width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        order: 2;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media screen and (max-width: 640px) {
        order: 2;
        padding: 0px;
        padding-top: 20px;
    }
`;

export const HomeContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    margin-top: -80px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: rgba(255, 200, 92, 0.5);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 200, 92, 0.2) 40%,
        rgba(255, 200, 92, 0.2) 60%,
        rgba(255, 255, 255, 0.3) 100%
    ); */
    @media screen and (max-width: 640px) {
        margin-top: -30px;
    }
`;

const TaglineHome = styled.h3`
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-weight: normal;
    margin: 0;
    font-weight: 700;
    @media screen and (max-width: 640px) {
        margin-top: 10px;
        font-size: 25px;
        font-weight: 600;
    }

    @media screen and (max-width: 320px) {
        margin-top: 10px;
        font-size: 18px;
    }
`;

const SubtaglineHome = styled.h5`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    margin-top: 40px;
    color: gray;
    @media screen and (max-width: 1080px) {
        margin-top: 10px;
        font-size: 20px;
    }

    @media screen and (max-width: 420px) {
        margin-top: 10px;
        font-size: 14px;
        display: inline-block
        word-break: break-all;
        white-space:normal;
    }
`;

export const HeroSection = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
    margin-top: 120px;

    @media (max-width: 990px) {
        margin-top: 80px;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const SectionTwoBox = styled.section`
    display: flex;
    margin-right: 10%;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;

    @media (max-width: 640px) {
        margin-right: 0%;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const HeroText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 1 100%;
    margin-left: 70px;
    padding: 0 12px;

    @media (max-width: 640px) {
        margin-left: 0px;
        order: 1;
        flex: 0 1 100%;
        align-items: center;
        text-align: center;
    }
`;

const SectionTwo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: -30%;
    flex: 0 1 100%;
    padding: 0 12px;

    @media (max-width: 640px) {
        margin-left: 0%;
        order: 1;
        flex: 0 1 100%;
        align-items: center;
        text-align: center;
    }
`;

export const FacilText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 640px) {
        align-items: center;
        text-align: center;
    }
`;

const HeroImgWrapper = styled.div`
    flex: 0 1 45%;

    @media (max-width: 640px) {
        order: 0;
        flex: 0 1 100%;
    }
`;

const HeroImg = styled.img`
    width: 100%;
    max-width: 600px;
`;

const HomeImg = styled.img`
    width: 100%;
    @media (max-width: 640px) {
        display: none;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 100%;
    margin-top: 20px;

    @media (max-width: 640px) {
        margin-top: 10px;
        flex-direction: column;
    }
`;

const StyledButtonBlue = styled(Link)`
  color: white;
  background: ${colors.blue_agente_btc};
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {

    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonBlueExt = styled.a`
  color: #3E5158;
  background: ${colors.yellow_agente_btc};
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  font-weight: 600;
  padding: 10px 35px;
  @media (max-width: 640px) {
    margin-top: 30px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px
    width: fit-content;
    height: 35px;
  }

  @media (max-width: 370px) {
    margin-top:10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: fit-content;
    height: 20px;
    line-height: 1;
  }
`;

const StyledButtonYellow = styled(Link)`
  color: #3C4A5B;
  background: ${colors.yellow_agente_btc};
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  display: flex;
  justify-content: center;
  border-radius: 70px
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    display: flex;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonYellowExt = styled.a`
  color: #3C4A5B;
  background: ${colors.yellow_agente_btc};
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {

    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonYellowSec = styled(StyledButtonYellow)`
    border-radius: 8px;
`;

const StyledButtonYellowSecExt = styled(StyledButtonYellowExt)`
    border-radius: 8px;
`;

const StyledButtonTransparent = styled(Link)`
  background: trasparent;
  color: ${colors.dark_blue_agente_btc};
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 1 0px;
  @media (max-width: 640px) {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px
    width: 120px;
    height: 35px;
  }
`;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    @media screen and (max-width: 900px) {
        display: block;
        padding: 0px;
    }
`;

const SingleCard = styled.div`
    box-shadow: 1px 1px 1px 1px #eeeeee;
    width: 33%;
    height: fit-content;
    border: 1px solid #F2F2F2;
    margin-inline: 20px;
    text-align: center;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 85%;
        margin-block-end: 10px;
        height: fit-content;
    }
    @media (max-width: 640px) {
        width: 85%;
    }
`;

const CardTitle = styled.p`
    color: #1a4bea;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    margin-block-start: 0px;
    font-weight: 500;
    @media (max-width: 640px) {
        font-size: 14px;
    }
`;

const CardContent = styled.p`
    color: #3e5158cc;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    text-align: left;
    @media (max-width: 640px) {
        font-size: 17px;
    }
`;

// Seccion Calculadora

const WrapperCalculatorSection = styled.div`
    width: 100%;
    //max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding: 60px 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    background-color: #fafafa
    @media (max-width: 900px) {
        flex-direction: column;
        padding: 50px 24px;
    }
`;
const SectionTwoImg = styled.img`
    width: 70%;
    @media (max-width: 640px) {
        width: 100%;
    }
`;

const VideoContainer = styled.div`
    padding: 45px;

    @media (max-width: 990px) {
        padding: 20px;
    }
`;

const VideoContainerFrame = styled.iframe`
    width: 560px;
    height: 315px;
    border-style: none;

    @media (max-width: 920px) {
        width: 100%;
    }
    
    @media (max-width: 460px) {
        width: 100%;
    }

    @media (max-width: 370px) {
        width: 100%;
        height: auto;
    }
`;

const WrapperCalculatorSectionCarusele = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const WrapperCalculatorSectionImage = styled.div`
    width: 100%;
    padding-bottom: 5%;
    background-repeat: round;
    background-color: rgb(244, 250, 255);
    background-image: url(${img});
`;

const WrapperBackgroundImageLastSection = styled.div`
    width: 100%;
    padding-bottom: 5%;
    background-repeat: round;
    background-image: url(${img2});
`;

const ContainerAboutSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justfify-content: flex-start;
`;

const ContainerLogos = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    transition: 0.5s;
    &:hover {
        transform: scale(0.9);
    }
`;

const CardsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    transition: 0.5s;
    margin-bottom: 0px;
    &:hover {
        transform: scale(0.9);
    }
    @media (max-width: 640px) {
        margin-bottom: 20px;
    }
`;

const ContainerLogosWithoutTransition = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
`;

const EndingContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
    @media screen and (max-width: 768px) {
        padding-left: 0;
        align-items: center;
    }
`;

const CircleCounter = styled.a`
    border-radius: 100%;
    background-color: blue;
    font-family: "Poppins", sans-serif;
    width: 30px;
    height: 30px;
    line-height: 2;
    margin-bottom: -10px;
`;

const ImageDetail = styled.img`
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    margin-right: 10px;
`;

const ImageDetailStep = styled.img`
    align-self: center;
`;

const ImageBanks = styled.img`
    width: 150px;
    flex-shrink: 0;
`;

const SectionsButton = styled.a`
    align-self: center;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    margin-right: 10px;
    padding: 10px;
    color: #1a4bea;
    font-weight: bold;
`;

const ContainerDetail = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: flex-start;
    margin: 40px 0px;
`;

const ContainerRight = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justfify-content: flex-start;
    padding-left: 10px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

// Generales

const ContainerSectionHeader = styled.div`
    width: 100%;
    text-align: center;
`;

const ContainerSectionBody = styled.div`
    width: 100%;
    padding-right: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justfify-content: flex-start;
    text-align: center;
`;

// Waves

const SectionWave = styled.img`
    width: 100%;
    display: flex;
`;

const SectionGrayBg = styled.div`
    width: 100%;
    background-color: #eaf1f6;
`;

const SectionBlueBg = styled.div`
    width: 100%;
    background-color: #1a4bea;
    color: #ffffff !important;
    padding-top: 50px;
    padding-bottom: 60px;
`;

// Press Logos

const PressTitle = styled.div`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #3c4a5b;
    padding-top: 50px;
`;

const PressSection = styled.div`
    width: 70%;
    box-sizing: border-box;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 900px) {
        flex-direction: column;
        padding: 0 24px;
    }
`;

const StepperSection = styled.div`
    width: 100%;
    color: black;
    box-sizing: border-box;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 640px) {
        justify-content: center;
    }
`;

const MobileStepper = styled.div`
    display: none;
    @media (max-width: 640px) {
        display: flex;
        width:100%;
        justify-content: center;
    }
`;

const WebStepper = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 640px) {
        display: none;
    }
`;

const SectionTwoText = styled.p`
    color: black;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
`;


//New landing

const MainTextLandingSmaller = styled.span`
	color: ${colors.dark_blue_agente_btc};
	font-family: "Poppins", sans-serif;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 45px;
	line-height: 0.8;
    @media (max-width: 1080px) {
        font-size: 35px;
    }

	@media (max-width: 768px) {
		font-size: 30px;
	}
	
`;

const SecondaryTextLandingSmaller = styled.span`
    color: ${colors.yellow_agente_btc};
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 45px;
    line-height: 0.8;
    @media (max-width: 1080px) {
        font-size: 35px;
    }

    @media (max-width: 768px) {
        font-size: 30px;
    }

`;

const TaglineSpan = styled.span`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    margin-top: 40px;
    color: grey;
    @media (max-width: 1080px) {
        margin-top: 10px;
        font-size: 14px;
        display: inline-block
        word-break: break-word;
    }

    @media (max-width: 768px) {
        margin-top: 10px;
        font-size: 15px;
    }
`;

const ButtonAppContainer = styled.div`
    width: 100%;

    @media (max-width: 640px) {
        text-align:center;
        display: flex;
        justify-content: center;
    }
`;

const ButtonAppImg = styled.img`
    width: 230px;
    margin-left: -10px;
    

    @media (max-width: 1080px) {
    
    }

    @media (max-width: 640px) {
        width: 200px;
        display: flex;
        justify-content: center;
        // margin: auto;
    }
`;

const VideoInfoContainer = styled.div`
    background-color: #e6e6e6
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0px 0px 1em 1em;
    transform: translateY(-7px);

    @media (max-width: 1080px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

const VideoInfoSymbol = styled.img`
    width: 4em;
    height: 4em;
    padding: 5px 10px;
    vertical-align: middle;

    @media (max-width: 1080px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 4em;
        height: 4em;
        padding: 0px;
        margin: auto;
    }
`;

const VideoInfoText = styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: #748183

    @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-top: -3px;
        padding: 0px 3px;
        word-brake: break-word;
        text-align: center;
    }

`;

const VideoInfoTextSpan = styled.span`
    color: ${colors.dark_blue_agente_btc};
    font-weight: 600;
`;

const CarusaleContainer = styled.div`
    background-color: #fafafa;
    width: 100%;
`;

const RechargeWalletWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    height: 400px;
    box-sizing: border-box;
    //padding: 60px 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    background-color: #1a4bea;

    @media (max-width:768px) {
        flex-direction: column;
        padding: 0;
    }
`;

const RechargeWalletTextWrapper = styled.div`
    margin: 25px 0px 25px 60px;
    width: auto;

`;

const RechargeWalletTextContainer = styled.div``;

const RechargeWalletText = styled.div``;

const RechargeWalletInfo = styled.p`
    color: white;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    font-size: 23px;
    
    @media( max-width: 1080px) {
        
    }

    @media( max-width: 768px) {

    }
`;

const RechargeWalletPrimaryText = styled.span`
    font-family: "Poppins", sans-serif;    
    color: white;
    font-size: 45px;
    font-weight: 600;

    @media( max-width: 1080px) {
        
    }

    @media( max-width: 768px) {
        font-size: 25px;
    }
`;

const RechargeWalletSecondaryText = styled.span`
    font-family: "Poppins", sans-serif;    
    color: #fec500;
    font-size: 45px;
    font-weight: 700;

    @media( max-width: 1080px) {
        
    }

    @media( max-width: 768px) {
        font-size: 25px;
        word-break: break-all;
        width: 100vw;
    }
`;

const RechargeWalletBtnContainer = styled.div`
    //background-color: #1a4bea;
    max-width: 100%;
    max-height: 400px;
    height: auto;
    //position: relative;
`;

const RechargeWalletButton = styled.div`
    border-radius: 0.8em;
    background: linear-gradient(#FFC500,#F7931E);
    color: #3E5158;
    width: 400px;
    height: 40px;
    padding: 15px 0px 0px 10px;
    margin: 10px 0px;
    font-weight: 600;
`;

const RechargeWalletBtnInfo = styled.a`
    width: 400px;
    margin: 10px 0px;
    font-size: 18px;
    text-decoration: underline;
    padding: 0px 5px;
`;

const RechargeWalletBanner = styled.img`
    // width: auto;
    // height: 500px;
    // transform: translate(70%, -47.9%);
`;

const MapWrapper = styled.div`
    width: 100%;
    height: 600px;
    background-image: url("https://i.imgur.com/vEgdRrk.png");
    background-position: center;
    padding-bottom: 5%;
    background-repeat: round;
    background-color: rgb(244, 250, 255);
`;

const MapContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const MapImg = styled.img`
    height: 600px;
    margin: 30px 100px;
`;

const MapTextWrapper = styled.div`
    margin-top: 60px;
    margin-left: auto;
    margin-right: 60px;
    `;

const MapTextContainer = styled.div`
    display: flex; 
    flex-direction: column;
`;

const MapTextUpperContainer = styled.p``;

const MapTextUpper = styled.p`
    font-family: "Poppins", sans-serif;
    color: #3e5158cc;
    font-size: 22px;
    text-align: center;
`;

const MapTextUpperBold = styled.span`
    font-weight: 600; 
    color: #3E5158;
`;

const MapButtonStyledText = styled.p`
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 30px;
    font-style: italic;
    background-color: #ffc600;
    border-radius: 0.5rem;
    text-align: center;
    padding: 2px 10px;
    color: #3E5158;
    width: 350px;
    margin -20px auto -20px;
`;

const MapTextImportantContainer = styled.p`
    font-family: "Poppins", sans-serif;
    color: #999999;
    font-weight: 600;
    font-size: 40px;
`;

const MapTextImportantUpper = styled.div``;

const MapTextImportantUpperBigger = styled.span`
    font-family: Poppins, sans-serif;
    color: #999999;
    font-weight: bold;
    font-size: 50px;
`;

const MapTextImportantUpperEmoji = styled.img`
    width: 50px; 
    transform: translateY(20px);
`;

const MapTextImportantLower = styled.div`
    margin-bottom: -125px;
`;

const MapTextImportantLowerSpan1 = styled.span`
    font-size: 120px; 
    color: #1a4bea; 
    font-style: italic; 
    font-weight: bolder;
`;
const MapTextImportantLowerSpan2 = styled.span`
    transform: translateY(-45px);
    margin-left: -25px; 
    font-size: 60px; 
    color: #1a4bea; 
    font-weight: bolder; 
    display: inline-block;
`;
const MapTextImportantLowerSpan3 = styled.span`
    display: block;
    font-size: 26px;
    transform: translate(205px, -78px);
`;

const AppBlock = styled.img`
    width: 300px;
    margin: 0 auto;
`;

const MapLeftTextWrapper = styled.div`
    transform: translate(-190%, 25px);
`;
const MapLeftTextContainer = styled.div`
    padding: 5px 10px;
    display: inline-block;
    color: #999999;
    margin-top: -50px;
    text-align: right;
`;

const MapLeftText = styled.p`
    font-size: 35px;
    align-items:center;
    justify-content:center;
    font-weight: 700;
    margin: -10px 0;
`;

const MapLeftTextSpan = styled.span`
    font-size: 12px;
    margin-top: -40px;
    padding: 0;
    
`;



export default {
	HomeContainer,
	MobileStepper,
	WebStepper,
	TaglineHome,
	SubtaglineHome,
	HeroSection,
	HeroText,
	FacilText,
	HeroImgWrapper,
	HeroImg,
	StyledButtonBlue,
	StyledButtonBlueExt,
	SectionTwoText,
	StyledButtonYellow,
	StyledButtonYellowExt,
	StyledButtonYellowSec,
	StepperSection,
	SectionTwoBox,
	SectionTwoImg,
	SectionTwo,
	StyledButtonYellowSecExt,
	StyledButtonTransparent,
	ContainerButtons,
	WrapperCalculatorSection,
	WrapperCalculatorSectionImage,
	ContainerAboutSection,
	ImageDetail,
	ImageBanks,
	CircleCounter,
	HomeImg,
	ImageDetailStep,
	ContainerDetail,
	ContainerRight,
	ContainerSectionHeader,
	CardContainer,
	SingleCard,
	CardContent,
	WrapperCalculatorSectionCarusele,
	ContainerSectionBody,
	SectionWave,
	SectionGrayBg,
	SectionBlueBg,
	CardsContainer,
	ContainerLogos,
	ContainerLogosWithoutTransition,
	WrapperBackgroundImageLastSection,
	CalculatorContainer,
	PressSection,
	EndingContainer,
	PressTitle,
	SectionsButton,
	CardTitle,
	VideoContainer,
    MainTextLandingSmaller,
	SecondaryTextLandingSmaller,
    TaglineSpan,
    ButtonAppContainer,
    ButtonAppImg,
    VideoInfoContainer,
    VideoInfoSymbol,
    VideoInfoText,
    VideoInfoTextSpan,
    VideoContainerFrame,
    CarusaleContainer,
    RechargeWalletWrapper,
    RechargeWalletTextWrapper,
    RechargeWalletTextContainer,
    RechargeWalletText,
    RechargeWalletInfo,
    RechargeWalletPrimaryText,
    RechargeWalletSecondaryText,
    RechargeWalletBtnContainer,
    RechargeWalletButton,
    RechargeWalletBtnInfo,
    RechargeWalletBanner,
    MapWrapper,
    MapContainer,
    MapImg,
    MapTextWrapper,
    MapTextContainer,
    MapTextUpperContainer,
    MapTextUpper,
    MapTextUpperBold,
    MapButtonStyledText,
    MapTextImportantContainer,
    MapTextImportantUpper,
    MapTextImportantUpperBigger,
    MapTextImportantUpperEmoji,
    MapTextImportantLower,
    MapTextImportantLowerSpan1,
    MapTextImportantLowerSpan2,
    MapTextImportantLowerSpan3,
    AppBlock,
    MapLeftTextWrapper,
    MapLeftTextContainer,
    MapLeftText,
    MapLeftTextSpan,
};
