let GACode = "";
switch (process.env.REACT_APP_ENV_TYPE) {
  case 'development':
    GACode = "UA-152651988-3"
    break;
  case 'staging':
    GACode = "UA-152651988-3"
    break;
  case 'production':
    GACode = "UA-152651988-2"
    break;
  default:
    GACode = "UA-152651988-5"
}

export default GACode;
