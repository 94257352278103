import styled from "styled-components";
import colors from "./common/colors";
import Select from "react-select";

/*= ====== NAVIGATION ======= */

export const StyledSelect = styled(Select)`
    height: 0px;
    display: hidden;
`;

export const UserInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const UserEmail = styled.span`
    color: #f2a900;
    font-weight: 600;

    @media screen and (max-width: 640px) {
        font-size: 20px;
    }
`;

export const Heading1 = styled.h1`
    font-size: 54px;
    font-family: Poppins, sans-serif;
    color: #f2a900;
    font-weight: 600;
    margin: 0;

    @media (max-width: 320px) {
        font-size: 38px;
    }
`;

export const HeadingPages = styled.h1`
    font-size: 48px;
    font-family: Poppins, sans-serif;
    color: #f2a900;
    font-weight: 600;
    margin: 0;
    text-align: center;

    @media (max-width: 640px) {
        font-size: 38px;
    }

    @media (max-width: 320px) {
        font-size: 32px;
    }
`;

export const SubHeading = styled.h2`
    font-family: Poppins, sans-serif;
    text-align: center;
    font-size: 24px;
    margin-top: 0;

    @media (max-width: 640px) {
        font-size: 19px;
    }

    @media (max-width: 320px) {
        font-size: 17px;
    }
`;

export const Heading2 = styled.h2`
    font-family: Poppins, sans-serif;
    text-align: center;
    font-size: 24px;
    font-weight: 400;

    @media screen and (max-width: 640px) {
        font-size: 18px;
        font-weight: 200;
    }
`;

export const Heading3 = styled.h3`
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #f2a900;
    font-weight: 400;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

export const TextSaldo = styled.h3`
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

export const TextCuponAviso = styled.p`
    font-family: Poppins, sans-serif;
    font-size: 15px;
    color: #20c997;
    font-weight: 400;
    text-align: center;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

export const TextCuponAvisoVenc = styled.p`
    font-family: Poppins, sans-serif;
    font-size: 15px;
    color: #fcc419;
    font-weight: 400;
    text-align: center;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

export const Tagline = styled.h5`
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
`;

export const Space = styled.div`
    min-height: 46px;
    padding: 6px 0;
`;

export const SpaceCarusele = styled.div`
    min-height: 46px;
    padding: 6px 0;
    @media (max-width: 640px) {
        display: none;
    }
`;

export const TitleSmall = styled.span`
    color: grey;
    padding-bottom: 15px;
    @media (max-width: 640px) {
        padding-top: 20px;
        font-size: 13px;
    }
`;

export const Ellipse = styled.a`
    z-index: 0;
    position: absolute;
    width: 140px;
    height: 55px;
    border-width: 3px;
    color: orange;
    border: solid;
    border-radius: 290%;
    @media (max-width: 768px) {
        width: 100px;
        height: 38px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;

    @media (max-width: 640px) {
        padding: 0 24px;
    }

    @media (max-width: 320px) {
        padding: 0 16px;
    }
`;

export const WrapperMin = styled.div`
    width: 100%;
    max-width: 960px;
    box-sizing: border-box;
    padding: 0 32px;
    margin: 0 auto;

    @media (max-width: 640px) {
        padding: 0 24px;
    }

    @media (max-width: 320px) {
        padding: 0 16px;
    }
`;

export const LoaderWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0 32px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 120px 0;
`;

export const Section = styled.section`
    display: flex;
    padding: 0 0;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media (max-width: 640px) {
        padding: 10px 0;
        padding-bottom: 32px;
    }
`;

export const CaruseleViewStyles = styled.div`
    width: 1200px;
    padding-bottom: 20px;
    color: transparent;
    margin: auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const LaptopImage = styled.img`
    height: 100%;
    max-height: 500px;
    margin-bottom: -10%;
    @media (max-width: 900px) {
        max-height: 350px;
    }
    @media (max-width: 768px) {
        max-height: 300px;
    }
    @media (max-width: 640px) {
        width: 100%;
        max-height: 250px;
    }
    @media (max-width: 320px) {
        display: none;
    }
`;

/* ====== COMO FUNCIONA ====== */
export const BoxContainer = styled.div`
    padding: 0 24px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        padding: 0 48px;
    }

    @media (max-width: 640px) {
        padding: 0;
    }
`;

export const BoxColumn = styled.div`
    flex: 0 1 48%;

    @media (max-width: 768px) {
        flex: 0 1 100%;
    }
`;

export const BoxHeading = styled.h3`
    flex: 0 1 48%;
    text-align: center;
    font-weight: 400;
    font-weight: 600;
    font-size: 22px;

    @media (max-width: 320px) {
        font-size: 19px;
    }
`;

export const Box = styled.div`
    border-radius: 4px;
    flex: 0 1 100%;
    padding: 16px 0;
    box-sizing: border-box;
`;

export const Steps = styled.ol`
    list-style: none;
    margin: 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

export const WalletKey = styled.p`
    font-size: 16px;
    background: #f2a900;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 8px;

    @media (max-width: 640px) {
        font-size: 14px;
    }
    @media (max-width: 320px) {
        font-size: 12px;
    }
`;

/* ====== AGENTES BTC ====== */
export const Agentes = styled.section`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

export const Agente = styled.div`
    padding: 16px;
    box-sizing: border-box;
    flex: 0 1 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

export const AgenteText = styled.div`
    flex: 0 1 44%;
    margin-left: 30px;
    @media (max-width: 640px) {
        flex: 0 1 100%;
        flex-wrap: wrap;
    }
`;

export const AgenteContact = styled.ul`
    padding: 0;
    @media (max-width: 640px) {
        flex-wrap: wrap;
        font-size: 12px;
    }
`;

export const AgenteLogo = styled.img`
    width: 100%;
    max-width: 100px;
    margin-left: -20px;
    @media (max-width: 640px) {
        flex-wrap: wrap;
        margin: 0px 10%;
    }
`;

export const Map = styled.img`
    width: 100%;
    max-width: 420px;
    padding: 10px;
    border: 1px solid #f2a900;
    border-radius: 4px;
`;

export const Img = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
`;

export const ImgFigure = styled.figure`
    margin: 0;
    text-align: center;
`;

export const ImgNormal = styled.img`
    width: 100%;
    max-width: 220px;
    margin-top: 24px;
`;

export const Line = styled.div`
    width: 100%;
    padding-bottom: 10px;
    border-top: 1px solid rgba(117, 117, 117, 0.5);
`;

export const List = styled.ul`
    margin: 0;
    padding: 0 24px;
    list-style-type: none;
    justify-content: space-between;
    align-items: center;
    display: flex;

    @media (max-width: 640px) {
        padding: 0;
    }
`;

export const ListColumn = styled.ul`
    margin: 0;
    padding: 10px 24px;
    list-style-type: none;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 640px) {
        padding: 0;
    }
`;

export const BoxBitcoin = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 24px;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
    }

    @media screen and (max-width: 640px) {
        padding: 0px;
    }
`;

export const BoxPricing = styled.ul`
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    max-width: 360px;
    border: 3px solid #f2a900;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    color: #757575;
`;

export const BoxCalculator = styled.ul`
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    max-width: 360px;
    border: 3px solid #f2a900;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    color: #757575;
`;

export const BoxCurrency = styled.li`
    display: flex;
    justify-content: space-between;
    flex: 0 1 46%;
`;

export const InputBox = styled.div`
    display: flex;
    background: rgba(240, 240, 240, 0.87) !important;
    box-sizing: border-box;
    border-radius: 4px;
    flex: 0 1 36%;
    justify-content: center;
    align-items: center;
`;

export const InputValue = styled.input`
    background: transparent;
    border: none;
    padding: 0 12px 0 12px;
    color: #757575;
`;

export const CatcherHelp = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SectionBox = styled.section`
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;

export const CenterBox = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FaqImg = styled.img`
    width: 100%;
    max-width: 480px;
    flex: 0 1 30%;

    @media (max-width: 640px) {
        max-width: 320px;
    }

    @media (max-width: 320px) {
        max-width: 260px;
    }
`;

export const MediumImg = styled.img`
    width: 100%;
    max-width: 240px;
    flex: 0 1 30%;
`;

export const SectionText = styled.h5`
    flex: 0 1 50%;
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 768px) {
        flex: 0 1 50%;
    }

    @media (max-width: 640px) {
        flex: 0 1 100%;
        text-align: center;
    }
`;

export const SectionEdit = styled.h5`
    font-size: 18px;
    font-weight: 400;
    margin-left: 40px;
    display: flex;
    align-items: flex-end;
`;

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const LoginInput = styled.input`
    border: 1px solid #f2a900;
    padding: 8px 10px;
    border-radius: 8px;
    margin: 8px 0;
    outline: none;
`;

export const LoginPasswordAd = styled.p`
    font-size: 9px;
    font-weight: 700;
    margin: 0;
`;

export const LoginText = styled.p`
    margin-bottom: 0;
`;

export const LoginButton = styled.button`
    background: #f2a900;
    border: none;
    padding: 10px 12px;
    color: #fff;
    border-radius: 24px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 8px;
    outline: none;

    &:disabled {
        background: #f2a90085;
    }

    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

export const Advantages = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap;
`;

export const Card = styled.li`
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    text-align: center;
    flex: 1;
    max-width: 300px;
    padding: 24px;
    padding-top: 36px;
    margin: 0 16px;

    @media (max-width: 768px) {
        flex: 0 1 100%;
        padding: 16px;
        margin: 8px;
        max-width: 480px;
    }
`;

export const CardHeading = styled.h3`
    font-size: 22px;
    font-weight: 400;
`;

export const CardIcon = styled.img`
    width: 100;
`;

export const StatsHeading = styled.h5`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
`;

export const StatsPrice = styled.p`
    margin: 0;
    color: #f2a900;
    font-weight: 600;
    font-size: 28px;
    font-family: Poppins, sant-serif;

    @media screen and (max-width: 640px) {
        font-size: 20px;
    }
`;

export const StatsCurrency = styled.span`
    font-weight: 300;
    font-size: 18px;
    color: #ffffff;
`;

export const StatsBox = styled.div`
    border: 5px solid #f2a900;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 16px;
    padding-right: 25px;
    margin-right: -20px;
    background: transparent;
    display: flex;
    align-items: center;
    z-index: 10;
    @media screen and (max-width: 640px) {
        margin-top: 10px;
        padding: 6px;
        padding-right: 25px;
        font-size: 10px;
    }
`;

export const FeesTable = styled.table`
    width: 100%;
    fotnt-size: 18px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;

    @media (max-width: 640px) {
        font-size: 12px;
    }
`;

export const FeesHead = styled.th`
    border-bottom: 1px solid #ddd;
`;

export const Bullet = styled.span`
    display: inline-block;
    font-size: 24px;
    background: #f2a900;
    border-radius: 50%;
    padding: 3px 14px;
    color: #ffffff;
`;

/* ===============================
        DASHBOARD PERFIL
=============================== */

export const Main = styled.div`
    flex: 0 1 79%;
`;

/* ===============================
        TRACKEO OPERACION
=============================== */

export const TrackBox = styled.div`
    width: 100%;
    max-width: 380px;
    margin: 120px auto 60px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-betwween;
`;

export const TrackStep = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    font-family: Poppins, sans-serif;
`;

export const TrackTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const TrackContent = styled.div`
    display: flex;
    margin-left: 18px;
    padding: 12px 0 16px 22px;
    border-left: 1px solid #cdd6e0;
    font-size: 14px;
    align-items: center;
`;

export const ProfileInfo = styled.span`
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 640px) {
        font-size: 14px;
        flex: 0 1 80%;
        padding-left: 10px;
        padding-top: 15px;
    }

    @media (max-width: 320px) {
        font-size: 16px;
    }
`;

export const ProfileList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 100%;
    flex-wrap: wrap;
    padding: 0;
`;

export const ProfileItem = styled.li`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    flex: 0 1 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    margin-left: 10px;
`;

export const ProfileText = styled.h3`
    display: flex;
    flex: 0 1 65%;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 8px 0;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        flex: 0 1 75%;
    }

    @media (max-width: 640px) {
        flex: 0 1 100%;
    }

    @media (max-width: 320px) {
        font-size: 16px;
    }
`;

export const ProfileBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 110px 0;
    box-sizing: border-box;
    border: 4px solid #f2aa00;
    border-radius: 50%;
    flex-basis: 280px;
`;

export const BalanceItem = styled.div`
    font-size: 18px;
`;

export const AccountBox = styled.div`
    border: 1px solid #f2aa00;
    border-radius: 8px;
    flex: 0 1 86%;
    box-sizing: border-box;
    padding: 0 24px;
    margin-top: 24px;
    font-weight: 400;

    @media screen and (max-width: 640px) {
        margin-left: 10px;
    }

    @media screen and (max-width: 320px) {
        flex: 0 1 84%;
    }
`;

export const EditButton = styled.button`
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    color: ${colors.dark_blue_agente_btc};
    font-family: Montserrat;
    font-weight: 600;
    flex: 0 1 35%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @media (max-width: 768px) {
        flex: 0 1 25%;
    }

    @media (max-width: 640px) {
        padding: 0;
    }

    @media (max-width: 320px) {
        font-size: 18px;
    }
`;

export const AlertButton = styled.button`
    border: none;
    outline: none;
    background-color: #f2a900;
    color: ${colors.dark_blue_agente_btc};
    font-family: Montserrat;
    text-decoration: none;
    padding: 16px 32px;
    font-weight: 700;
    border-radius: 8px;
    font-size: 22px;
    margin: 12px 0;
    flex: 0 1 20%;
    text-align: center;
    cursor: pointer;
    @media (max-width: 768px) {
        flex: 0 1 30%;
    }

    @media (max-width: 640px) {
        flex: 0 1 60%;
        padding: 12px 0;
        border: none;
        font-size: 18px;
    }
`;

/* ====== MODALS ====== */
export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Modal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    padding: 48px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f2aa00;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
    text-align: center;

    @media (max-width: 640px) {
        padding: 24px;
        left: 50%;
        width: 60%;
    }
`;

export const ModalKasnet = styled.div`
    position: fixed;
    display: inline-flex;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    max-height: 300px;
    background-color: #fff;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 50%;
    @media (max-width: 1100px) {
        width: 55%;
    }
    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 770px) {
        width: 75%;
    }

    @media (max-width: 640px) {
        display: block;
        left: 50%;
        max-height: none;
    }
`;

export const ModalKasnetOne = styled.div`
    position: fixed;
    display: inline-flex;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    height : auto;
    background-color: #fff;
    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 60%;
    background-color: red;
    @media (max-width: 1100px) {
        width: 55%;
    }
    @media (max-width: 900px) {
        width: 65%;
    }

    @media (max-width: 770px) {
        width: 75%;
    }

    @media (max-width: 640px) {
        display: block;
        left: 50%;
        max-height: none;
    }
`;

export const CloseModal = styled.span`
    position: absolute;
    color: black;
    top: 10px;
    right: 20px;
    cursor: pointer;

    @media (max-width: 640px) {
        font-size: 22px;
        color: white;
    }
`;

export const ModalText = styled.p`
    font-size: 18px;
    text-align: center;
    color: #757575;

    @media (max-width: 320px) {
        font-size: 16px;
    }
`;

export const ModalButton = styled.button`
    background: #f2a900;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    padding: 6px 6px;
    border-radius: 24px;
`;

export const ModalHeaderKasnt = styled.p`
    color: #3e5158;
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    @media (max-width: 1260px) {
        font-size: 20px;
    }
    @media (max-width: 640px) {
        font-size: 20px;
    }
`;

export const ModalWrapperKasnt = styled.div`
    padding-top: 25px;
    padding-left: 15px;
    width: 45%;
    @media (max-width: 640px) {
        width: 90%;
    }
`;

export const ModalButtonKasnt = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5px;
    @media (max-width: 1260px) {
        margin-top: 10px;
    }
    @media (max-width: 640px) {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

export const ModalButtonBoxKasnt = styled.div`
    background: #f2a900;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    padding: 6px 18px;
    border-radius: 24px;
    @media (max-width: 1260px) {
        padding: 3px 9px;
        font-size: 12px;
    }
    @media (max-width: 640px) {
        font-size: 10px;
    }
`;

export const ModalImgKasnt = styled.img`
    height: 300px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    max-width: 100%;
    @media (max-width: 640px) {
        height: auto;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
`;

export const ModalImgKasntOne = styled.img`
    height: auto;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    max-width: 100%;
    width : 100%;
    @media (max-width: 640px) {
        height: auto;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
`;

/* ====== CURRENCY CALCULATOR ====== */

export const MySelect = styled.select`
    flex: 0 1 44%;
    border: none;
    border-bottom: 1px solid #f2aa00;
    outline: none;
    color: #757575;

    @media screen and (max-width: 640px) {
        margin: 10px;
    }
`;

export const MyLabel = styled.label`
    font-size: 16px;
`;

export const VisorCoins = styled.span`
    margin: 1px auto;
    readonly: yes;
    color: #757575;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    @media screen and (max-width: 640px) {
        font-size: 24px;
        width: 200px;
    }
`;

export const Warning = styled.span`
    width: 600px;
    -webkit-user-modify: read-only;
    color: #f3a306;
    font-size: 14px;
    margin-bottom: 0;
`;

export const AmountCurrency = styled.span`
    margin: 0;
    color: #f2a900;
    font-weight: 600;
    font-family: Poppins, sant-serif;
`;

export const AmountText = styled.p`
    position: relative;
`;

export const Tab = styled.button`
    flex: 0 1 50%;
    cursor: pointer;
    padding: 30px 12px;
    background: transparent;
    outline: none;
    opacity: 0.8;
    font-weight: bold;
    font-family: Montserrat, sant-serif;
    font-size: 24px;
    border: 0px;
    border-bottom: 2px solid grey;
    @media screen and (max-width: 640px) {
        font-size: 20px;
        width: 100%;
    }

    @media (max-width: 320px) {
        width: auto;
        flex: 0 1 100%;
        margin-bottom: 12px;
    }
`;

export const SelectCurrency = styled.div`
    display: flex;
    justify-content: space-around;
    width: 30%;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        margin: 12px auto 0;
        font-size: 12px;
    }
    @media screen and (max-width: 640px) {
        width: 40%;
    }
    @media screen and (max-width: 320px) {
        width: 50%;
    }
`;

export const DisplayImage = styled.img`
    display: flex;
    justify-content: flex-start;
    flex: 1 1 100%;
    order: 1;
    margin: auto;
    margin-bottom: 10px;
    max-width: 240px;
`;

export const BTCPartnersBox = styled.div`
    display: flex;
    align-items: center;
    border: 2px solid #f2a900;
    border-left: 0;
    border-right: 0;
    padding: 24px;

    @media (max-width: 640px) {
        flex-direction: column;
    }
`;

export const BTCPartners = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 50%;

    @media (max-width: 640px) {
        flex-direction: column;
        flex: 0 1 100%;
    }
`;

export const BTCText = styled.div`
    flex: 0 1 50%;
    font-weight: 600;
    font-weight: 600;
    font-size: 20px;
    font-family: Poppins, sans-serif;
    @media (max-width: 640px) {
        font-size: 14px;
    }
`;

export const LogoPartner = styled.img`
    width: 100%;
    max-width: 140px;

    @media (max-width: 768px) {
        max-width: 100px;
    }

    @media (max-width: 640px) {
        width: 25%;
        height: 20%;
    }

    @media (max-width: 640px) {
        width: auto;
        height: auto;
        margin: 16px 0;
    }
`;
