import React from "react";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Header from "./components/Header/index.js";
import About from "./pages/About/index.js";
import Policy from "./pages/Policy/index.js";
import Nosotros from "./pages/Nosotros/index.js";
import KYC from "./pages/KYC/index.js";
import TermsAndConditions from "./pages/Terms/index.js";
import Help from "./pages/Help/index.js";
import HowToStart from "./pages/HowToStart/index.js";
import MeetBTC from "./pages/MeetBTC/index.js";
import Developers from "./pages/Developers/index.js";
import Fees from "./pages/Fees/index.js";
import Responsability from "./pages/Responsability/index.js";
import OTC from "./pages/OTC/index.js";
import Contacts from "./pages/Contacts/index.js";
import Footer from "./components/Footer/index.js";
import FooterMobile from "components/FooterMobile/index.js";
import Home from "./pages/Home/index.js";
import Auth from "./pages/Auth/index.js";
import NotFound from "./pages/NotFound";
import { AuthProvider } from "./Auth";
import { CurrencyProvider } from "./CurrencyContext";
import { BtcPriceProvider } from "./BtcPriceContext";
import TagManager from "react-gtm-module";

import Wallet from "./pages/Wallet/index.js";

import Styles from "./styles/Macro";
import "styles/all.css";
import HomeHonduras from "pages/Honduras/HomeHonduras/index.js";
import ContactHonduras from "pages/Honduras/ContactHonduras/index.js";
import HeaderHonduras from "components/HeaderHonduras/index.js";

const tagManagerArgs = {
    gtmId: "GTM-KGH8DB8",
};

TagManager.initialize(tagManagerArgs);

const Main = () => {

    return (
        <AuthProvider>
            <CurrencyProvider>
                <BtcPriceProvider>
                    <BrowserRouter>
                        <Styles.AppContainer>
                            
                            
                            {/* <Header /> */}
                            <Switch>
                                <Route exact path="/" component={()=>(<><Header/><Home/><Footer/><FooterMobile/></>)} />
                                <Route exact path="/ayuda" component={()=>(<><Header/><Help/><Footer/></>)} />
                                <Route
                                    exact
                                    path="/como-empezar"
                                    component={()=>(<><Header/><HowToStart/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/comisiones"
                                    component={()=>(<><Header/><Fees/><Footer/></>)}
                                />
                                {/*
                                <Route
                                    exact
                                    path="/nosotros"
                                    component={About}
                                />
                                torecoverMTC*/}
                                <Route
                                    exact
                                    path="/politica-de-privacidad"
                                    component={()=>(<><Header/><Policy/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/nosotros"
                                    component={()=>(<><Header/><Nosotros/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/politicas-kyc-aml"
                                    component={()=>(<><Header/><KYC/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/terminos-y-condiciones"
                                    component={()=>(<><Header/><TermsAndConditions/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/conoce-bitcoin"
                                    component={()=>(<><Header/><MeetBTC/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/contacto"
                                    component={()=>(<><Header/><Contacts/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/desarrolladores"
                                    component={()=>(<><Header/><Developers/><Footer/></>)}
                                />
                                <Route
                                    exact
                                    path="/responsabilidad-social"
                                    component={()=>(<><Header/><Responsability/><Footer/></>)}
                                />
                                <Route exact path="/otc" component={OTC} />
                                <Route
                                    exact
                                    path="/wallet"
                                    component={()=>(<><Header/><Wallet/><Footer/></>)}
                                />

                                 {/* Honduras */}
                                <Route
                                    exact
                                    path="/honduras"
                                    component={()=>(<><HeaderHonduras/><HomeHonduras/><Footer/><FooterMobile/></>)}
                                />
                                <Route
                                    exact
                                    path="/honduras/contacto"
                                    component={()=>(<><HeaderHonduras/><ContactHonduras/><Footer/><FooterMobile/></>)}
                                />

                                <Route component={()=>(<><Header/><NotFound/><Footer/><FooterMobile/></>)} />

                               
                            </Switch>
                            
                        </Styles.AppContainer>
                    </BrowserRouter>
                </BtcPriceProvider>
            </CurrencyProvider>
        </AuthProvider>
    );
};

export default Main;
