import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
    ModalKasnet,
    CloseModal,
    ModalText,
    ModalButtonBoxKasnt,
    ModalWrapper,
    ModalWrapperKasnt,
    ModalHeaderKasnt,
    ModalImgKasnt,
    ModalImgKasntOne,
    ModalKasnetOne,
    ModalButtonKasnt,
} from "styles/Styled";
import agentebtcapi from "agentebtcapi.js";

const KasnetModal = () => {
    const [modalShow, setModalShow] = useState(false);
    if (
        typeof localStorage !== "undefined" &&
        localStorage.getItem("KasnetModal") === null
    ) {
        setModalShow(true);

        localStorage.setItem("KasnetModal", true);
    }
    return (
        modalShow &&
        ReactDOM.createPortal(
            <ModalWrapper onClick={() => setModalShow(false)}>
                <ModalKasnet>
                    <ModalImgKasnt
                        src={require("../../media/modal/KasnetModalImage.png")}
                    />
                    <ModalWrapperKasnt>
                        <CloseModal onClick={() => setModalShow(false)}>
                            X
                        </CloseModal>
                        <div>
                            <ModalHeaderKasnt>
                                Ahora recarga tu billetera{" "}
                                <a style={{ color: "#1A4BEA" }}>
                                    con dinero en efectivo
                                </a>
                            </ModalHeaderKasnt>

                            <div
                                style={{
                                    height: 1,
                                    backgroundColor: "#C4C4C4",
                                }}
                            />
                            <ModalText
                                style={{
                                    fontSize: 12,
                                    textAlign: "left",
                                    marginBlockEnd: 0,
                                }}
                            >
                                Solo visita cualquiera de los 12mil agentes
                                KasNet y deposita:
                            </ModalText>
                            <ModalText
                                style={{
                                    fontSize: 12,
                                    textAlign: "left",
                                    paddingTop: 5,
                                    marginBlockStart: 0,
                                }}
                            >
                                - Mencionando el código de empresa{" "}
                                <b>"AGENTEBTC" o "220121" </b>y luego tu código
                                personal
                            </ModalText>
                        </div>
                        <ModalButtonKasnt>
                            <ModalButtonBoxKasnt
                                style={{ width: "80%" }}
                                onClick={() => setModalShow(false)}
                            >
                                <a
                                    href={`${agentebtcapi.webapp}/auth/register`}
                                    style={{
                                        textDecoration: "unset",
                                        color: "#3E5158",
                                    }}
                                >
                                    Quiero abrir mi cuenta
                                </a>
                            </ModalButtonBoxKasnt>
                        </ModalButtonKasnt>
                        <a
                            href={`${agentebtcapi.webapp}/auth/login`}
                            onClick={() => setModalShow(false)}
                            style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: "#3E515899",
                            }}
                        >
                            Ya tengo cuenta
                        </a>
                    </ModalWrapperKasnt>
                </ModalKasnet>
            </ModalWrapper>,
            document.querySelector("#modal")
        )
    );
};



const KasnetModal2 = () => {
    const [modalShow, setModalShow] = useState(false);
    if (
        typeof localStorage !== "undefined" &&
        localStorage.getItem("KasnetModal") === null
    ) {
        setModalShow(true);

        localStorage.setItem("KasnetModal", true);
    }
    return (
        modalShow &&
        ReactDOM.createPortal(
            <ModalWrapper onClick={() => setModalShow(false)}>
                <ModalKasnetOne>
                    <ModalImgKasntOne
                        src={require("../../media/modal/AlintiBanner.jpg")}
                    />
                </ModalKasnetOne>
            </ModalWrapper>,
            document.querySelector("#modal")
        )
    );
};



export default KasnetModal;
