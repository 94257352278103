import styled from "styled-components";
import colors from "./common/colors";

export const PrimaryText = styled.span`
    color: ${colors.dark_blue_agente_btc};
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    line-height: 1.3;
`;

export const SecondaryText = styled.span`
    color: ${colors.yellow_agente_btc};
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    line-height: 1.3;
    position: absolute;
`;

export const PrimaryTextLanding = styled.span`
    color: ${colors.dark_blue_agente_btc};
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.3;
    @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
    }
`;

export const MainTextLanding = styled.span`
    color: ${colors.dark_blue_agente_btc};
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 55px;
    line-height: 0.8;
    @media (max-width: 768px) {
        font-size: 35px;
    }
`;

export const SecondaryTextLanding = styled.span`
    color: ${colors.yellow_agente_btc};
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 55px;
    line-height: 0.8;
    @media (max-width: 1100px) {
        font-size: 40px;
    }
    @media (max-width: 768px) {
        font-size: 35px;
    }
`;

export const SecondaryTextLandingSec = styled.span`
    color: ${colors.yellow_agente_btc};
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 40px;
    line-height: 0.8;
    @media (max-width: 768px) {
        font-size: 35px;
    }
`;
