import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";

import heroImg from "media/heros/about_us@2x.png";
import imgLuis from "images/luis@2x.png";
import imgVictor from "images/victor@2x.png";
import imgCarolina from "images/carolina@2x.png";
import imgCristian from "images/cristian@2x.png";
import imgBrayan from "images/brayan@2x.png";
import imgFabian from "images/fabian@2x.png";

import logoBcp from "images/logo-bcp-w.png";
import logoBbva from "images/logo-bbva-w.png";
import logoInterbank from "images/logo-interbank-w.png";

import bitcoin from "media/icons/icon_bitcoin.png";
import fiat from "media/fiat@2x.png";
import time from "media/time@2x.png";

// Media images
import iconHouse from "media/icons/icon_house.png";
import iconJoin from "media/icons/icon_join.png";
import iconSecurity from "media/icons/icon_security.png";
import iconCurrency from "media/icons/icon_currency.png";

import heroGetStarted from "media/heros/get_started.png";
import heroWhyChoose from "media/heros/why_we_choose.png";
import heroUseCases from "media/heros/unlock_potential.png";

import bankLogos from "media/logo_banks.png";
import logoRecidar from "media/logo_recidar.png";
import logoUsil from "media/logo_usil.png";
import logoTasaTop from "media/logo_tasatop.png";

import waveGrayUp from "media/waves/wave_gray_up.svg";
import waveGrayDown from "media/waves/wave_gray_down.svg";
import waveBlueUp from "media/waves/wave_blue_up.svg";

import { Helmet } from "react-helmet";

import agentebtcapi from "agentebtcapi.js";

const KYC = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "KYC",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.ContainerSectionHeader>
                    <Styled.TaglineAbout>
                        <PrimaryText>
                            Prácticas y Estándares de AML / KYC
                        </PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            En Agentebtc.com queremos ir a la vanguardia a nivel
                            mundial en Prevención del riesgo de Lavado de
                            Activos y Financiamiento del Terrorismo, es por esto
                            que contamos con rigurosas políticas, procedimientos
                            y herramientas tecnológicas que nos permiten operar
                            con los más altos estándares internacionales en
                            nuestra industria.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerSectionHeader>
            </Styled.AboutContainer>

            <Styled.ContentBoxedContainer>
                <Header3>Definiciones</Header3>

                <Paragraph>
                    AML/CFT es el acrónimo del concepto inglés Anti-Money
                    Laudering and Combating the Financing of Terrorism, lo que
                    podríamos traducir como Prevención del Lavado de Activos y
                    Financiamiento del Terrorismo. En palabras simples, cuando
                    alguna persona obtiene dineros o recursos provenientes de
                    ciertos delitos, trata de “lavarlos”, para sacarle la
                    “suciedad” del delito y poder utilizarlos en el día a día,
                    intentando hacerlos pasar como dinero proveniente de una
                    actividad lícita.
                </Paragraph>

                <Paragraph>
                    Para prevenir que nuestra plataforma sea usada en estas
                    actividades, contamos con variadas medidas complementarias,
                    cuya aplicación y cumplimiento obligatorio son la
                    responsabilidad principal de nuestra área de Compliance,
                    involucrando transversalmente a todo el equipo de
                    Agentebtc.com, entre las que destacamos:
                </Paragraph>

                <Header3>Manual de Cumplimiento</Header3>

                <Paragraph>
                    Todos los procedimientos destinados a la prevención y
                    control de riesgo del Lavado de Activos y Financiamiento del
                    Terrorismo están contenidos en nuestro completo y
                    actualizado Manual SARLAFT (Sistema de Administración del
                    Riesgo de Lavado de Activos y Financiación del Terrorismo).
                    En él, se describen nuestras políticas de conocimiento del
                    cliente, la asignación de responsabilidades, los controles a
                    los Clientes y las medidas a tomar en caso de detectar
                    operaciones sospechosas.
                </Paragraph>
                <Paragraph>
                    Este Manual es distribuido entre todo el equipo de
                    Agentebtc.com, a quienes además se les instruye
                    constantemente en materia de prevención de riesgos.
                </Paragraph>

                <Header3>Conocemos a nuestros usuarios</Header3>

                <Paragraph>
                    Contamos con softwares de punta como Kibana para perfilar,
                    analizar y extraer conclusiones sobre el comportamiento de
                    nuestros usuarios. El mismo nos permite organizar, graficar
                    y clasificar las operaciones en nuestra plataforma, para
                    determinar patrones de conducta ordinarios y
                    extraordinarios. Por otro lado, nos enorgullecemos de ser
                    pioneros entre los exchanges de latinoamérica en operar con
                    Elliptic, una herramienta que analiza las transacciones de
                    Bitcoin en el blockchain. Las características de
                    trazabilidad, inmutabilidad y publicidad de cada transacción
                    realizada en el blockchain de Bitcoin, permiten a esta
                    solución tecnológica realizar análisis que eventualmente
                    puedan conectar direcciones de criptomonedas vinculadas a
                    actividades ilícitas específicas.
                </Paragraph>

                <Header3>Autorregulación y reporte a autoridades</Header3>

                <Paragraph>
                    Buscamos constantemente oportunidades de mejora en nuestros
                    procesos, incorporando las mejores prácticas internacionales
                    a nuestro exchange. Si bien en algunos de los países donde
                    operamos no calificamos legalmente como sujetos obligados a
                    reportar ante las Unidades de análisis o Inteligencia
                    Financiera (UIF) respectivas, nosotros voluntariamente nos
                    hemos sometido a ciertas normas atingentes a las
                    características de nuestro negocio, actuando de facto como
                    un sujeto obligado, yendo más allá de lo que nos exige la
                    regulación. Es por lo mismo que cada transacción inusual o
                    actuar sospechoso en nuestra plataforma es analizado,
                    solicitando información adicional al usuario para aclarar
                    las conductas alertadas y - en caso de configurarse los
                    supuestos y características exigidas por cada normativa - se
                    reporta el caso a la UIF correspondiente.
                </Paragraph>
            </Styled.ContentBoxedContainer>

            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>
                            Ahora que nos conoces un poco más ¿Listo para el
                            futuro?{" "}
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Registrate en AgenteBTC en minutos
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSecExt
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comienza ahora :)
                            </Styled.StyledButtonYellowSecExt>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Prácticas y Estándares de AML / KYC | AgenteBTC</title>
                <meta
                    name="description"
                    content="Prácticas y Estándares de AML / KYC de Agente BTC, el primer exchange de bitcoins que es legal en Perú."
                />
            </Helmet>
        </>
    );
};

export default withRouter(KYC);
