import React, { useState, createContext, useEffect } from 'react';

export const ExchangeContext = createContext();

export const ExchangeProvider = ({ children }) => {
  const [exchangePen, setExchangePen] = useState(0);

  const getExchange = async () => {
    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    const API = 'https://api.cambio.today/v1/quotes/USD/PEN/json?quantity=1&key=2526|U^0e0FmKfrwtxa6SuPoo3RbY8iutR2bt'; // site that doesn’t send Access-Control-*
    const response = await fetch(proxyurl + API);
    const data = await response.json();

    const valuePen = (data.result.value + 0.035).toFixed(2);
    setExchangePen(valuePen);
  };

  useEffect(() => {
    getExchange();
  }, []);

  return <ExchangeContext.Provider value={exchangePen}>{children}</ExchangeContext.Provider>;
};
