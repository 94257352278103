import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { CarusaleView } from "../../components/Carusel/Carusel";
import { MediaCarusel } from "../../components/MediaCarusel/index";
// Spinner Loader
import { library } from "@fortawesome/fontawesome-svg-core";
import imgBlog from "media/home/blog_icon.png";
import imgTelegram from "media/home/telegram_icon.png";
import imgYT from "media/home/youtube_icon.png";
import HomeStepper from "../../components/HomeSteper/index";
import { Stepper, Step } from "react-form-stepper";
import background from "../../media/media_icons/carusele_section_bcg.png";
import KasnetModal from "../../components/modals/KasnetModal";

import "./styles.css";

import swal from "sweetalert";
import Aos from "aos";
import "aos/dist/aos.css";

// Get FontAwesome Icons
import {
	faHandHoldingUsd,
	faUserLock,
	faTachometerAlt,
	faWeight,
} from "@fortawesome/free-solid-svg-icons";
// Styled components
import {
	Section,
	LaptopImage,
	CaruseleViewStyles,
	Wrapper,
	Space,
} from "styles/Styled";

import {
	PrimaryTextLanding,
	SecondaryTextLanding,
	SecondaryTextLandingSec,
	MainTextLanding,
} from "styles/colorized";

import Styled from "./styles";
import {
	Header1,
	Header1Cards,
	SocialMedia,
	ConfianSectionCarusele,
	Paragraph,
	TitleSection,
} from "styles/common/index.js";
// Get the USD to PEN rate
import { ExchangeProvider } from "ExchangeContext";
// Get the time
import { DateProvider } from "DateContext";

import agentebtcapi from "agentebtcapi.js";

import { Helmet } from "react-helmet";
import { Style } from "victory";

// Print FontAwesome Icons
library.add(faHandHoldingUsd, faUserLock, faTachometerAlt);

const Home = ({ history, location }) => {
	window.dataLayer.push({
		event: "pageview",
		page: {
			url: location,
			title: "Home",
		},
	});
	// TagManager.dataLayer(tagManagerArgs);
	const [isOpen, setIsOpen] = useState(true);
	const [openModal, setOpenModal] = useState(false);

	const [count, setCount] = useState(0);

	// Scroll to top on all pages
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const showModal = () => {
		swal({
			text:
				"Estamos fuera de operaciones por el resto del día, para darles un mejor servicio, volveremos muy pronto",
			title: "MANTENIMIENTO BTC",
			buttons: false,
			closeOnClickOutside: false,
		});
	};

	useEffect(() => {
		//scrollTop();
		Aos.init({ duration: 2000 });
		const interval = setInterval(() => {
			//console.log(isVisible);
		}, 1000);

		setInterval(() => {
			setIsOpen(false);
		}, 10000);

		// showModal();
	}, []);

	if (count < 1) {
		setTimeout(() => {
			setCount(count + 1);
		}, 1000);
	}

	return (
		<>
			{openModal && (
				<div id="myModal" class="modal">
					<div class="modal-content">
						<div className="modal-content-header-container">
							<div className="modal-content-header-left"></div>
							<div className="modal-content-header-right">
								<span
									class="close"
									onClick={() => {
										setOpenModal(false);
									}}
								>
									&times;
								</span>
							</div>
						</div>

						<div className="modal-content-container">
							<div className="modal-content-col-left">
								<div className="modal-content-col-title">
									Toma el poder de <br />
									<span className="modal-content-col-title-yellow">
										tu propio dinero
									</span>
								</div>

								<div className="modal-content-col-subtitle">
									Escanea el QR con la cámara de tu celular y descarga la APP de
									AgenteBTC.
								</div>

								<a
									className="modal-content-col-qr-image-link"
									href="https://play.google.com/store/apps/details?id=com.agentebtc.appagentebtc&hl=es"
									target="_blank"
								>
									<img
										src="images/home/Qr-APP.png"
										className="modal-content-col-qr-image"
									/>
								</a>
							</div>

							<div className="modal-content-col-right">
								<img
									className="modal-content-col-mobile"
									src="images/home/Mobile-Cryptos.png"
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			<ExchangeProvider>
				<DateProvider>
					<>
						<Styled.HomeContainer>
							<Section style={{ paddingBottom: 15 }}>
								<img
									src={require("../../media/home/LandBack_1.png")}
									style={{
										position: "absolute",
										zIndex: -1,
									}}
									width={"100%"}
								/>
								<Wrapper>
									<Styled.HeroSection>
										<Styled.HeroText>
											<Styled.TaglineHome>
												<Styled.SecondaryTextLandingSmaller>
													Compra Bitcoin
												</Styled.SecondaryTextLandingSmaller>
												&nbsp;
												<Styled.MainTextLandingSmaller>
													y otras
												</Styled.MainTextLandingSmaller>
											</Styled.TaglineHome>
											<Styled.TaglineHome>
												<Styled.MainTextLandingSmaller>
													criptomonedas en Perú
												</Styled.MainTextLandingSmaller>
											</Styled.TaglineHome>
											<Styled.SubtaglineHome>
												<Styled.TaglineSpan>
													La experiencia más simple y segura de compra y venta
													de criptomonedas en Perú.
												</Styled.TaglineSpan>
											</Styled.SubtaglineHome>
											<Styled.ContainerButtons>
												<Styled.StyledButtonBlueExt
													href={`${agentebtcapi.webapp}/auth/register`}
													style={{
														background: `linear-gradient(#FFC500,#F7931E)`,
													}}
												>
													Comenzar
													<img
														style={{
															width: 30,
															paddingLeft: 20,
														}}
														src={require("../../media/icons/arrowIcon.png")}
													/>
												</Styled.StyledButtonBlueExt>
											</Styled.ContainerButtons>

											<Styled.ButtonAppContainer>
												<a style={{ cursor: "pointer" }}>
													<Styled.ButtonAppImg
														onClick={() => {
															setOpenModal(true);
														}}
														src="https://i.imgur.com/3ahEEX3.png"
													></Styled.ButtonAppImg>
												</a>
											</Styled.ButtonAppContainer>
										</Styled.HeroText>
										<Styled.CalculatorContainer>
											{/*}<LaptopImage
                                                src={require("../../media/WebappLanding.png")}
                                                alt="bitcoin a soles, bitcoin a dolar"
                                            />*/}
											<Styled.VideoContainer>
												<Styled.VideoContainerFrame
													src="https://www.youtube.com/embed/QoAfAjmQvTk"
													title="YouTube video player"
													frameborder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowfullscreen
												></Styled.VideoContainerFrame>
												<Styled.VideoInfoContainer>
													<div>
														<Styled.VideoInfoSymbol src="https://i.imgur.com/vHvZ40h.png" />
													</div>
													<Styled.VideoInfoText>
														Recarga tu billetera AgenteBTC en cualquier bodega
														del Perú.
														{/* &nbsp;
                                                        <Styled.VideoInfoTextSpan>Aprende cómo, AQUÍ</Styled.VideoInfoTextSpan> */}
													</Styled.VideoInfoText>
												</Styled.VideoInfoContainer>
											</Styled.VideoContainer>
										</Styled.CalculatorContainer>
									</Styled.HeroSection>
									<Space />
								</Wrapper>
							</Section>
							<Styled.CarusaleContainer>
								<CaruseleViewStyles>
									<CarusaleView />
								</CaruseleViewStyles>
							</Styled.CarusaleContainer>
						</Styled.HomeContainer>
						<Styled.WrapperCalculatorSection>
							<Styled.ContainerSectionHeader>
								<Header1>
									Invierte solo <a style={{ color: "#FFC500" }}>en 3 pasos</a>
								</Header1>
								<Paragraph
									style={{
										marginBottom: "130px",
										fontSize: "20px",
										marginTop: "-30px",
										fontWeight: "300",
										color: "#808080",
									}}
								>
									Compra y vende criptomonedas sin ser un experto
								</Paragraph>
								<Styled.WebStepper>
									<Styled.StepperSection
										style={{
											width: "100%",
											justifyContent: "space-around",
										}}
									>
										<img
											style={{
												width: 80,
											}}
											alt="criptomonedas users"
											src={require("../../media/icons/StepperIcon_1.png")}
										/>
										<img
											style={{
												width: 80,
											}}
											alt="comprar en peru"
											src={require("../../media/icons/StepperIcon_2.png")}
										/>
										<img
											style={{
												width: 80,
											}}
											alt="bitcoin a dolar, bitcoin a soles, comprar bitcoins"
											src={require("../../media/icons/StepperIcon_3.png")}
										/>
									</Styled.StepperSection>
								</Styled.WebStepper>
								<Styled.WebStepper>
									<Styled.StepperSection>
										<Stepper
											styleConfig={{
												labelFontSize: 18,
												fontWeight: 400,
												size: 24,
												completedBgColor: "#1A4BEA",
												activeBgColor: "#3C4A5B",
											}}
											connectorStyleConfig={{
												stepSize: "4em",
												width: "100%",
											}}
											style={{
												width: "100%",
												padding: 0,
												paddingBlock: 24,
											}}
										>
											<Step
												key={"Regístrate y verifica tu identidad"}
												label="Regístrate y verifica tu identidad"
												style={{
													backgroundColor: "#1A4BEA",
												}}
												active
											/>
											<Step
												key={"Deposita fondos en tu cuenta"}
												label="Deposita fondos en tu cuenta"
												style={{
													backgroundColor: "lightgrey",
												}}
												active
											/>
											<Step
												key={"¡Compra tu cripto favorita!"}
												label="¡Compra tu cripto favorita!"
												style={{
													backgroundColor: "lightgrey",
												}}
												active
											/>
										</Stepper>
									</Styled.StepperSection>
								</Styled.WebStepper>
								<Styled.StepperSection>
									<Styled.MobileStepper>
										<HomeStepper />
									</Styled.MobileStepper>
								</Styled.StepperSection>

								<Styled.ContainerLogosWithoutTransition
									style={{ alignSelf: "center" }}
								>
									<Styled.StyledButtonBlueExt
										href={`${agentebtcapi.webapp}/auth/register`}
										style={{
											background: `linear-gradient(#FFC500,#F7931E)`,
										}}
									>
										Crear mi cuenta
										<img
											style={{
												width: 30,
												paddingLeft: 20,
											}}
											src={require("../../media/icons/arrowIcon.png")}
										/>
									</Styled.StyledButtonBlueExt>
								</Styled.ContainerLogosWithoutTransition>
							</Styled.ContainerSectionHeader>
						</Styled.WrapperCalculatorSection>

						{/*Esto se va en el rediseño 
                        <Section>
                            <Wrapper style={{ paddingInline: 0 }}>
                                <div style={{ backgroundColor: "#F4FAFF" }}>
                                    <Styled.SectionTwoBox>
                                        <Styled.CalculatorContainer>
                                            <Styled.SectionTwoImg
                                                src={require("../../media/MobileWebapp.png")}
                                                alt="comprar en peru, bitcoin a dolar"
                                            />
                                        </Styled.CalculatorContainer>
                                        <Styled.SectionTwo>
                                            <Styled.TaglineHome> 
                                                <PrimaryTextLanding>
                                                    La mejor plataforma para
                                                </PrimaryTextLanding>
                                                &nbsp;
                                                <SecondaryTextLandingSec>
                                                    iniciarte en criptos
                                                </SecondaryTextLandingSec>
                                            </Styled.TaglineHome>
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 40,
                                                            display:
                                                                "table-cell",
                                                            paddingRight: 15,
                                                        }}
                                                        alt="wallet of criptomonedas"
                                                        src={require("../../media/icons/new_wallet_icon.png")}
                                                    />

                                                    <div
                                                        style={{
                                                            display:
                                                                "table-cell",
                                                        }}
                                                    >
                                                        <Styled.SectionTwoText>
                                                            Deposita dinero sin
                                                            comisiones
                                                        </Styled.SectionTwoText>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 40,
                                                            display:
                                                                "table-cell",
                                                            paddingRight: 15,
                                                        }}
                                                        alt="calendar criptomoneda"
                                                        src={require("../../media/icons/new_calendar_icon.png")}
                                                    />

                                                    <div
                                                        style={{
                                                            display:
                                                                "table-cell",
                                                        }}
                                                    >
                                                        <Styled.SectionTwoText>
                                                            Operaciones seguras
                                                            las 24h
                                                        </Styled.SectionTwoText>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 40,
                                                            display:
                                                                "table-cell",
                                                            paddingRight: 15,
                                                        }}
                                                        alt="criptomoneda users"
                                                        src={require("../../media/icons/new_people_icon.png")}
                                                    />

                                                    <div
                                                        style={{
                                                            display:
                                                                "table-cell",
                                                        }}
                                                    >
                                                        <Styled.SectionTwoText>
                                                            Asesoría
                                                            Personalizada
                                                        </Styled.SectionTwoText>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "table-cell",
                                                        paddingTop: 30,
                                                    }}
                                                >
                                                    <Styled.StyledButtonBlueExt
                                                        style={{
                                                            fontStyle: "normal",
                                                            color: "#3E5158",
                                                            background: `linear-gradient(#FFC500,#F7931E)`,
                                                        }}
                                                        href={`${agentebtcapi.webapp}/auth/register`}
                                                    >
                                                        Abrir mi cuenta
                                                        <img
                                                            style={{
                                                                width: 30,
                                                                paddingLeft: 20,
                                                            }}
                                                            src={require("../../media/icons/arrowIcon.png")}
                                                        />
                                                    </Styled.StyledButtonBlueExt>
                                                </div>
                                            </div>
                                        </Styled.SectionTwo>
                                    </Styled.SectionTwoBox>
                                </div>
                                <Styled.WrapperCalculatorSection>
                                    <Styled.PressSection
                                        style={{ width: "100%" }}
                                    >
                                        <Styled.ContainerLogos
                                            style={{ flexDirection: "column" }}
                                        >
                                            <Paragraph
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 50,
                                                    marginBlockStart: 0,
                                                    marginBlockEnd: 0,
                                                }}
                                            >
                                                + 5MIL
                                            </Paragraph>
                                            <Paragraph
                                                style={{
                                                    color: "#A3A6AB",
                                                    marginBlockStart: 5,
                                                    marginBlockEnd: 0,
                                                    paddingBottom: 15,
                                                }}
                                            >
                                                Clientes
                                            </Paragraph>
                                        </Styled.ContainerLogos>
                                        <Styled.ContainerLogos
                                            style={{
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Paragraph
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 50,
                                                    marginBlockStart: 0,
                                                    marginBlockEnd: 0,
                                                }}
                                            >
                                                5MM
                                            </Paragraph>
                                            <Paragraph
                                                style={{
                                                    color: "#A3A6AB",
                                                    marginBlockStart: 5,
                                                    marginBlockEnd: 0,
                                                    paddingBottom: 15,
                                                }}
                                            >
                                                Volumen Transaccionado
                                            </Paragraph>
                                        </Styled.ContainerLogos>
                                        <Styled.ContainerLogos
                                            style={{ flexDirection: "column" }}
                                        >
                                            <Paragraph
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 50,
                                                    marginBlockStart: 0,
                                                    marginBlockEnd: 0,
                                                }}
                                            >
                                                9
                                            </Paragraph>
                                            <Paragraph
                                                style={{
                                                    color: "#A3A6AB",
                                                    marginBlockStart: 5,
                                                    marginBlockEnd: 0,
                                                    paddingBottom: 15,
                                                }}
                                            >
                                                Criptomonedas
                                            </Paragraph>
                                        </Styled.ContainerLogos>
                                    </Styled.PressSection>
                                </Styled.WrapperCalculatorSection>
                            </Wrapper>
                        </Section>
                        */}

						{/* <Styled.RechargeWalletWrapper>

                            <Styled.RechargeWalletTextWrapper>
                                <Styled.RechargeWalletTextContainer>

                                    <Styled.RechargeWalletText>
                                        <Styled.RechargeWalletSecondaryText>
                                            Recarga tu billetera &nbsp;
                                        </Styled.RechargeWalletSecondaryText>
                                        <Styled.RechargeWalletPrimaryText>
                                            sin <br/>
                                        </Styled.RechargeWalletPrimaryText>
                                        <Styled.RechargeWalletPrimaryText>
                                            cuenta bancaria, ni tarjetas
                                        </Styled.RechargeWalletPrimaryText>
                                    </Styled.RechargeWalletText>

                                    <Styled.RechargeWalletInfo>
                                        Ahora en más de 14,000 bodegas KasNet en todo el Perú
                                    </Styled.RechargeWalletInfo>

                                <Styled.RechargeWalletBtnContainer>
                                        <Styled.RechargeWalletButton>
                                            Quiero saber cómo
                                        </Styled.RechargeWalletButton>
                                        <Styled.RechargeWalletBtnInfo>
                                            Ubica tu agente KasNet más cercano a ti
                                        </Styled.RechargeWalletBtnInfo>
                                        
                                </Styled.RechargeWalletBtnContainer>

                                </Styled.RechargeWalletTextContainer>

                            </Styled.RechargeWalletTextWrapper>

                         
                            
                        </Styled.RechargeWalletWrapper> */}

						<div className="home-wallet-container">
							<div className="home-wallet-col-left">
								<div className="home-wallet-col-left-title">
									<span className="home-wallet-col-left-title-yellow">
										Recarga tu billetera{" "}
									</span>
									sin cuenta bancaria ni tarjetas
								</div>

								<div className="home-wallet-col-left-subtitle">
									Ahora en más de 14,000 bodegas KasNet en todo el Perú.
								</div>

								{/* <a href="http://google.com" className="home-wallet-col-left-button">
                                    Quiero saber cómo
                                        <img className="home-wallet-col-left-button-icon"
                                            src={require("../../media/icons/arrowIcon.png")}
                                        />
                                </a> */}

								<a
									href="https://ubicatuagente.agentekasnet.com/"
									target="_blank"
									className="home-wallet-col-left-link"
								>
									Ubica tu agente KasNet más cercano a ti
								</a>
							</div>

							<div className="home-wallet-col-right">
								<img
									src="images/home/BodegueraKasnet.png"
									className="home-wallet-col-right-image"
								/>
							</div>
						</div>

						<div className="home-map-container">
							<div className="home-map-col-left">
								<img
									src="images/home/MapSection1.png"
									className="home-map-image-map"
								/>
							</div>
							<div className="home-map-col-right">
								<img
									src="images/home/MapSection2.png"
									className="home-map-image-map"
								/>

								<div
									href="https://play.google.com/store/apps/details?id=com.agentebtc.appagentebtc&hl=es"
									target="_blank"
									className="home-map-image-button-link"
									onClick={() => {
										setOpenModal(true);
									}}
								>
									<img
										src="images/home/MapSection3.png"
										className="home-map-image-button"
									/>
								</div>
							</div>
						</div>

						{/* <Styled.MapWrapper>
                            <Styled.MapContainer>
                                <Styled.MapImg src="https://i.imgur.com/5CcTBLg.png"></Styled.MapImg>
                                <Styled.MapTextWrapper>
                                    <Styled.MapTextContainer>
                                        
                                        <Styled.MapTextUpperContainer>
                                            <Styled.MapTextUpper>
                                                Recarga tu billetera <Styled.MapTextUpperBold> AgenteBTC</Styled.MapTextUpperBold>
                                            </Styled.MapTextUpper>
                                            <Styled.MapButtonStyledText>
                                                TOTALMENTE GRATIS
                                            </Styled.MapButtonStyledText>
                                        </Styled.MapTextUpperContainer>
                                        
                                        <Styled.MapTextImportantContainer>
                                            <Styled.MapTextImportantUpper>
                                                ¡Ya 
                                                <Styled.MapTextImportantUpperBigger>
                                                    &nbsp; estamos
                                                </Styled.MapTextImportantUpperBigger>
                                                &nbsp; en &nbsp;
                                                <span>
                                                    <Styled.MapTextImportantUpperEmoji src="https://i.imgur.com/Ggc02zO.png">
                                                    </Styled.MapTextImportantUpperEmoji>
                                                </span> <br/>
                                            </Styled.MapTextImportantUpper>
                                            <Styled.MapTextImportantLower>
                                                <Styled.MapTextImportantLowerSpan1> 20 </Styled.MapTextImportantLowerSpan1>
                                                <Styled.MapTextImportantLowerSpan2> &nbsp; ciudades </Styled.MapTextImportantLowerSpan2>
                                                <Styled.MapTextImportantLowerSpan3> y vamos por MÁS!</Styled.MapTextImportantLowerSpan3>
                                            </Styled.MapTextImportantLower>
                                        </Styled.MapTextImportantContainer>
                                        <Styled.AppBlock src="https://i.imgur.com/3ahEEX3.png"></Styled.AppBlock>
                                        <Styled.MapLeftTextWrapper>
                                            <Styled.MapLeftTextContainer>
                                                <Styled.MapLeftText>
                                                    +20
                                                </Styled.MapLeftText>
                                                <Styled.MapLeftTextSpan>
                                                    Departamentos
                                                </Styled.MapLeftTextSpan>
                                            </Styled.MapLeftTextContainer>
                                            <Styled.MapLeftTextContainer>
                                                <Styled.MapLeftText>
                                                    195 
                                                </Styled.MapLeftText>
                                                <Styled.MapLeftTextSpan>
                                                    Provincias
                                                </Styled.MapLeftTextSpan>
                                            </Styled.MapLeftTextContainer>
                                        </Styled.MapLeftTextWrapper>
                                    </Styled.MapTextContainer>
                                </Styled.MapTextWrapper>
                            </Styled.MapContainer>
                        </Styled.MapWrapper> */}

						{/* 
                        <Styled.WrapperCalculatorSection>
                            <Styled.ContainerSectionHeader
                                style={{ textAlign: "left" }}
                            >
                                <div style={{ width: "85%", margin: "auto" }}>
                                    <Header1Cards
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        ¿Por qué
                                        <a style={{ color: "#FFC500" }}>
                                            más de 100mil peruanos
                                        </a>
                                        <br />
                                        ya han invertido en criptomonedas?
                                    </Header1Cards>
                                
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <Styled.CardContainer>
                                        <Styled.SingleCard>
                                            <div style={{ padding: 15 }}>
                                                <img
                                                    style={{ width: 40 }}
                                                    alt="invertir en criptomonedas"
                                                    src={require("../../media/icons/CardIcon_1.png")}
                                                />
                                                <Styled.CardTitle>
                                                    Protege tus Ahorros
                                                </Styled.CardTitle>
                                                <Styled.CardContent>
                                                    Las criptomonedas pueden
                                                    incrementar su valor a lo
                                                    largo del tiempo,
                                                    permitiendo proteger tu
                                                    dinero contra la inflación.
                                                </Styled.CardContent>
                                            </div>
                                        </Styled.SingleCard>
                                    
                                        <Styled.SingleCard>
                                            <div style={{ padding: 15 }}>
                                                <img
                                                    style={{ width: 40, textAlign: "center"}}
                                                    alt="invertir en criptomonedas"
                                                    src={require("../../media/icons/CardIcon_3.png")}
                                                />
                                                <Styled.CardTitle>
                                                    Potencial Rentable
                                                </Styled.CardTitle>
                                                <Styled.CardContent>
                                                    A largo plazo, criptomonedas
                                                    como el Bitcoin han
                                                    presentado ganancias
                                                    sumamente elevadas,
                                                    otorgando libertad
                                                    financiera a muchos.
                                                </Styled.CardContent>
                                            </div>
                                        </Styled.SingleCard>
                                        <Styled.SingleCard>
                                            <div style={{ padding: 15 }}>
                                                <img
                                                    style={{ width: 40 }}
                                                    alt="descentralización, bicoin peru"
                                                    src={require("../../media/icons/CardIcon_4.png")}
                                                />
                                                <Styled.CardTitle>
                                                    Descentralización
                                                </Styled.CardTitle>
                                                <Styled.CardContent>
                                                    Las criptomonedas no están
                                                    controladas por un ente
                                                    bancario o gobierno. Su
                                                    seguridad reside en el
                                                    Blockchain, un sistema
                                                    inmutable en control de los
                                                    usuarios.
                                                </Styled.CardContent>
                                            </div>
                                        </Styled.SingleCard>
                                    </Styled.CardContainer>
                                </div>
                                <div style={{width:"100%", display:"flex", marginTop:"40px"}}>
                                    <Styled.StyledButtonBlueExt
                                        href={`${agentebtcapi.webapp}/auth/register`}
                                        style={{
                                            background: `linear-gradient(#FFC500,#F7931E)`,
                                            margin: "0 auto"
                                        }}
                                    >
                                        Súmate AHORA
                                        <img
                                            style={{
                                                width: 30,
                                                paddingLeft: 40,
                                                marginRight:"-20px"
                                            }}
                                            src={require("../../media/icons/arrowIcon.png")}
                                        />
                                    </Styled.StyledButtonBlueExt>
                                </div>
                            </Styled.ContainerSectionHeader>
                        </Styled.WrapperCalculatorSection>
                        */}

						{/* Invert and Open Account */}
						<div className="home-honduras-savings-container">
							<div className="savings-working">
								<img
									className="savings-image"
									src="https://i.imgur.com/iBFw28o.png"
								/>
							</div>
						</div>
						<Styled.ContainerLogosWithoutTransition
							style={{ alignSelf: "center", backgroundColor: "#f4faff" }}
						>
							<Styled.StyledButtonBlueExt
								href={`${agentebtcapi.webapp}/auth/register`}
								style={{
									background: `linear-gradient(#FFC500,#F7931E)`,
								}}
							>
								Crear mi cuenta
								<img
									style={{
										width: 30,
										paddingLeft: 20,
									}}
									src={require("../../media/icons/arrowIcon.png")}
								/>
							</Styled.StyledButtonBlueExt>
						</Styled.ContainerLogosWithoutTransition>

						{/* MTC */}
						<div className="home-download-container">
							<div className="home-download-wrap">
								<div className="home-download-col-left">
									<div className="home-download-col-left-title">
										¡Descarga el APP y crea tu cuenta{" "}
										<span className="home-download-col-left-title-yellow">
											GRATIS!
										</span>
									</div>
									<div className="home-download-col-left-subtitle">
										Descubre una nueva forma de <br /> relacionarte con tu
										dinero.
									</div>
									<div className="home-download-col-left-link">
										<div
											onClick={() => {
												setOpenModal(true);
											}}
											className="home-download-image-button-link"
										>
											<img
												src="images/home/MapSection3.png"
												className="home-download-image-button"
											/>
										</div>
									</div>
								</div>

								<div className="home-download-col-right">
									<img
										src="images/home/Woman-Kasnet.png"
										className="home-download-image-woman"
									/>
								</div>
							</div>

							<div className="home-download-footer-container">
							<div className="home-download-footer-item">
								<div className="home-download-footer-title">+10MIL</div>
								<div className="home-download-footer-body">Clientes</div>
							</div>

							<div className="home-download-footer-item">
								<div className="home-download-footer-title">+20M</div>
								<div className="home-download-footer-body">
									Volumen Transaccionado
								</div>
							</div>

							<div className="home-download-footer-item">
								<div className="home-download-footer-title">+6</div>
								<div className="home-download-footer-body">Criptomonedas</div>
							</div>

							<div className="home-download-footer-item">
								<div className="home-download-footer-title">+5</div>
								<div className="home-download-footer-body">Países</div>
							</div>
						</div>
						</div>

						{/* Espacios en la landing original
                        <Space />
                        <Space />
                        

                        <Space />
                        <Space /> */}

						<Styled.WrapperCalculatorSectionCarusele>
							<Styled.ContainerSectionHeader>
								<Header1>
									Ellos confían en <a style={{ color: "#FFC500" }}>AgenteBTC</a>
								</Header1>
								<ConfianSectionCarusele
									style={{
										padding: 0,
										backgroundColor: "aliceblue",
									}}
								>
									<MediaCarusel />
								</ConfianSectionCarusele>
							</Styled.ContainerSectionHeader>
						</Styled.WrapperCalculatorSectionCarusele>

						<Styled.WrapperCalculatorSectionImage>
							<Styled.WrapperCalculatorSection>
								<Styled.ContainerSectionHeader style={{ textAlign: "left" }}>
									<TitleSection
										style={{
											fontSize: 32,
											marginBlockEnd: 0,
										}}
									>
										¡Únete a la
									</TitleSection>
									<TitleSection
										style={{
											color: "#1B4BE8",
											fontSize: 32,
											marginBlockStart: 0,
											marginBlockEnd: 0,
										}}
									>
										comunidad criptoagente!
									</TitleSection>
									<Paragraph style={{ fontSize: 18, color: "gray" }}>
										AgenteBTC te ofrece herramientas gratuitas <br /> para
										aprender y conectar más con el mundo cripto.
									</Paragraph>
									<Styled.PressSection style={{ width: "100%" }}>
										<Styled.CardsContainer
											style={{
												flexDirection: "column",
												width: 350,
												backgroundColor: "white",
											}}
											data-aos="fade"
											data-aos-once="true"
										>
											<Styled.ImageBanks
												src={imgBlog}
												style={{
													height: 180,
													width: 250,
													backgroundColor: "#fff",
												}}
												alt="criptomonedas que son, criptomonedas blog"
											/>
											<SocialMedia>
												<Paragraph
													style={{
														fontWeight: "bold",
														paddingLeft: 20,
														marginBlockStart: 10,
														marginBlockEnd: 0,
														fontSize: 22,
													}}
												>
													Blog
												</Paragraph>
												<Paragraph
													style={{
														color: "#3E5158",
														paddingLeft: 20,
														marginBlockStart: 5,
														marginBlockEnd: 0,
														paddingBottom: 15,
													}}
												>
													Contenido educativo y de tendencias.
												</Paragraph>
												<div
													style={{
														marginInline: 10,
														height: 1,
														opacity: 0.1,
														backgroundColor: "#3E5158",
													}}
												/>
												<div
													style={{
														textAlign: "right",
														width: "100%",
														padding: 10,
													}}
												>
													<Styled.SectionsButton href="https://blog.agentebtc.com/">
														Más información
													</Styled.SectionsButton>
												</div>
											</SocialMedia>
										</Styled.CardsContainer>

										<Styled.CardsContainer
											style={{
												flexDirection: "column",
												width: 350,
												backgroundColor: "white",
											}}
											data-aos="fade"
											data-aos-once="true"
										>
											<div>
												<Styled.ImageBanks
													src={imgYT}
													style={{
														height: 180,
														width: 250,
													}}
													alt="criptomonedas que son, criptomonedas youtube"
												/>
											</div>
											<SocialMedia>
												<Paragraph
													style={{
														fontWeight: "bold",
														paddingLeft: 20,
														marginBlockStart: 10,
														marginBlockEnd: 0,
														fontSize: 22,
													}}
												>
													Youtube
												</Paragraph>
												<Paragraph
													style={{
														color: "#3E5158",
														paddingLeft: 20,
														marginBlockStart: 5,
														marginBlockEnd: 0,
														paddingBottom: 15,
													}}
												>
													Tutoriales / Entrevistas / Webinars.
												</Paragraph>
												<div
													style={{
														marginInline: 10,
														height: 1,
														opacity: 0.1,
														backgroundColor: "#3E5158",
													}}
												/>
												<div
													style={{
														textAlign: "right",
														width: "100%",
														padding: 10,
													}}
												>
													<Styled.SectionsButton href="https://www.youtube.com/c/AgenteBTC">
														Más información
													</Styled.SectionsButton>
												</div>
											</SocialMedia>
										</Styled.CardsContainer>
										<Styled.CardsContainer
											style={{
												flexDirection: "column",
												width: 350,
												backgroundColor: "white",
											}}
											data-aos="fade"
											data-aos-once="true"
										>
											<Styled.ImageBanks
												src={imgTelegram}
												style={{
													height: 180,
													width: 250,
												}}
												alt="criptomonedas que son, criptomonedas telegram"
											/>
											<SocialMedia>
												<Paragraph
													style={{
														fontWeight: "bold",
														fontSize: 22,
														paddingLeft: 20,
														marginBlockStart: 10,
														marginBlockEnd: 0,
													}}
												>
													Telegram
												</Paragraph>
												<Paragraph
													style={{
														color: "#3E5158",
														paddingLeft: 20,
														marginBlockStart: 5,
														marginBlockEnd: 0,
														paddingBottom: 15,
													}}
												>
													Comunidad CRIPTOAGENTES.
												</Paragraph>
												<div
													style={{
														marginInline: 10,
														height: 1,
														opacity: 0.1,
														backgroundColor: "#3E5158",
													}}
												/>
												<div
													style={{
														textAlign: "right",
														width: "100%",
														padding: 10,
													}}
												>
													<Styled.SectionsButton href="https://t.me/agenteBTC_PE">
														Más información
													</Styled.SectionsButton>
												</div>
											</SocialMedia>
										</Styled.CardsContainer>
									</Styled.PressSection>
								</Styled.ContainerSectionHeader>
							</Styled.WrapperCalculatorSection>
						</Styled.WrapperCalculatorSectionImage>
						{/* <Styled.WrapperBackgroundImageLastSection>
                            
                            <Space />
                            <Styled.WrapperCalculatorSection>
                                <Styled.ContainerSectionHeader>
                                    <Styled.WrapperCalculatorSection>
                                        <Styled.ContainerLogosWithoutTransition
                                            style={{ textAlign: "left " }}
                                        >
                                            <PrimaryTextLanding
                                                style={{
                                                    marginBlockStart: 0,
                                                    marginBlockEnd: 0,
                                                }}
                                            >
                                                ¿Estás listo?
                                                <br />
                                                <a style={{ color: "#FFC500" }}>
                                                    Te estamos esperando
                                                </a>
                                            </PrimaryTextLanding>
                                        </Styled.ContainerLogosWithoutTransition>
                                        <Styled.ContainerLogosWithoutTransition
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            <Styled.StyledButtonBlueExt
                                                href={`${agentebtcapi.webapp}/auth/register`}
                                                style={{
                                                    background: `linear-gradient(#FFC500,#F7931E)`,
                                                }}
                                            >
                                                Crear mi cuenta
                                                <img
                                                    style={{
                                                        width: 30,
                                                        paddingLeft: 20,
                                                    }}
                                                    src={require("../../media/icons/arrowIcon.png")}
                                                />
                                            </Styled.StyledButtonBlueExt>
                                        </Styled.ContainerLogosWithoutTransition>
                                    </Styled.WrapperCalculatorSection>
                                </Styled.ContainerSectionHeader>
                            </Styled.WrapperCalculatorSection>
                        </Styled.WrapperBackgroundImageLastSection> */}
						<KasnetModal />
					</>
				</DateProvider>
			</ExchangeProvider>

			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Compra y vende Bitcoin en Perú con Soles y Dólares | Agente BTC
				</title>
				<meta
					name="description"
					content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://agentebtc.com/" />
				<meta
					property="og:title"
					content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
				/>
				<meta
					property="og:description"
					content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
				/>
				<meta
					property="og:image"
					content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content="https://agentebtc.com/" />
				<meta
					property="twitter:title"
					content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
				/>
				<meta
					property="twitter:description"
					content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
				/>
				<meta
					property="twitter:image"
					content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
				/>
			</Helmet>
		</>
	);
};

export default withRouter(Home);
