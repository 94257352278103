import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { ThemeProvider, createTheme } from "@mui/material/styles";

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: "#1A4BEA",
            dark: "#1A4BEA",
            light: "01fff0",
        },
    },
});

const HomeStepper = () => {
    const stepsFiat = [
        {
            label: "Regístrate y verifica tu identidad",
            content: (
                <img
                    style={{
                        width: 80,
                    }}
                    src={require("../../media/icons/StepperIcon_1.png")}
                />
            ),
        },
        {
            label: "Deposita fondos en tu cuenta",
            content: (
                <img
                    style={{
                        width: 90,
                    }}
                    src={require("../../media/icons/StepperIcon_2.png")}
                />
            ),
        },
        {
            label: "¡Compra tu cripto favorita!",
            
            content: (
                <img
                    style={{
                        width: 90,
                    }}
                    src={require("../../media/icons/StepperIcon_3.png")}
                />
            ),
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Stepper orientation="vertical">
                {stepsFiat.map((step, index) => (
                    <Step key={step.label} active={true}>
                        <StepLabel sx={{p:3}}>
                            <span style={{fontSize: 21}}>{step.label}</span>
                            
                        </StepLabel>
                        <StepContent>{step.content}</StepContent>
                    </Step>
                ))}
            </Stepper>
        </ThemeProvider>
    );
};

export default HomeStepper;
