import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import app from 'base';
import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';
import { Header1 } from 'styles/common/index';
import StyledAuth from '../styles';

const Recover = ({ history }) => {
  const handleRecover = (e)=>{
    e.preventDefault();
    const { email } = e.target.elements;
    let body = {email: email.value};
    let data =  {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    }
    let endpoint = '/auth/recover';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){
          swal({text: "Porfavor, revisa tu correo electrónico", button: false, timer: 3000});
          history.push('/auth/login');
        }else{
          swal({text: "Lo sentimos, no se encontrar el correo relacionado. Si no tiene una cuenta, favor de crearse una cuenta.", button: false, timer: 2000});
        }
      })
      .catch((error)=>{
        swal({text: "Lo sentimos, no se encontrar el correo relacionado", button: false, timer: 2000});
      })
  }

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <Header1>Recuperar Cuenta</Header1>
      <StyledAuth.AuthText>Ingresa tu correo electrónico. Te enviaremos un correo en donde encontrarás las instrucciones para recuperar tu cuenta: </StyledAuth.AuthText>
      <StyledAuth.AuthForm onSubmit={handleRecover}>
        <StyledAuth.AuthInput name="email" type="email" placeholder="Tu correo electrónico" />
        <StyledAuth.AuthButton type="submit">Recuperar contraseña</StyledAuth.AuthButton>
      </StyledAuth.AuthForm>
      <StyledAuth.AuthText>
        ¿Ya tienes una cuenta? &nbsp;
        <StyledAuth.AuthLink to="/auth/login">
          Ingresar
        </StyledAuth.AuthLink>
      </StyledAuth.AuthText>
      <StyledAuth.AuthText>
        ¿Aún no tienes una cuenta? &nbsp;
        <StyledAuth.AuthLink to="/test">
          Registro
        </StyledAuth.AuthLink>
      </StyledAuth.AuthText>
    </>
  );
};

export default withRouter(Recover);
