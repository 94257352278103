import React, { useCallback, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';

import { Header1 } from 'styles/common/index';
import StyledAuth from '../styles';

const ChangePassword = ({ history, match }) => {
  const { token } = match.params;

  const handleRecaptcha = (value) => {
    console.log("Captcha value:", value);
  }

  const handleUpdatePassword = (e)=>{
    e.preventDefault();
    swal({text: "Modificando contraseña", button: false});
    const { password, repeat_password } = e.target.elements;
    let body = {password: password.value, repeat_password: repeat_password.value, token_string: token};
    let data =  {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    }
    let endpoint = '/auth/changepassword';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){
          swal({text: "Contraseña modificada, porfavor ingresar", button: false, timer: 2000});
          history.push('/auth/login');
        }
        else{
          swal({text: "Lo sentimos, no se pudo actualizar la contraseña", button: false, timer: 2000});
        }
      })
      .catch((error)=>{
        swal({text: "Lo sentimos, no se pudo actualizar la contraseña", button: false, timer: 2000});
      })
  }

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
  });

  return (
    <>
      <Header1>Cambio de Contraseña</Header1>
      <StyledAuth.AuthText>Ingresa una nueva tu contraseña para tu cuenta</StyledAuth.AuthText>
      <StyledAuth.AuthForm onSubmit={handleUpdatePassword}>
        <StyledAuth.AuthInput name="password" type="password" placeholder="Una contraseña segura" required />
        <StyledAuth.AuthInput name="repeat_password" type="password" placeholder="Repite tu contraseña" required />
        <ReCAPTCHA
          sitekey="6LceUcsUAAAAAKJ3xKxdp19Ywpwl3JT2ZGmxsJ38"
          onChange={handleRecaptcha}
          className="recaptcha"
        />
      <StyledAuth.AuthButton type="submit">Actualizar contraseña</StyledAuth.AuthButton>
      </StyledAuth.AuthForm>
      <StyledAuth.AuthText>
        ¿Ya tienes una cuenta? &nbsp;
        <StyledAuth.AuthLink to="/auth/login">
          Inicia sesión
        </StyledAuth.AuthLink>
      </StyledAuth.AuthText>
    </>
  );
};

export default withRouter(ChangePassword);
