import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as LinkHash } from "react-router-hash-link";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserCheck,
    faSignInAlt,
    faBars,
    faTimes,
    faHome,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Logo, Item } from "./styles";
import logo from "media/icons/new_logo.png";
import ReactGA from "react-ga";

import BuySellIcon from "media/icons/navigationProdIcon_1.png";
import DevelopersIcon from "media/icons/navigationProdIcon_2.png";
import OTCIcon from "media/icons/navigationProdIcon_3.png";
import WalletIcon from "media/icons/navigationProdIcon_4.png";
import NosotrosIcon from "media/icons/Nosotros_icon.png";

import BlogIcon from "media/icons/navigationComIcon_2.png";
import SocialIcon from "media/icons/navigationComIcon_3.png";

import { AuthContext } from "Auth";
import swal from "sweetalert";
import "./index.scss";
import "./styles.css";
import agentebtcapi from "agentebtcapi.js";

const Header = ({ location, history }) => {
    const {
        currentToken,
        expiration,
        updateToken,
        updateExpiration,
    } = useContext(AuthContext);

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const handleLogOut = () => {
        swal({
            text: "Se ha cerrado sesión. Muchas gracias",
            button: false,
            timer: 3000,
        });
        updateToken("");
        updateExpiration(0);
        localStorage.setItem("token-agente-btc", "");
        history.push("/");
    };

	const [selectedCountry, setSelectedCountry] = useState('Perú');

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        // Additional logic if needed when the country is changed

		if(selectedCountry === 'Perú' && country === 'Perú') {
			return;
  		};
		if(selectedCountry === 'Perú' && country === 'Honduras') {
			history.push('/honduras'); 
		};
		if (selectedCountry === 'Honduras' && country === 'Perú') {
			history.push('/');
		};
		if (selectedCountry === 'Honduras' && country === 'Honduras') {
			return;
		};
	}



    return (
			<>
				<div className="navbarBack">
					<nav className="navbar">
						<NavLink className="navbar-logo" to="/">
							<Logo src={logo} alt="AgenteBTC criptomonedas" />
						</NavLink>
						<div
							className="menu-icon"
							onClick={handleClick}
							style={{ padding: 10 }}
						>
							{click ? (
								<FontAwesomeIcon icon={faTimes} style={{ color: "#ffc85c" }} />
							) : (
								<FontAwesomeIcon icon={faBars} style={{ color: "#ffc85c" }} />
							)}
						</div>
						{!click ? (
							<>
								<ul
									style={{ marginRight: 30 }}
									className={click ? "nav-menu-center" : "nav-menu-center"}
								>
									<li className="nav-item-button">
										<NavLink
											to="/como-empezar"
											exact
											activeClassName="selected-link"
											style={{ width: "100%" }}
											className="nav-link"
										>
											Cómo empezar
										</NavLink>
									</li>
									<li className="nav-item-button">
										<Item className="menu-dropdown">
											<NavLink
												to="/"
												exact
												//activeClassName="selected-link"
												className="nav-link"
											>
												Productos ▾
											</NavLink>
											<div class="dropdown-menu" style={{ marginTop: 0 }}>
												<ul>
													<NavLink
														to={{
															pathname: "https://app.agentebtc.com/auth/login",
														}}
														target="_blank"
													>
														<li>
															<div class="item-content">
																<div class="left">
																	<img
																		style={{
																			width: 40,
																			marginRight: 20,
																		}}
																		src={BuySellIcon}
																		alt="bicoin peru"
																	/>
																</div>
																<div class="right">
																	<div class="sm-title">
																		Compra y venta de Bitcoins{" "}
																	</div>
																	<div class="sm-description">
																		Compra rápida vía deposito bancario
																	</div>
																</div>
															</div>
														</li>
													</NavLink>

													<NavLink to="/wallet">
														<li>
															<div class="item-content">
																<div class="left">
																	<img
																		style={{
																			width: 40,
																			marginRight: 20,
																		}}
																		src={WalletIcon}
																		alt="comprar en peru"
																	/>
																</div>

																<div class="right">
																	<div class="sm-title">Wallet</div>
																	<div class="sm-description">
																		Tu propia billetera Bitcoin gratis
																	</div>
																</div>
															</div>
														</li>
													</NavLink>

													<NavLink to="/otc">
														<li>
															<div class="item-content">
																<div class="left">
																	<img
																		style={{
																			width: 40,
																			marginRight: 20,
																		}}
																		src={OTCIcon}
																		alt="invertir en criptomonedas"
																	/>
																</div>

																<div class="right">
																	<div class="sm-title">OTC</div>
																	<div class="sm-description">
																		Servicio especializado para altos
																		patrimonios
																	</div>
																</div>
															</div>
														</li>
													</NavLink>

													<NavLink to="/desarrolladores">
														<li>
															<div class="item-content">
																<div class="left">
																	<img
																		style={{
																			width: 40,
																			marginRight: 20,
																		}}
																		src={DevelopersIcon}
																	/>
																</div>

																<div class="right">
																	<div class="sm-title">Desarrolladores</div>
																	<div class="sm-description">
																		Documentación y API REST
																	</div>
																</div>
															</div>
														</li>
													</NavLink>
												</ul>
											</div>
										</Item>
									</li>
									<li className="nav-item-button">
										<NavLink
											to="/contacto"
											exact
											activeClassName="selected-link"
											style={{ width: "100%" }}
											className="nav-link"
										>
											Contacto
										</NavLink>
									</li>

									<li className="nav-item-button">
										<Item className="menu-dropdown">
											<NavLink to="#" exact className="nav-link">
												Comunidad ▾
											</NavLink>
											<div class="dropdown-menu" style={{ marginTop: 0 }}>
												<ul>
													{/*
                                                <NavLink to="/">
                                                    <li>
                                                        <div class="item-content">
                                                            <div class="left">
                                                                <img
                                                                    style={{
                                                                        width: 40,
                                                                        marginRight: 20,
                                                                    }}
                                                                    src={
                                                                        GanaIcon
                                                                    }
                                                                />
                                                            </div>
                                                            <div class="right">
                                                                <div class="sm-title">
                                                                    Gana con
                                                                    Referidos
                                                                </div>
                                                                <div class="sm-description">
                                                                    Operaciones
                                                                    rápida vía
                                                                    deposito
                                                                    bancario
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </NavLink>
                                                */}

													<NavLink to="/nosotros">
														<li>
															<div class="item-content">
																<div class="left">
																	<img
																		style={{
																			width: 40,
																			marginRight: 20,
																		}}
																		src={NosotrosIcon}
																		alt="criptomonedas que son"
																	/>
																</div>
																<div class="right">
																	<div class="sm-title">Nosotros</div>
																	<div class="sm-description">
																		Conoce más sobre AgenteBTC
																	</div>
																</div>
															</div>
														</li>
													</NavLink>

													<NavLink
														to={{
															pathname: "https://blog.agentebtc.com/",
														}}
														target="_blank"
													>
														<li>
															<div class="item-content">
																<div class="left">
																	<img
																		style={{
																			width: 40,
																			marginRight: 20,
																		}}
																		src={BlogIcon}
																		alt="criptomonedas que son"
																	/>
																</div>
																<div class="right">
																	<div class="sm-title">Blog</div>
																	<div class="sm-description">
																		Tu propia billetera Bitcoin gratis
																	</div>
																</div>
															</div>
														</li>
													</NavLink>

													<LinkHash to="/#BuyBitcoin">
														<li>
															<div class="item-content">
																<div class="left">
																	<img
																		style={{
																			width: 40,
																			marginRight: 20,
																		}}
																		src={SocialIcon}
																		alt="sociales criptomonedas peru"
																	/>
																</div>

																<div class="right">
																	<div class="sm-title">Redes Sociales</div>
																	<div class="sm-description">
																		Servicio especializado para altos
																		patrimonios
																	</div>
																</div>
															</div>
														</li>
													</LinkHash>
												</ul>
											</div>
										</Item>
									</li>

									<li className="nav-item">
										<a
											href={`${agentebtcapi.webapp}/auth/login`}
											style={{
												width: "100%",
												backgroundColor: "#F2F2F2",
												color: "black",
												fontWeight: "normal",
												color: "#3E5158",
												paddingInline: 20,
												borderRadius: 20,
												marginLeft: 50,
											}}
											activeClassName="selected-link"
											className="nav-link-cta container-wrapper"
										>
											Entrar
										</a>
									</li>
									<li className="nav-item">
										<a
											href={`${agentebtcapi.webapp}/auth/register`}
											style={{
												width: "100%",
												background: `#FFB400`,
												color: "black",
												color: "#3E5158",
												fontWeight: "normal",
												paddingInline: 15,
												borderRadius: 20,
												marginRight: 50,
											}}
											onClick={() => {
												ReactGA.event({
													category: "Registrarse",
													action: " Clic en registro",
												});
											}}
											activeClassName="selected-link"
											className="nav-link-cta"
										>
											Registrarse
										</a>
									</li>

									{/* Honduras and Peru select */}
									<li className="nav-item">
										<div className="country-container">
											<select
												value={selectedCountry}
												onChange={(e) => handleCountryChange(e.target.value)}
												className="select-country"
											>
												<option value="Perú">🇵🇪 Perú</option>
												<option value="Honduras">🇭🇳 Honduras</option>
											</select>
										</div>
									</li>
								</ul>
							</>
						) : (
							<>
								<ul
									className={click ? "nav-menu active" : "nav-menu-center"}
									style={{ paddingInlineStart: 0 }}
								>
									<li
										className="nav-item"
										onClick={handleClick}
										style={{ width: "100%" }}
									>
										<div
											className="menu-icon"
											onClick={handleClick}
											style={{ padding: 10 }}
										>
											{click ? (
												<FontAwesomeIcon
													icon={faTimes}
													style={{ color: "#ffc85c" }}
												/>
											) : (
												<FontAwesomeIcon
													icon={faBars}
													style={{ color: "#ffc85c" }}
												/>
											)}
										</div>
									</li>
									<li
										className="nav-item"
										onClick={handleClick}
										style={{ width: "100%" }}
									>
										<NavLink
											to="/"
											style={{
												width: "100%",
											}}
											activeClassName="selected-link"
											className="nav-links"
										>
											<FontAwesomeIcon icon={faHome} className="icon-padding" />
											Inicio
										</NavLink>
									</li>
									<li
										className="nav-item"
										onClick={handleClick}
										style={{ width: "100%" }}
									>
										<NavLink
											to="/ayuda"
											style={{
												width: "100%",
											}}
											activeClassName="selected-link"
											className="nav-links"
										>
											<FontAwesomeIcon
												icon={faQuestionCircle}
												className="icon-padding "
											/>
											Ayuda
										</NavLink>
									</li>
									<li className="nav-item" style={{ width: "100%" }}>
										<a
											href={`${agentebtcapi.webapp}/auth/login`}
											style={{
												width: "100%",
											}}
											activeClassName="selected-link"
											className="nav-links"
										>
											<FontAwesomeIcon
												icon={faSignInAlt}
												className="icon-padding"
											/>
											Entrar
										</a>
									</li>
									<li className="nav-item" style={{ width: "100%" }}>
										<a
											href={`${agentebtcapi.webapp}/auth/register`}
											onClick={() => {
												ReactGA.event({
													category: "Registrarse",
													action: " Clic en registro",
												});
											}}
											style={{
												width: "100%",
											}}
											activeClassName="selected-link"
											className="nav-links"
										>
											<FontAwesomeIcon
												icon={faUserCheck}
												className="icon-padding"
											/>
											Registrarse
										</a>
									</li>
									<li
										className="nav-item"
										onClick={handleClick}
										style={{ width: "100%" }}
									>
										<NavLink
											to="/"
											style={{
												width: "100%",
											}}
											activeClassName="selected-link"
											className="nav-links"
										>
											🇵🇪 Perú
										</NavLink>
									</li>
									<li
										className="nav-item"
										onClick={handleClick}
										style={{ width: "100%" }}
									>
										<NavLink
											to="/honduras"
											style={{
												width: "100%",
											}}
											activeClassName="selected-link"
											className="nav-links"
										>
											🇭🇳 Honduras
										</NavLink>
									</li>
								</ul>
							</>
						)}
					</nav>
				</div>
			</>
		);
};

export default withRouter(Header);
