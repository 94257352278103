import React, { useState, useRef, useEffect} from 'react';

const Accordion = ({ children, title, state, shortStyle }) => {
  const [active, setActive] = useState(state);
  const [short, setShort] = useState(shortStyle);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active ? `${contentRef.current.scrollHeight}px` : '0px';

    
  }, [contentRef, active]);

  const toogleActive = () => {
    setActive(!active);
  };

 
  return (
    <div className={short ? 'accordion-section short' : 'accordion-section'}>
      <button type="button" className="accordion-title" onClick={toogleActive}>
        <p className="accordion-title-content" >{title}</p>
        <span className={active ? 'accordion-icon rotate' : 'accordion-icon'}>></span>
      </button>

      <div ref={contentRef} className="accordion-content">
        {children}
      </div>
    </div>
  );
};

export default Accordion;
