import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
import {
    faUserCheck,
    faSignInAlt,
    faBars,
    faTimes,
    faHome,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

//import BackgroundHero1 from '../../../public/images/otc/hero1.jpg'
import API_DATA from "../../../src/agentebtcapi"

import './styles.css';




import { Helmet } from "react-helmet";

import agentebtcapi from "agentebtcapi.js";

// Media images

import waveBlueUp from "media/waves/wave_blue_up.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const OTC = ({ history, location }) => {

    const homeRef = useRef(null);
    const benefitsRef = useRef(null);
    const startRef    = useRef(null);
    const contactRef  = useRef(null);
    const [message, setMessage] = useState({
        name : '',
        email : '',
        phone : '',
        origin : 'OTC Page',
        subject : 'Mensaje de Contacto OTC',
        msg : ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [messageError, setMessageError] = useState('');


    const executeScroll = ( _target ) => {
        switch( _target ){
            case 'Benefits':
                benefitsRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'Start':
                startRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'Contact':
                contactRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'Home':
                homeRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                homeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "OTC",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };




    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);


    const validateEmail = ( _email ) => { 
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_email)){
            return (true)
        }
        return (false)
    }

    const changeMessage = ( _field, _value ) => {
        let newMessage = {...message};
        newMessage[ _field ] = _value;
        setMessage( newMessage );
    }

    const sendMessage = async () => {
        if( !validateEmail( message.email ) ){
            setMessageError('Verifica tu información');
            return;
        }

        if( !message.name || !message.phone || !message.email || !message.msg ){
            setMessageError('Verifica tu información');
            return;
        }

        const data = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(message),
        };
        fetch(`${API_DATA.endpoint}/public/contact`, data)
            .then((r) => r.json())
            .then((response) => {
                if( response.type === 'message-sent' ){
                    setMessageError('');
                    setMessageSent( true );
                    return;
                }
                setMessageError( "Hubo un problema al enviar tu mensaje, intenta más tarde." );
            })
            .catch((e) => {
                console.log(e);
            });
    }



    const MenuOTC = () => {
        return (
            <div className="menu-otc-container" ref={homeRef}>
                <div className="menu-otc-logo-container">
                    <img src='images/otc/Logo-agentebtc-vip-white.png' 
                    className="menu-otc-logo"/>
                </div>
                <div className="menu-otc-options-container">
                    <a className="menu-otc-options-link" href="https://agentebtc.com">
                        AgenteBTC
                    </a>
                    <div className="menu-otc-options-link" onClick={()=>executeScroll('Benefits')}>
                        Beneficios
                    </div>
                    <div className="menu-otc-options-link" onClick={()=>executeScroll('Start')}>
                        Cómo Empezar
                    </div>
                    <div className="menu-otc-options-link-button" onClick={()=>executeScroll('Contact')}>
                        Contactar Asesor
                    </div>
                </div>
            </div>
        )
    }

    const Hero1 = () => {
        return(
            <div style={{backgroundImage: `url(images/otc/hero1.jpg)`}} className="otc-hero1-container">
                <div className="otc-hero1-content">
                    <h1 className="otc-hero1-h1">
                        <div className="otc-hero1-h1-1">Mesa de</div>
                        <div className="otc-hero1-h1-2">negociaciones</div>
                    </h1>

                    <p className="otc-hero1-p">
                        Inversiones personalizadas para instituciones e 
                        inversores de alto capital
                    </p>

                    <div className="otc-hero1-button" onClick={()=>executeScroll('Start')}>
                        Hagamos negocios
                    </div>
                </div>
            </div>
        )
    }


    const InfoSection = () => {
        return(
            <div className="otc-info-container">

                    <img src='images/otc/Logo-agentebtc-vip-color.png' 
                    className="info-otc-logo"/>

                    <p className="otc-info-p">
                        Servicio de transacción directa (OTC) para compra
                        y venta de altos volúmenes en Bitcoin u otras
                        criptomonedas. Con seguridad, amplia variedad de activos
                        digitales y un trato especializado.
                    </p>
            </div>
        )
    }

    const BannerSection1 = () => {
        return(
            <div className="otc-banner1-container">

                    <img src='images/otc/check-icon.png' 
                    className="banner1-otc-icon"/>

                    <p className="otc-banner1-p">
                        Juntémonos, conversemos y cerremos un trato
                    </p>
            </div>
        )
    }


    const Benefits = () => {
        return (
            <div className="ben-container" ref={benefitsRef}>
                <h3 className="ben-title">
                    Beneficios
                </h3>
                
                <div className="ben-grid-container">

                    <div className="ben-item">
                        <img src='images/otc/bag-icon.jpg' 
                        className="ben-icon"/>
                        <h2 className="ben-item-title">
                            Liquidez Profunda
                        </h2>
                        <p className="ben-item-p">
                            Nos conectamos con los principales mercados
                            cripto del mundo, no hay límites para tu inversión.
                        </p>
                    </div>

                    <div className="ben-item">
                        <img src='images/otc/graph-icon.jpg' 
                        className="ben-icon"/>
                        <h2 className="ben-item-title">
                            Bajas Comisiones
                        </h2>
                        <p className="ben-item-p">
                            Ahorra con tasas de comisión exclusivas y beneficiosas
                            para ambas partes.
                        </p>
                    </div>

                    <div className="ben-item">
                        <img src='images/otc/box-icon.jpg' 
                        className="ben-icon"/>
                        <h2 className="ben-item-title">
                            Rápida Liquidación
                        </h2>
                        <p className="ben-item-p">
                            Vende fácilmente tus criptos por soles o dólares 
                            y muévelo hacia Europa o Centroamérica.
                        </p>
                    </div>

                    <div className="ben-item">
                        <img src='images/otc/user-icon.jpg' 
                        className="ben-icon"/>
                        <h2 className="ben-item-title">
                            Personalizado
                        </h2>
                        <p className="ben-item-p">
                            Te asignamos un agente especializado 
                            que velará por tus intereses y guiará
                            durante todo el proceso.
                        </p>
                    </div>


                </div>
            </div>
        )
    }


    const Hero2 = () => {
        return (
            <div className="otc-hero2-container" style={{backgroundImage: `url(images/otc/hero2.jpg)`}} ref={startRef}>
                <div className="otc-hero2-col1">
                    <img src='images/otc/Logo-agentebtc-vip-white.png' 
                    className="otc-hero2-col1-logo"/>

                    <h2 className="otc-hero2-col1-title">
                        Opera fácil con nosotros en 3 pasos
                    </h2>
                </div>
                <div className="otc-hero2-col2">

                    <div className="otc-hero2-col2-item">
                        <div className="otc-hero2-col2-item-number">
                            1
                        </div>

                        <div className="otc-hero2-col2-item-col2-content">
                            <h3 className="otc-hero2-col2-item-title">
                                Regístrate
                            </h3>
                            <p className="otc-hero2-col2-item-text">
                                Y valida tu cuenta al nivel 2 <br/>
                                (Inversionista de alto patrimonio)
                            </p>
                            <a href="https://app.agentebtc.com" target='_blank' className="otc-hero2-col2-item-link">
                                Registrar / Iniciar Sesión
                            </a>
                        </div>
                                                    
                    </div>

                    <div className="otc-hero2-col2-item">
                        <div className="otc-hero2-col2-item-number">
                            2
                        </div>

                        <div className="otc-hero2-col2-item-col2-content">
                            <h3 className="otc-hero2-col2-item-title">
                                Negociemos
                            </h3>
                            <p className="otc-hero2-col2-item-text">
                                Mediante un chat personalizado, <br/>
                                atenderemos tu pedido y acordaremos<br/>
                                los términos
                            </p>
                        </div>                       
                    </div>

                    <div className="otc-hero2-col2-item">
                        <div className="otc-hero2-col2-item-number">
                            3
                        </div>

                        <div className="otc-hero2-col2-item-col2-content">
                            <h3 className="otc-hero2-col2-item-title">
                                ¡Trato cerrado!
                            </h3>
                            <p className="otc-hero2-col2-item-text">
                                Realiza tu operación de forma segura<br/>
                                y obtén tus criptos según lo acordado
                            </p>
                        </div>                       
                    </div>

                </div>
            </div>
        )
    }


    const BannerSection2 = () => {
        return (
            <div className="otc-banner2-container" ref={contactRef}>
                <h2 className="otc-banner2-title">
                    Hagamos negocios juntos
                </h2>

                <p className="otc-banner2-text">
                    Contacta a alguno de nuestros asesores
                    para resolver tus dudas <br/>
                    y guiarte durante el proceso
                </p>
            </div>
        )
    }

    const ContactForm = () => {
        return (
            <div className="contact-section-container">
                <div className="contact-section-left-column">
                    <img src={require("../../media/icons/contact_form_image.png")}
                        className="contact-section-left-image"/>
                </div>
                <div className="contact-section-right-column">
                    { !messageSent &&
                        <>
                            <div className="contact-section-form-label">Nombres Completos</div>
                            <input className="contact-section-form-input-text"
                                type="text"
                                value={ message.name }
                                onChange={(e)=>{ changeMessage( 'name', e.target.value)}}
                            />

                            <div className="contact-section-form-label">Correo Electrónico</div>
                            <input className="contact-section-form-input-text"
                                type="text"
                                value={ message.email }
                                onChange={(e)=>{ changeMessage( 'email', e.target.value)}}
                            />

                            <div className="contact-section-form-label">Número de Celular</div>
                            <input className="contact-section-form-input-text"
                                type="text"
                                value={ message.phone }
                                onChange={(e)=>{ changeMessage( 'phone', e.target.value)}}
                            />

                            <div className="contact-section-form-label">Mensaje</div>
                            <textarea className="contact-section-form-input-text"
                                rows={5}
                                onChange={(e)=>{ changeMessage( 'msg', e.target.value)}}
                            >
                                { message.msg }
                            </textarea>
                            
                            <div className='contact-section-form-send-button-container'>
                                <div className='contact-section-form-send-button2'
                                    onClick={()=>{ sendMessage() }}
                                >
                                    Enviar Mensaje
                                </div>
                            </div>
                        </>
                    }


                    { messageSent &&
                        <div className='contact-section-form-send-confirmed-container'>
                            <b>Su mensaje ha sido enviado</b><br/>
                            Uno de nuestros agentes te contactará a la brevedad
                        </div>
                    }

                    { messageError !== '' &&
                        <div className='contact-section-form-send-error-container'>
                            { messageError }
                        </div>
                    }
                    
                </div>
            </div>
        )
    }

    const Footer = () => {
        return (
            <div className="otc-footer-container">

                <div className="otc-footer-col1">
                    <img src='images/otc/Logo-agentebtc-vip-white.png' 
                    className="otc-footer-logo" onClick={()=>executeScroll('Home')}/>
                </div>

                <div className="otc-footer-col2">
                    <a className="otc-footer-link" href="https://agentebtc.com">
                        AgenteBTC
                    </a>
                    <div className="otc-footer-link" onClick={()=>executeScroll('Benefits')}>
                        Beneficios
                    </div>
                    <div className="otc-footer-link" onClick={()=>executeScroll('Start')}>
                        Cómo Empezar
                    </div>
                    <div className="otc-footer-link" onClick={()=>executeScroll('Contact')}>
                        Contactar Asesor
                    </div>
                </div>

                <div className="otc-footer-col3">
                    <a href="https://www.linkedin.com/company/agentebtc/" target='_blank'>
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            className="otc-footer-icon"
                        />
                    </a>

                    <a href="https://twitter.com/agentebtc" target='_blank'>
                        <FontAwesomeIcon
                            icon={faTwitter}
                            className="otc-footer-icon"
                        />
                    </a>

                    <a href="https://www.youtube.com/c/AgenteBTC" target='_blank'>
                        <FontAwesomeIcon
                            icon={faYoutube}
                            className="otc-footer-icon"
                        />
                    </a>

                    <a href="https://www.facebook.com/agentebtc/" target='_blank'>
                        <FontAwesomeIcon
                            icon={faFacebook}
                            className="otc-footer-icon"
                        />
                    </a>

                    <a href="https://www.instagram.com/agentebtc/" target='_blank'>
                        <FontAwesomeIcon
                            icon={faInstagram}
                            className="otc-footer-icon"
                        />
                    </a>
                </div>
            </div>
        )
    }


    return (
        <div className="otc-main-container">
           
            <MenuOTC/>
            <Hero1/>
            <InfoSection/>
            <BannerSection1/>
            <Benefits/>
            <Hero2/>
            <BannerSection2/>
            { ContactForm() }
            <Footer/>
            


            <Helmet>
                <meta charSet="utf-8" />
                <title>Over to the counter (OTC) | AgenteBTC</title>
                <meta
                    name="description"
                    content="Servicio de altas inversiones de activos digitales como bitcoin para instituciones e inversores de alto patrimonio."
                />
            </Helmet>
        </div>
    );
};

export default withRouter(OTC);
