import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { ThemeProvider, createTheme } from "@mui/material/styles";

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: "#1A4BEA",
            dark: "#1A4BEA",
            light: "01fff0",
        },
    },
    components: {
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    marginLeft: 4,
                },
            },
        },
        MuiStepContent: {
            styleOverrides: {
                root: {
                    marginLeft: 4,
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    alignItems: "start",
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                wrapperInner: {
                    marginTop: -23,
                },
            },
        },
    },
});

const HomeStepper = () => {
    const stepsFiat = [
        {
            label:
                "Actualmente, es la criptomoneda más importante y de mayor capitalización de todo el mercado. Al puntode que cualquier evento en torno a Bitcoin tendrá unefecto cadena sobre el esto del ecosistema cripto.",
            content: (
                <a
                    style={{
                        color: "#3E5158CC",
                    }}
                >
                    Bitcoin (BTC) es la primera criptomoneda de la historia,
                    concebida por <b>Satoshi Nakamoto </b>en 2008 y puesta en
                    circulación en el 2010.
                </a>
            ),
        },
        {
            label: "Fue",
            content: (
                <a
                    style={{
                        color: "#3E5158CC",
                    }}
                >
                    Fue revolucionaria por emplear una tecnología denominada{" "}
                    <b>«blockchain»</b>, que permite que todas las transacciones
                    con Bitcoin sean seguras sin depender de un ente bancario o
                    gobierno central.
                </a>
            ),
        },
        {
            label: "Actualmente",
            content: (
                <a
                    style={{
                        color: "#3E5158CC",
                    }}
                >
                    Actualmente, es la{" "}
                    <b>criptomoneda más importante y de mayor capitalización</b>{" "}
                    de todo el mercado. Al puntode que cualquier evento en torno
                    a Bitcoin tendrá unefecto cadena sobre el resto del
                    ecosistema cripto.
                </a>
            ),
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Stepper orientation="vertical">
                {stepsFiat.map((step, index) => (
                    <Step key={step.label} active={true}>
                        <StepLabel
                            StepIconComponent={() => (
                                <div
                                    style={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: "50%",
                                        backgroundColor: "#E6E6E6",
                                    }}
                                />
                            )}
                        />

                        <StepContent>{step.content}</StepContent>
                    </Step>
                ))}
            </Stepper>
        </ThemeProvider>
    );
};
export default HomeStepper;
