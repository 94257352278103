import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";

import heroImg from "media/heros/about_us@2x.png";
import imgLuis from "images/luis@2x.png";
import imgVictor from "images/team/Victor.png";
import imgCristian from "images/team/Christian.png";
import imgBrayan from "images/team/Brayan.png";
import imgFabian from "images/team/Chetino.png";
import imgCristianC from "images/team/Cristian.png";
import imgJhon from "images/team/Jhon.png";
import imgLeonardo from "images/team/Leonardo.png";
import imgNatali from "images/team/Natali.png";
import imgNicky from "images/team/Nicky.png";
import imgWiru from "images/team/Wojtek.png";
import imgHugo from "images/team/Hugo.png";

import logoBcp from "images/logo-bcp-w.png";
import logoBbva from "images/logo-bbva-w.png";
import logoInterbank from "images/logo-interbank-w.png";

import bitcoin from "media/icons/icon_bitcoin.png";
import fiat from "media/fiat@2x.png";
import time from "media/time@2x.png";

// Media images
import iconHouse from "media/icons/icon_house.png";
import iconJoin from "media/icons/icon_join.png";
import iconSecurity from "media/icons/icon_security.png";
import iconCurrency from "media/icons/icon_currency.png";

import heroGetStarted from "media/heros/get_started.png";
import heroWhyChoose from "media/heros/why_we_choose.png";
import heroUseCases from "media/heros/unlock_potential.png";

import bankLogos from "media/logo_banks.png";
import logoRecidar from "media/logo_recidar.png";
import logoUsil from "media/logo_usil.png";
import logoTasaTop from "media/logo_tasatop.png";

import waveGrayUp from "media/waves/wave_gray_up.svg";
import waveGrayDown from "media/waves/wave_gray_down.svg";
import waveBlueUp from "media/waves/wave_blue_up.svg";

import { Helmet } from "react-helmet";

import agentebtcapi from "agentebtcapi.js";

const About = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "About",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.HeroSection>
                    <Styled.HeroText>
                        <Styled.TaglineAbout>
                            <PrimaryText>Nosotros</PrimaryText>
                        </Styled.TaglineAbout>
                        <Styled.SubtaglineHome>
                            <PrimaryText>
                                Creemos que las criptomonedas serán una parte
                                integral de nuestro futuro y por ello combinamos
                                esfuerzos para lograrlo juntos.{" "}
                            </PrimaryText>
                        </Styled.SubtaglineHome>
                    </Styled.HeroText>
                    <Styled.HeroImgWrapper>
                        <Styled.HeroImg src={heroImg} alt="Network" />
                    </Styled.HeroImgWrapper>
                </Styled.HeroSection>
            </Styled.AboutContainer>
            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>Misión</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Ser la más grande conexión de valor de latinoamérica
                            hacia el mundo.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>

            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>Visión</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Dar oportunidad a que cualquiera pueda estar en un
                            sistema financiero abierto, sin barreras.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>

            <Styled.TeamContainer>
                <Styled.ContainerTextTeam>
                    <Styled.TaglineAbout>
                        <PrimaryText>El equipo</PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.DescriptionTeam>
                        <PrimaryText>
                            Con una combinada experiencia en pagos y en
                            desarrollo de software, el equipo de AgenteBTC está
                            preparado para revolucionar el uso práctico de
                            Bitcoin. Además, mejorar los estándares de la
                            industria y potenciar el uso de la tecnología
                            blockchain en latinoamérica
                        </PrimaryText>
                    </Styled.DescriptionTeam>
                    <Styled.ContainerMembers>
                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgVictor} />
                            <Styled.TeamMemberName>
                                Víctor Egoavil
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                CEO - Founder
                            </Styled.TeamMemberPosition>

                            <Styled.TeamMemberDescription>
                                Más 5 años en tecnologías de Pagos Digitales,
                                Criptominería y servicios financieros enfocado a
                                productos digitales. Bach. Administración de
                                Empresa (UDEP, Perú)
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgFabian} />
                            <Styled.TeamMemberName>
                                Fabian Chiera
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                {" "}
                                CTO - Co Founder{" "}
                            </Styled.TeamMemberPosition>

                            <Styled.TeamMemberDescription>
                                20 años en technologías de Blockchain y
                                CiberSeguridad. Bach Software Engineer Security
                                (UNSA - Argentina). Antes: Chief Security
                                Ambassador (CSA) en Eleven Paths.
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgLeonardo} />
                            <Styled.TeamMemberName>
                                Leonardo
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                CGO (Chief Growth Officer)
                            </Styled.TeamMemberPosition>
                            <Styled.TeamMemberDescription>
                                Más de 7 años de experiencia en Marketing en
                                reconocidas empresas digitales.
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgBrayan} />
                            <Styled.TeamMemberName>
                                Brayan Cruces
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                {" "}
                                CPO{" "}
                            </Styled.TeamMemberPosition>

                            <Styled.TeamMemberDescription>
                                Ing. de Software (UPC). Desde el 2016 en el
                                ámbito fintech. Desarrollando productos
                                tecnológicos en busca de la adopción y
                                transformación digital.
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgCristianC} />
                            <Styled.TeamMemberName>
                                Cristian Cruz
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                {" "}
                                Backend Developer
                            </Styled.TeamMemberPosition>

                            <Styled.TeamMemberDescription>
                                Bach Ing. Informática y Sistemas en Universidad
                                Particular de Chiclayo, Perú
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgWiru} />
                            <Styled.TeamMemberName>
                                Wojtek Waleszczyk
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                {" "}
                                Frontend Developer
                            </Styled.TeamMemberPosition>

                            <Styled.TeamMemberDescription>
                                Encargado del frontend en los productos de
                                AgenteBTC. Ing. Informática en Universidad
                                Politécnica de Silesia (Polonia).
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgJhon} />
                            <Styled.TeamMemberName>
                                Jhon Rivera
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                Customer Service
                            </Styled.TeamMemberPosition>
                            <Styled.TeamMemberDescription>
                                Buscando el mejor servicio y soporte para los
                                nuevos criptoagentes.
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>
                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgCristian} />
                            <Styled.TeamMemberName>
                                Cristian Pacheco
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                Designer
                            </Styled.TeamMemberPosition>

                            <Styled.TeamMemberDescription>
                                Diseño en AgenteBTC y responsable de contenidos
                                de Marketing
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgHugo} />
                            <Styled.TeamMemberName>
                                Hugo Luque
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                Strategy Manager
                            </Styled.TeamMemberPosition>
                            <Styled.TeamMemberDescription>
                                Bach. en Administración y Marketing (UPC).
                                Emprendedor y apasionado por conectar y enseñar
                                a latinoamérica al mundo de las criptomonedas
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgNatali} />
                            <Styled.TeamMemberName>
                                Natali Cayao
                            </Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                Practicante
                            </Styled.TeamMemberPosition>
                            <Styled.TeamMemberDescription>
                                Practicante del área de Finanzas
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>

                        <Styled.ContainerTeamMember>
                            <Styled.ImageMember src={imgNicky} />
                            <Styled.TeamMemberName>Nicky</Styled.TeamMemberName>
                            <Styled.TeamMemberPosition>
                                Practicante
                            </Styled.TeamMemberPosition>
                            <Styled.TeamMemberDescription>
                                Practicante del área de Finanzas
                            </Styled.TeamMemberDescription>
                        </Styled.ContainerTeamMember>
                    </Styled.ContainerMembers>
                </Styled.ContainerTextTeam>

                <Styled.ContainerSectionHeader>
                    <TitleSection>
                        “Las huellas de las personas que caminan juntas nunca se
                        borran” <br></br>(Proverbio Africano){" "}
                    </TitleSection>
                </Styled.ContainerSectionHeader>
            </Styled.TeamContainer>
            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>
                            Ahora que nos conoces un poco más ¿Listo para el
                            futuro?{" "}
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Registrate en AgenteBTC en minutos
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSecExt
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comenzar ahora :)
                            </Styled.StyledButtonYellowSecExt>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title>¿Quienes son el equipo de Agentebtc.com?</title>
                <meta
                    name="description"
                    content="El equipo detrás de Agentebtc.com, la principal empresa Bitcoin de LATAM."
                />
            </Helmet>
        </>
    );
};

export default withRouter(About);
