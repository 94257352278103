import React, {useState,  useCallback, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';

import { Header1 } from 'styles/common/index';
import StyledAuth from '../styles';

const Register = ({ history }) => {

  const [buttonEnabled, setButtonEnabled] = useState(true);


  const handleRecaptcha = (value) => {
    console.log("Captcha value:", value);
  }

  const handleSignUp = (e)=>{
    e.preventDefault();
    setButtonEnabled(false);
    const { name, email, password, coupon } = e.target.elements;
    let body = {username: email.value, name: name.value, email: email.value, password: password.value, coupon: coupon.value};
    let data =  {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    }
    let endpoint = '/auth/register';
    let url = `${agentebtcapi}${endpoint}`;
    fetch(url, data)
      .then( response => {

        setButtonEnabled(true);

        response.json().then(json => {
          console.log(response);
          console.log(json);
          if(response.ok){
            swal({text: "Porfavor, revisa tu correo electrónico para confirmar", button: false, timer: 2000});
            history.push('/auth/login');
          }else if(response.status == 400)
            swal({text: "Correo ya existente", button: false, timer: 2000});
          else{
            swal({text: json.msg, button: false, timer: 2000});
          }

        })


      })
      .catch((error)=>{

        setButtonEnabled(true);
        swal({text: "Lo sentimos, no se pudo realizar el registro", button: false, timer: 2000});
      })
  }

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
  });

  return (
    <>
      <Header1>Registro</Header1>
      <StyledAuth.AuthText>Ingresa tu correo electrónico y contraseña para registrarte.</StyledAuth.AuthText>
      <StyledAuth.AuthForm onSubmit={handleSignUp}>
        <StyledAuth.AuthInput name="name" type="text" placeholder="Nombre completo*" required />
        <StyledAuth.AuthInput name="email" type="email" placeholder="Tu correo electrónico*" required />
        <StyledAuth.AuthInput name="password" type="password" placeholder="Una contraseña segura*" required />
        <StyledAuth.AuthInput type="password" placeholder="Repite tu contraseña*" required />
        <StyledAuth.AuthInput name="coupon" type="coupon" placeholder="Cupón (Opcional)"/>
        <ReCAPTCHA
          sitekey="6LceUcsUAAAAAKJ3xKxdp19Ywpwl3JT2ZGmxsJ38"
          onChange={handleRecaptcha}
          className="recaptcha"
        />
      <StyledAuth.AuthButton type="submit">Crear cuenta</StyledAuth.AuthButton>
      </StyledAuth.AuthForm>
      <StyledAuth.AuthText>
        ¿Ya tienes una cuenta? &nbsp;
        <StyledAuth.AuthLink to="/auth/login">
          Inicia sesión
        </StyledAuth.AuthLink>
      </StyledAuth.AuthText>
    </>
  );
};

export default withRouter(Register);
