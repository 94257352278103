import styled from "styled-components";

export const ChartIcon = styled.img`
    width: 40px !important;
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translate(-50%, 0);

    @media screen and (max-width: 768px) {
        width: 25px !important;
    }

    @media screen and (max-width: 640px) {
        width: 15px !important;
    }
`;
