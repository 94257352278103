import React, { useEffect, useState } from "react";
import "./Charts.css";
import agentebtcapi from "agentebtcapi.js";
import { Ring } from "react-awesome-spinners";
import {
    VictoryLine,
    VictoryChart,
    VictoryAxis,
    VictoryVoronoiContainer,
    VictoryTooltip,
} from "victory";
import { ChartPrice, ChartPercentage, LabelContainer } from "./styles";

const formatPrice = (price) => {
    const formatConfig = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });

    return formatConfig.format(price);
};

const LineChart = ({ selectedCurrency }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = () => {

        if( selectedCurrency === 'ALIC' ){
            let manualData = [];
            for( let i = 0; i <= 25; i++ ){
                manualData.push( { x : i, y : 0.05} );
            }
            setData( manualData );
            setLoading(false);
            return;
        }


        const endpoint = "/public/quote/histohour";
        const url = `${agentebtcapi.endpoint}${endpoint}`;
        const body = { limit: "24", currency: selectedCurrency };
        const data = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };
        fetch(url, data)
            .then((r) => r.json())
            .then((cryptoprice) => {
                setData(
                    Object.keys(cryptoprice.Data.Data).map((date, index) => {
                        return {
                            x: cryptoprice.Data.Data[date].time,
                            y: cryptoprice.Data.Data[date].close,
                        };
                    })
                );
                // this.setState({
                //     loading: true,
                // });
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const percValue = (
        ((data[24]?.y - data[1]?.y) / data[24]?.y) *
        100
    ).toFixed(2);

    return (
        <div>
            {loading ? (
                <div className="spinner">
                    <Ring />
                </div>
            ) : (
                <>
                    <VictoryChart
                        width={400}
                        height={200}
                        domainPadding={3}
                        containerComponent={
                            <VictoryVoronoiContainer
                                labels={({ datum }) => formatPrice(datum.y)}
                                title={`${selectedCurrency} price data chart`}
                                labelComponent={
                                    <VictoryTooltip
                                        style={{
                                            fill: "#333",
                                            fontSize: 16,
                                        }}
                                        flyoutStyle={{
                                            fill: "#fff",
                                            stroke: "#fff",
                                            strokeWidth: 1,
                                            margin: 10,
                                        }}
                                    />
                                }
                            />
                        }
                    >
                        <VictoryLine
                            style={{
                                data: {
                                    stroke: (percValue >= 0 || selectedCurrency === 'ALIC') ? "green" : "#F44336",
                                    strokeWidth: 2,
                                    strokeLinejoin: "round",
                                },
                            }}
                            data={data}
                        />
                        <VictoryAxis
                            orientation="bottom"
                            style={{
                                axis: {
                                    stroke: "#ccc",
                                    strokeWidth: 1,
                                },
                                tickLabels: {
                                    fill: "#000",
                                    display: "none",
                                },
                                grid: {
                                    stroke: "#ccc",
                                    strokeWidth: 0.5,
                                },
                            }}
                            data={data}
                        />
                    </VictoryChart>
                    <LabelContainer>
                        <div>
                            <ChartPrice>${data[24]?.y}</ChartPrice>
                        </div>
                        <div>
                            <ChartPercentage
                                style={{
                                    color: (percValue >= 0 || selectedCurrency === 'ALIC') ? "green" : "red",
                                }}
                            >
                                {(
                                    ((data[24]?.y - data[1]?.y) / data[24]?.y) *
                                    100
                                ).toFixed(2)}{" "}
                                %
                            </ChartPercentage>
                        </div>
                    </LabelContainer>{" "}
                </>
            )}
        </div>
    );
};

export default LineChart;
