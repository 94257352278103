import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import agentebtcapi from "agentebtcapi.js";

import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import { Helmet } from "react-helmet";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";
// Get the Chart component
import heroImg from "media/heros/how-to-start.png";
import imgCircleGray from "media/circle_gray.png";

import imgRetener from "media/icons/retener_icon.png";
import imgCompras from "media/icons/compras_icon.png";
import imgTransferencia from "media/icons/transferencia_icon.png";

import iconCreateAccount from "media/icons/icon-create-account.png";
import iconValidateAccount from "media/icons/icon-verify-account.png";

import iconCreateWallet from "media/icons/icon-create-wallet.png";
import iconBuySell from "media/icons/icon-buy-sell.png";

// Media images

import waveBlueUp from "media/waves/wave_blue_up.svg";
import waveGrayDown from "media/waves/wave_gray_down.svg";

const MeetBTC = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "MeetBTC",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.HeroSection>
                    <Styled.HeroText>
                        <Styled.TaglineAbout>
                            <PrimaryText>Cómo empezar</PrimaryText>
                        </Styled.TaglineAbout>
                        <Styled.SubtaglineHome>
                            <PrimaryText>
                                Comprar y vender Bitcoin es ahora una
                                experiencia sencilla. Aquí te explicamos desde
                                cero.
                            </PrimaryText>
                        </Styled.SubtaglineHome>
                    </Styled.HeroText>
                    <Styled.HeroImgWrapper>
                        <Styled.HeroImg src={heroImg} alt="Network" />
                    </Styled.HeroImgWrapper>
                </Styled.HeroSection>
            </Styled.AboutContainer>

            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>Estamos aquí</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Recuerda que si necesitas ayuda personalizada
                            siempre podrás comunicarte con nosotros por chat o o
                            vía telefonica.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>
            <Styled.SectionWaveGrayDown />
            <Styled.ContainerSkyBlue>
                <Styled.Starter>
                    <Styled.ContainerStarter>
                        <Styled.TaglineAbout>
                            <PrimaryText>Comienza en minutos</PrimaryText>
                        </Styled.TaglineAbout>
                        <Styled.DescriptionTeam>
                            <PrimaryText>
                                Iniciar en AgenteBTC es completamente gratuito,
                                una vez registrado y verificado puedes comenzar
                                a adquirir o vender criptomonedas. En 4 pasos ;)
                            </PrimaryText>
                        </Styled.DescriptionTeam>
                        <Styled.MessageForVideo>
                            <PrimaryText>
                                ¿Prefieres seguir estos pasos con ayuda de un
                                video? Entra a nuestro{" "}
                                <Styled.LinkVideo href="https://www.youtube.com/watch?v=Gao1yn-fRXk">
                                    video explicativo ⓘ{" "}
                                </Styled.LinkVideo>{" "}
                            </PrimaryText>
                        </Styled.MessageForVideo>
                    </Styled.ContainerStarter>
                </Styled.Starter>
                <Styled.ContainerSteps>
                    <Styled.ContainerStep>
                        <Styled.StepTitle>1. Crear una cuenta</Styled.StepTitle>
                        <Styled.ContainerStepContent>
                            <Styled.ImageStep
                                src={iconCreateAccount}
                                alt="Crea un cuenta"
                            />
                            <Styled.TextStep>
                                <PrimaryText>
                                    Te registras en la web de AgenteBTC usando
                                    tu correo electrónico. Al final de este paso
                                    te llegará un e-mail que tendrás que
                                    confirmar. ¡Da el primer paso! :)
                                </PrimaryText>
                                <Styled.CallToAction
                                    href={`${agentebtcapi.webapp}/auth/register`}
                                >
                                    Registrate ahora
                                </Styled.CallToAction>
                            </Styled.TextStep>
                        </Styled.ContainerStepContent>
                    </Styled.ContainerStep>
                    <Styled.ContainerStep>
                        <Styled.StepTitle>2. Valida tu cuenta</Styled.StepTitle>
                        <Styled.ContainerStepContent>
                            <Styled.ImageStep
                                src={iconValidateAccount}
                                alt="Crea un cuenta"
                            />
                            <Styled.TextStep>
                                <PrimaryText>
                                    En este paso te solicitaremos ciertos datos
                                    personales para verificar tu identidad:
                                    documento de identidad, pasaporte o carné de
                                    extranjería. <br />
                                    <br />
                                    Esto es parte de nuestro proceso de KYC. Te
                                    tomará solo unos minutos, y después de
                                    validar tu cuenta automáticamente se te
                                    creará una billetera electrónica de Bitcoin.
                                </PrimaryText>
                            </Styled.TextStep>
                        </Styled.ContainerStepContent>
                    </Styled.ContainerStep>
                    <Styled.ContainerStep>
                        <Styled.StepTitle>3. Compra o vende</Styled.StepTitle>
                        <Styled.ContainerStepContent>
                            <Styled.ImageStep
                                src={iconBuySell}
                                alt="Crea un cuenta"
                            />
                            <Styled.TextStep>
                                <PrimaryText>
                                    ¡Ya estás listo para{" "}
                                    <b>comprar bitcoins!</b>. Puedes realizar tu
                                    primera compra a partir de $15 o su
                                    equivalente en soles
                                    <br />
                                    <br />
                                    Por otro lado, si deseas{" "}
                                    <b>vender tus bitcoin</b> vas a necesitar
                                    indicar en el proceso de venta tu cuenta
                                    bancaria donde recibirás los soles o
                                    dólares.{" "}
                                </PrimaryText>
                                <Styled.CallToAction href="https://app.agentebtc.com/app/operation/new">
                                    Comprar ahora
                                </Styled.CallToAction>
                            </Styled.TextStep>
                        </Styled.ContainerStepContent>
                    </Styled.ContainerStep>
                </Styled.ContainerSteps>
                <Styled.TeamContainer>
                    <Styled.ContainerTextTeam>
                        <Styled.TaglineAbout>
                            <PrimaryText>Preguntas frecuentes</PrimaryText>
                        </Styled.TaglineAbout>
                        <Styled.DescriptionTeam>
                            <PrimaryText>
                                Sí te quedaste con alguna duda o pregunta puedes
                                ir a la zona de Ayuda. ¡Espero pueda ayudarte!
                            </PrimaryText>
                        </Styled.DescriptionTeam>

                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellow to="/ayuda">
                                Ir a Ayuda
                            </Styled.StyledButtonYellow>
                        </Styled.ContainerButtons>
                    </Styled.ContainerTextTeam>
                </Styled.TeamContainer>
                <Styled.SectionWave src={waveBlueUp} />
            </Styled.ContainerSkyBlue>
            <Styled.SectionBlueBg>
                <Styled.WrapperPreFooter>
                    <Styled.ContainerWrapperPreFooter>
                        <Header1White>
                            Comprar tu primer bitcoin facil.{" "}
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Comienza con tan solo 15$
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerWrapperPreFooter>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSecExt
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comenzar ahora :)
                            </Styled.StyledButtonYellowSecExt>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperPreFooter>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {" "}
                    Agentebtc.com - Guía para tu primera experiencia bitcoin
                </title>
                <meta
                    name="description"
                    content="Crea una cuenta, Valida tu identidad, Crea tu billetera o wallet y compra/vende."
                />
            </Helmet>
        </>
    );
};

export default withRouter(MeetBTC);
