import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";

import heroImg from "media/heros/about_us@2x.png";
import imgLuis from "images/luis@2x.png";
import imgVictor from "images/victor@2x.png";
import imgCarolina from "images/carolina@2x.png";
import imgCristian from "images/cristian@2x.png";
import imgBrayan from "images/brayan@2x.png";
import imgFabian from "images/fabian@2x.png";

import logoBcp from "images/logo-bcp-w.png";
import logoBbva from "images/logo-bbva-w.png";
import logoInterbank from "images/logo-interbank-w.png";

import bitcoin from "media/icons/icon_bitcoin.png";
import fiat from "media/fiat@2x.png";
import time from "media/time@2x.png";

// Media images
import iconHouse from "media/icons/icon_house.png";
import iconJoin from "media/icons/icon_join.png";
import iconSecurity from "media/icons/icon_security.png";
import iconCurrency from "media/icons/icon_currency.png";

import heroGetStarted from "media/heros/get_started.png";
import heroWhyChoose from "media/heros/why_we_choose.png";
import heroUseCases from "media/heros/unlock_potential.png";

import bankLogos from "media/logo_banks.png";
import logoRecidar from "media/logo_recidar.png";
import logoUsil from "media/logo_usil.png";
import logoTasaTop from "media/logo_tasatop.png";

import waveGrayUp from "media/waves/wave_gray_up.svg";
import waveGrayDown from "media/waves/wave_gray_down.svg";
import waveBlueUp from "media/waves/wave_blue_up.svg";

import { Helmet } from "react-helmet";

import agentebtcapi from "agentebtcapi.js";

const Policy = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Policy",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.ContainerSectionHeader>
                    <Styled.TaglineAbout>
                        <PrimaryText>Política de Privacidad</PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Este apartado explica lo que puedes esperar de
                            Nosotros y qué necesitamos de ti en relación con tus
                            Datos Personales.{" "}
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerSectionHeader>
            </Styled.AboutContainer>

            <Styled.ContentBoxedContainer>
                <Paragraph>
                    Para Compañia de Activos Digitales S.A.C es de suma
                    relevancia la privacidad de la información de nuestros
                    clientes. Nos hemos preocupado de adaptar nuestras políticas
                    a las normativas de vanguardia.
                </Paragraph>

                <Paragraph>
                    Por esta razón, te pedimos que leas con detención estas
                    políticas, dado que son legalmente vinculantes cuando
                    utilices nuestros Servicios, entendiéndose que aceptas las
                    mismas cuando operas con Nosotros.
                </Paragraph>

                <Paragraph>
                    En resumidas cuentas, los aspectos de estas políticas de
                    privacidad son:
                </Paragraph>

                <Styled.ListItems>
                    <Styled.Item>
                        Información y Datos Personales que Agentebtc.com recoge
                        y de qué manera los utiliza.
                    </Styled.Item>
                    <Styled.Item>El uso de cookies.</Styled.Item>
                    <Styled.Item>
                        La seguridad que aplicamos a la información.
                    </Styled.Item>
                    <Styled.Item>
                        La retención por parte de Agentebtc.com de tus Datos
                        Personales.
                    </Styled.Item>
                    <Styled.Item>Transferencia de datos.</Styled.Item>
                    <Styled.Item>
                        Tus derechos relativos a tus Datos Personales.
                    </Styled.Item>
                </Styled.ListItems>

                <Paragraph>
                    Si tienes alguna duda, reclamo, comentario o sugerencia
                    respecto de estas Políticas de Privacidad, a tus derechos y
                    obligaciones desprendidos de las mismas, te solicitamos
                    contactarnos a soporte@agentebtc.com
                </Paragraph>

                <Header3>Definiciones</Header3>

                <Paragraph>
                    <b>“Agentebtc.com”</b>, <b>“Nosotros”</b>, el <b>“Sitio”</b>{" "}
                    y el <b>“responsable”</b> se refiere tanto a Agentebtc.com
                    SAC
                </Paragraph>

                <Paragraph>
                    <b>“Datos o Información Personal”</b> se refiere a toda
                    información del Usuario (en adelante “Usuario”, “tú” o el
                    “interesado”), como una persona natural identificada o
                    identificable, incluyendo nombre, número de identificación,
                    datos de localización, un identificador en línea o distintos
                    elementos propios de la identidad física, fisiológica,
                    genética, psíquica, económica, cultural o social.
                </Paragraph>

                <Paragraph>
                    <b>Servicio(s)</b>. La plataforma, reglas de funcionamiento
                    e intermediación manejada por Agentebtc.com, que permite a
                    los usuarios la venta y compra de criptomonedas
                </Paragraph>

                <Paragraph>
                    <b>La Cuenta</b>. El acuerdo mediante el cual un usuario de
                    Agentebtc.com ha aceptado los términos de uso y las
                    políticas de privacidad y ha sido aprobado para utilizar los
                    Servicios.
                </Paragraph>

                <Header3>La información que recogemos</Header3>

                <Header3>
                    En ciertos casos, recabamos información de forma automática.
                </Header3>

                <Header3>Información proporcionada por terceras partes</Header3>

                <Header3>Cookies</Header3>

                <Header3>Uso de la información</Header3>

                <Header3>Entrega de información a autoridades</Header3>

                <Header3>
                    Transferencia internacional de Información Personal
                </Header3>

                <Header3>Tus derechos, tus elecciones</Header3>

                <Paragraph>
                    Es importante para Nosotros que conozcas los derechos que
                    resguardan la protección de tus Datos Personales, entre los
                    que encontramos:
                </Paragraph>

                <Styled.ListItems>
                    <Styled.Item>
                        <b>Comunicación por e-mail.</b> Podríamos enviar
                        comunicaciones promocionando Servicios, productos,
                        facilidades o actividades usando la información que
                        tenemos tuya. Cuando creas una cuenta en Agentebtc.com,
                        aceptas que te enviemos estos correos. Por supuesto,
                        tienes la posibilidad de opt-out (desuscribirte) de
                        estas noticias cuando quieras, con las excepciones
                        expresadas en el punto anterior que hace referencia al{" "}
                        <b>uso de la información</b>
                    </Styled.Item>

                    <Styled.Item>
                        <b>Acceso.</b> Tienes el derecho para preguntarnos si
                        estamos procesando tu información, o de qué manera la
                        estamos procesando, además de solicitar acceso y una
                        copia de la información que nos hayas proporcionado.{" "}
                    </Styled.Item>

                    <Styled.Item>
                        <b>Rectificación y complementación.</b> Tienes derecho a
                        rectificar o complementar la información que se
                        encuentre errónea, falsa o incompleta sin demora.{" "}
                    </Styled.Item>

                    <Styled.Item>
                        <b>Cancelación.</b> Tienes derecho a solicitar que
                        eliminemos y borremos permanentemente tus datos. Debes
                        tener presente que Agentebtc.com puede guardar la
                        información con la finalidad de resolver disputas o
                        reclamaciones, detectar problemas o incidencias y
                        solucionarlos, y dar cumplimiento a lo dispuesto en los
                        Términos de Uso por un período de tiempo determinado por
                        Ley. Además, AgenteBTC.com debe guardar en sus archivos
                        la Información Personal del Usuario por motivos legales
                        y técnicos.
                    </Styled.Item>

                    <Styled.Item>
                        <b>Portabilidad.</b> Puedes solicitar la entrega de tus
                        Datos Personales a una tercera parte.
                    </Styled.Item>

                    <Styled.Item>
                        <b>Oposición.</b> Cuando procesemos tus datos en base a
                        un interés legítimo de Agentebtc.com, tienes el derecho
                        a oponerte en base a tus intereses, derechos y
                        libertades.
                    </Styled.Item>

                    <Styled.Item>
                        <b>Oposición a valoraciones automatizadas.</b> Si
                        existiera alguna decisión automatizada que te afecte de
                        forma negativa, siempre podrás solicitar la intervención
                        humana para analizar la situación.
                    </Styled.Item>
                </Styled.ListItems>

                <Header3>Confidencialidad de la información</Header3>

                <Paragraph>
                    Una vez inscrito en el Sitio, Agentebtc.com no venderá,
                    arrendará o compartirá la Información Personal excepto en
                    las formas establecidas en estas políticas. Sin perjuicio de
                    ello, el Usuario consiente en forma expresa que
                    Agentebtc.com transfiera total o parcialmente la Información
                    Personal a cualquiera de las sociedades controladas,
                    controlantes y/o vinculadas con Agentebtc.com, a cualquier
                    título y en el momento, forma y condiciones que estime
                    pertinentes.
                </Paragraph>

                <Header3>Seguridad y almacenamiento de la información</Header3>

                <Paragraph>
                    Agentebtc.com está obligada a cumplir con toda la normativa
                    aplicable en materia de medidas de seguridad aplicables a la
                    Información Personal. Adicionalmente, Agentebtc.com usa los
                    estándares de la industria en materia de protección de la
                    confidencialidad de su Información Personal, incluyendo, en
                    otras medidas, cortafuegos ("firewalls") y Secure Socket
                    Layers ("SSL") y además, contamos con un programa de
                    recompensa (bounty program) donde externos intentan
                    continuamente vulnerar nuestro sitio en busca de fallas que
                    luego eventualmente nos reportan. Asimismo, Agentebtc.com
                    trabaja con pruebas periódicas de seguridad de su código.
                    Agentebtc.com considera a los datos de sus Usuarios como un
                    activo que debe ser protegido de cualquier pérdida o acceso
                    no autorizado. Empleamos diversas técnicas de seguridad para
                    proteger tales datos de accesos no autorizados por Usuarios
                    de dentro o fuera de nuestra compañía. Sin embargo, es
                    necesario tener muy en cuenta que la seguridad perfecta no
                    existe en Internet.
                </Paragraph>

                <Paragraph>
                    Por lo anterior, Agentebtc.com no se hace responsable por
                    interceptaciones ilegales o violación de sus sistemas o
                    bases de datos por parte de personas no autorizadas.{" "}
                </Paragraph>
                <Paragraph>
                    Agentebtc.com, tampoco se hace responsable por la indebida
                    utilización de la información obtenida por esos medios.
                </Paragraph>

                <Header3>Cambios a las Políticas de privacidad</Header3>

                <Paragraph>
                    Agentebtc.com podrá modificar en cualquier momento los
                    Términos de Uso de estas Políticas de Privacidad y/o las
                    prácticas de envío de e-mails. Si decidimos introducir algún
                    cambio material a nuestras Políticas de Privacidad, te
                    notificaremos publicando una versión actualizada de las
                    Políticas en esta sección, mediante el envío de un e-mail o
                    informándolo en la página principal u otras secciones del
                    sitio para mantenerte actualizado de los cambios realizados.
                </Paragraph>

                <Paragraph>
                    Si hacemos cambios materiales sustanciales en la forma que
                    tu Información Personal es administrada te notificaremos por
                    e-mail para que puedas tomar una decisión informada respecto
                    a si aceptas o no que tu Información Personal sea utilizada
                    de esa forma. Si no aceptas esos términos, en ese caso
                    quedará disuelto el vínculo contractual y tu Información
                    Personal no será usada de otra forma que la que fue
                    informada al momento de recabarse.
                </Paragraph>

                <Header3>Spam</Header3>

                <Paragraph>
                    Agentebtc.com tomará las precauciones necesarios para evitar
                    el envío no solicitado de e-mails.
                </Paragraph>

                <Paragraph>
                    Cualquier persona que no desee recibir estas comunicaciones
                    por e-mail, deberá hacerlo a través del link “unsuscribe” o
                    "desuscribirse" que aparece al final de cada e-mail.
                </Paragraph>

                <Header3>Contacto</Header3>

                <Paragraph>
                    Si tienes alguna duda, comentario o sugerencia respecto a
                    estas Políticas de Privacidad, a tus derechos y obligaciones
                    desprendidos de las mismas, te solicitamos contactarnos a
                    soporte@agentebtc.com.
                </Paragraph>
            </Styled.ContentBoxedContainer>

            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>
                            Ahora que nos conoces un poco más ¿Listo para el
                            futuro?{" "}
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Registrate en AgenteBTC en minutos
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSecExt
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comenzar ahora :)
                            </Styled.StyledButtonYellowSecExt>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Políticas de Privacidad | Agente BTC</title>
                <meta
                    name="description"
                    content="Política de Privacidad de Agente BTC, el primer exchange peruano de Bitcoin."
                />
            </Helmet>
        </>
    );
};

export default withRouter(Policy);
