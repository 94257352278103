import styled from "styled-components";
import colors from "styles/common/colors.js";

export const TopHeader = styled.header`
    background: white;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    top: 60;
    left: 0;
    right: 0;
    width: 100%;
`;

export const Nav = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    z-index: 21;
`;

export const GeneralLinksContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: flex-start;
`;

export const Item = styled.li`
    position: relative;
    cursor: pointer;
`;

export const Logo = styled.img`
    width: 100%;
    max-width: 160px;
    margin-top: 8px;

    @media screen and (max-width: 640px) {
        max-width: 120px;
    }
`;

export const LogoutBtn = styled.button`
    background: #f2a900;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    padding: 6px 18px;
    border-radius: 24px;

    @media screen and (max-width: 640px) {
        padding: 3px 6px 4px;
    }
`;

export const Menu = styled.ul`
    width: 100%;
    display: flex;
    flex-directio: row;
    list-style-type: none;
    justify-content: center;
    box-sizing: border-box;
    @media screen and (max-width: 640px) {
        font-size: 14px;
        font-weight: 400;
        padding-inline-start: 10px;
    }
    @media screen and (max-width: 320px) {
        font-size: 11px;
    }
`;

export default {
    TopHeader,
};
