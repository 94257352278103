import styled from "styled-components";
import colors from "./colors";
import Select from "react-select";
import { HashLink } from "react-router-hash-link";

export const Header1 = styled.h1`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 640px) {
        padding: 0 24px;
    }
`;

export const Header1Cards = styled.h1`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 26x;
    font-weight: 400;
    text-align: center;
    @media screen and (max-width: 640px) {
        font-size: 23px;
    }
`;

export const Header2 = styled.h2`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 28px;
    text-align: center;
    font-weight: 600;
`;

export const Header3 = styled.h3`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 25px;
    margin: 0;
    font-weight: 600;
    @media screen and (max-width: 640px) {
        font-size: 22px;
    }
`;

export const Header4 = styled.h4`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    line-height: 2.2;
`;

export const Header5 = styled.h5`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 14px;
    margin: 0;
    font-weight: 600;
`;

export const Paragraph = styled.p`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 15px;
    font-weight: 400;
    line-height: 1.8;
`;

export const AyudaSection = styled.div`
    flex-direction: row;
    display: flex;
    margin-top: 100px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ConfianSection = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    margin-top: 70px;
    @media (max-width: 900px) {
        flex-direction: column;
        padding: 0 24px;
    }
`;

export const ConfianSectionCarusele = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    margin-top: 70px;
    @media (max-width: 900px) {
        margin-top: 30px;
        flex-direction: column;
        padding: 0 24px;
    }
`;

export const AyudaImg = styled.div`
    width: 25%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const AccordionSection = styled.div`
    width: 75%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const SocialMedia = styled.div`
    text-align: left;
    background-color: white;
    height: 140px;
    width: 350px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.1);
`;

export const FunctionaElement = styled.div`
    width: 120px;
    height: 120px
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
    box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.1);
`;

export const TitleSection = styled.h1`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 25px;
    font-weight: 700;
`;

export const ParagraphSubtitle = styled.p`
    font-family: "Poppins", sans-serif;
    color: ${colors.dark_blue_agente_btc};
    font-size: 25px;
    font-weight: 300;
`;

export const WarningBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 13px;
    border-radius: 4px;
    background-color: #ffeece;
    margin-top: 20px;
    border: 1px solid #ffc85c;
    left: 0;
    right: 0;
    text-align: center;
    color: #3c4a5b;
`;

export const BtnBlueSm = styled(HashLink)`
    padding: 12px;
    background-color: #1a4bea;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    width: 120px;
    display: inline;
    cursor: pointer;
    margin: 0px 10px;
    text-decoration: none;
    font-weight: 600;
`;

export const Input = styled.input`
    position: relative;
    height: 30px;
    max-width: 400px;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    text-align: left;
    margin: 10px 0;
    border: none;
    border-bottom: 1px solid #3c4a5b;
    box-sizing: border-box;
    text-align: left;
    padding-left: 10px;
    font-weight: 400;
    display: flex;
    outline: none;

    -moz-appearance: textfield;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const TextData = styled.div`
    position: relative;
    max-width: 400px;
    width: 100%;
    text-align: left;
    font-family: Montserrat;
    font-size: 14px;
    box-sizing: border-box;
    font-weight: 400;
    display: flex;
    outline: none;
    -moz-appearance: textfield;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

// Alternatives

export const Header1White = styled(Header1)`
    color: #ffffff;
`;

export const ParagraphSubtitleWhite = styled(ParagraphSubtitle)`
    color: #ffffff;
`;
