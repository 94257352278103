import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "aos/dist/aos.css";
import API_DATA from "../../../../src/agentebtcapi";

// Styled components
import { Section, Space, Wrapper } from "styles/Styled";

import { PrimaryTextLanding, SecondaryTextLandingSec } from "styles/colorized";

import Styled from "./styles";
import "./styles.css";

// Get the USD to PEN rate
import { ExchangeProvider } from "ExchangeContext";
// Get the time
import { DateProvider } from "DateContext";

import agentebtcapi from "agentebtcapi.js";

import { Helmet } from "react-helmet";

const ContactHonduras = ({ location }) => {
	const [message, setMessage] = useState({
		name: "",
		email: "",
		phone: "",
		origin: "Landing Page",
		subject: "Mensaje de Contacto",
		msg: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [messageSent, setMessageSent] = useState(false);
	const [messageError, setMessageError] = useState("");

	window.dataLayer.push({
		event: "pageview",
		page: {
			url: location,
			title: "Nosotros",
		},
	});

	const validateEmail = (_email) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_email)) {
			return true;
		}
		return false;
	};

	const changeMessage = (_field, _value) => {
		let newMessage = { ...message };
		newMessage[_field] = _value;
		setMessage(newMessage);
	};

	const sendMessage = async () => {
		if (!validateEmail(message.email)) {
			setMessageError("Verifica tu información");
			return;
		}

		if (!message.name || !message.phone || !message.email || !message.msg) {
			setMessageError("Verifica tu información");
			return;
		}

		const data = {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(message),
		};
		fetch(`${API_DATA.endpoint}/public/contact`, data)
			.then((r) => r.json())
			.then((response) => {
				if (response.type === "message-sent") {
					setMessageError("");
					setMessageSent(true);
					return;
				}
				setMessageError(
					"Hubo un problema al enviar tu mensaje, intenta más tarde."
				);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<>
			<ExchangeProvider>
				<DateProvider>
					<>
						<Styled.HomeContainer>
							<Styled.Section style={{ paddingBottom: 15 }}>
								<img
									src={require("../../../media/home/LandBack_1.png")}
									style={{
										position: "absolute",
										zIndex: -1,
										top: 0,
										height: "5%",
										left: 0,
										opacity: 0.5,
									}}
									width={"100%"}
								/>
								<div className="contact-banner-hn">
									<Styled.HeaderTextSecond
										style={{
											color: "#fff",
										}}
									>
										CONT&Aacute;CTANOS
									</Styled.HeaderTextSecond>
									<div style={{ paddingTop: 20 }}>
										<Styled.HeaderTextThird
											style={{
												color: "#fff",
												fontWeight: 300,
											}}
										>
											Un agente especializado está listo
											<br />
											para ayudarte a resolvcer tus dudas.
										</Styled.HeaderTextThird>
									</div>
								</div>
							</Styled.Section>
						</Styled.HomeContainer>
						<Section>
							<Wrapper style={{ paddingInline: 0 }}>
								<div className="contact-section-container">
									<div className="contact-section-right-column">
										<p className="contact-form-title">
											Completa este formulario y envíanos tus consultas.
										</p>
										{!messageSent && (
											<>
												<div className="contact-section-form-label">
													Nombres Completos
												</div>
												<input
													className="contact-section-form-input-text"
													type="text"
													value={message.name}
													onChange={(e) => {
														changeMessage("name", e.target.value);
													}}
												/>

												<div className="contact-section-form-label">
													Correo Electrónico
												</div>
												<input
													className="contact-section-form-input-text"
													type="text"
													value={message.email}
													onChange={(e) => {
														changeMessage("email", e.target.value);
													}}
												/>

												<div className="contact-section-form-label">
													Número de Celular
												</div>
												<input
													className="contact-section-form-input-text"
													type="text"
													value={message.phone}
													onChange={(e) => {
														changeMessage("phone", e.target.value);
													}}
												/>

												<div className="contact-section-form-label">
													Mensaje
												</div>
												<textarea
													className="contact-section-form-input-text"
													rows={5}
													onChange={(e) => {
														changeMessage("msg", e.target.value);
													}}
												>
													{message.msg}
												</textarea>

												<div className="contact-section-form-send-button-container">
													<div
														className="contact-section-form-send-button"
														onClick={() => {
															sendMessage();
														}}
													>
														Enviar Mensaje
													</div>
												</div>
											</>
										)}

										{messageSent && (
											<div className="contact-section-form-send-confirmed-container">
												<b>Su mensaje ha sido enviado</b>
												<br />
												Uno de nuestros agentes te contactará a la brevedad
											</div>
										)}

										{messageError !== "" && (
											<div className="contact-section-form-send-error-container">
												{messageError}
											</div>
										)}
									</div>

									<div className="contact-section-left-column">
										<img
											src="https://i.imgur.com/xr6uiGq.png"
											className="contact-section-left-image"
										/>
										<div className="contact-small-box-hn">
											<span className="contact-small-box-span-hn">
												Puedes contactarnos
											</span>
											<br />
											A través del siguiente formulario
											de Atención al cliente
										</div>
									</div>
								</div>

								<div className="contact-bottom-text">
									<p>
										O también puedes contactarnos a través de los siguientes
										canales.
									</p>
								</div>
								<Styled.WrapperContactSection>
									<Styled.ContactSection style={{ width: "100%" }}>
										{/* 
                                        <Styled.ContainerContact
											style={{
												flexDirection: "column",
											}}
										>
											<Styled.ContactCard>
												<div
													style={{
														padding: 5,
														display: "inline-flex",
													}}
												>
													<Styled.ContactCardImage
														alt="wallet of criptomonedas"
														src={require("../../../media/icons/contact_icon_1.png")}
													/>
													<Styled.ContactCardTitle>
														soporte@agentebtc.com
													</Styled.ContactCardTitle>
												</div>
											</Styled.ContactCard>
										</Styled.ContainerContact>
										<Styled.ContainerContact
											style={{
												flexDirection: "column",
											}}
										>
											<Styled.ContactCard>
												<div
													style={{
														padding: 5,
														display: "inline-flex",
													}}
												>
													<Styled.ContactCardImage
														alt="wallet of criptomonedas"
														src={require("../../../media/icons/contact_icon_2.png")}
													/>
													<Styled.ContactCardTitle>
														975-183-710
													</Styled.ContactCardTitle>
												</div>
											</Styled.ContactCard>
										</Styled.ContainerContact> 
                                        */}

										<Styled.ContainerContact
											style={{
												flexDirection: "column",
											}}
										>
											<Styled.ContactCard>
												<div
													style={{
														padding: 5,
														display: "inline-flex",
													}}
												>
													<a
														href="https://t.me/agenteBTC_PE?utm_source=linktree&utm_medium=blog&utm_campaign=linktreetelegram"
														target="_blank"
														style={{
															textDecoration: "none",
															color: "#3e5158cc",
															display: "inline-flex",
														}}
													>
														<Styled.ContactCardImage
															alt="wallet of criptomonedas"
															src={require("../../../media/icons/date_icon_1.png")}
														/>
														<Styled.ContactCardTitle>
															Telegram
														</Styled.ContactCardTitle>
													</a>
												</div>
											</Styled.ContactCard>
										</Styled.ContainerContact>
										<Styled.ContainerContact
											style={{
												flexDirection: "column",
											}}
										>
											<Styled.ContactCard>
												<div
													style={{
														padding: 5,
														display: "inline-flex",
													}}
												>
													<a
														href="https://api.whatsapp.com/send?phone=51975183710"
														target="_blank"
														style={{
															textDecoration: "none",
															color: "#3e5158cc",
															display: "inline-flex",
														}}
													>
														<Styled.ContactCardImage
															alt="wallet of criptomonedas"
															src={require("../../../media/icons/contact_icon_3.png")}
														/>
														<Styled.ContactCardTitle>
															WhatsApp
														</Styled.ContactCardTitle>
													</a>
												</div>
											</Styled.ContactCard>
										</Styled.ContainerContact>
									</Styled.ContactSection>
								</Styled.WrapperContactSection>
								<Space />
								<Space />
							</Wrapper>
						</Section>
					</>
				</DateProvider>
			</ExchangeProvider>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Compra y vende Bitcoin en Perú con Soles y Dólares | Agente BTC
				</title>
				<meta
					name="description"
					content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://agentebtc.com/" />
				<meta
					property="og:title"
					content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
				/>
				<meta
					property="og:description"
					content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
				/>
				<meta
					property="og:image"
					content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content="https://agentebtc.com/" />
				<meta
					property="twitter:title"
					content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
				/>
				<meta
					property="twitter:description"
					content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
				/>
				<meta
					property="twitter:image"
					content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
				/>
			</Helmet>
		</>
	);
};

export default withRouter(ContactHonduras);
