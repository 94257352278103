import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carusel.css";
import { Carousel } from "react-responsive-carousel";
import LineChart from "../LandingCharts/LineChart";
import agentebtcapi from "agentebtcapi.js";
import { Ring } from "react-awesome-spinners";
import { ChartIcon } from "./styles";

const cryptoArray = ["btc", "eth", "ada", "sol", "shib", "matic", "doge"];

export const CarusaleView = () => {
    const [cryptos, setCryptos] = useState([]);
    const [loading, setLoading] = useState(false);
    const getCryptos = async () => {
        const endpoint = "/public/cryptos";
        const url = `${agentebtcapi.endpoint}${endpoint}`;
        const data = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
        };
        await fetch(url, data)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw { type: "non-user" };
                }
            })
            .then((responseJson) => {
                setCryptos(responseJson);
                setLoading(true);
                console.log(responseJson, "asdasdas");
            })
            .catch((error) => {
                console.log("Ha habido un error en nuestro sistema");
            });
    };
    const createCarouselItemImage = (index, options = {}) => (
        <div
            key={index}
            style={{
                borderRadius: 30,
                boxShadow: "4px 6px 4px #9E9E9E",
                alignItems: "center",
                backgroundColor: "white",
                overflow: "visible",
                paddingTop: 5,
                height: "100%",
            }}
        >
            <ChartIcon
                src={cryptos[index]?.image}
                alt={`${cryptos[index]?.symbol} criptomonedas peru`}
            />
            <LineChart selectedCurrency={cryptos[index]?.symbol} />
        </div>
    );

    const width = window.innerWidth;
    const baseChildren = (
        <div>{[0, 1, 2, 3, 4, 5, 6].map(createCarouselItemImage)}</div>
    );

    useEffect(() => {
        getCryptos();
    }, []);
    return (
        <div className="carousel-outer">
            {loading ? (
                <Carousel
                    infiniteLoop
                    centerMode
                    centerSlidePercentage={width < 640 ? 40 : 25}
                    showArrows={false}
                    autoPlay
                    dynamicHeight
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                >
                    {baseChildren.props.children}
                </Carousel>
            ) : (
                <div className="spinner">
                    <Ring />
                </div>
            )}
        </div>
    );
};
