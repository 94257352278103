import styled from "styled-components";

export const ChartPrice = styled.a`
    color: #808080;
    font-weight: bold;
    font-size: 16px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }

    @media screen and (max-width: 640px) {
        font-size: 10px;
    }
`;

export const ChartPercentage = styled.a`
    font-weight: bold;
    font-size: 16px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }

    @media screen and (max-width: 640px) {
        font-size: 10px;
    }
`;

export const LabelContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-block-start: -10px;
    padding-block-end: 10px;

    @media screen and (max-width: 768px) {
        display: block;
    }

    @media screen and (max-width: 640px) {
        display: block;
    }
`;
