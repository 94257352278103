import React from "react";
import Accordion from "./Accordion";

function AccordionPartner() {
    return (
        <>
            <Accordion
                className="active"
                title=" ¿Por qué ser parte de AgenteBTC?"
            >
                <p>
                    <ul>
                        {" "}
                        AgenteBTC es una red especializada en la venta y compra
                        de criptomonedas. Estar dentro de la red más grande de
                        socios de AgenteBTC te permitirá poder tener una moneda
                        virtual para ofrecer a los clientes y así tener una
                        moneda virtual que se usa a nivel global. Además.
                        AgenteBTC se encarga de proveer la tecnologia y gestión
                        de los Bitcoins. De manera fácil y segura.
                    </ul>{" "}
                </p>
            </Accordion>
            <Accordion title="¿Qué beneficios trae ser parte de AgenteBTC?">
                <p>
                    <ul>- Comisionan por transacción realizada</ul>
                    <ul>
                        - Diferenciación entre sus competidores sobre un activo
                        completamente electrónica y estar a nivel global
                    </ul>
                    <ul>
                        - Entregan un producto que se utiliza a nivel global
                        tanto como activo de dinero, como para Remesas
                        globales(y pertenecer a las primeras casa de cambio a
                        nivel nacional que ofrezcan criptomonedas)
                    </ul>
                    <ul>
                        - Son capacitados para poder utilizar la plataforma y
                        gestionar múltiples billeteras electrónicas
                    </ul>
                    <ul>
                        - Publicidad dentro de la página web, en sus redes
                        sociales y en el mapa de Google Maps, por parte de
                        "AgenteBTC" a las casas de cambio asociadas
                    </ul>
                </p>
            </Accordion>

            <Accordion title="¿Qué necesito para poder asociarme con AgenteBTC?">
                <p>
                    <ul>
                        - Contar con Licencia de Casa de Cambio en la
                        SBS(Superintendencia de Banca y Seguros del Perú)
                    </ul>
                    <ul>
                        - Un año mínimo de funcionamiento en el mercado local
                    </ul>
                    <ul>
                        - Computadoras internas y usar la aplicación móvil
                        WhatsApp
                    </ul>
                    <ul>
                        - Tener cuentas en uno de los bancos a los que estamos
                        asociados: BBVA o Interbank. De preferencia tener
                        cuentas en dólares. Mas no es obligatorio.
                    </ul>
                </p>
            </Accordion>

            <Accordion title="¿Tiene algún costo asociarme o integrarme con AgenteBTC?">
                <p>
                    <ul>
                        No, no hay ningún costo por la asociación o integración
                        con AgenteBTC.
                    </ul>
                </p>
            </Accordion>
        </>
    );
}

export default AccordionPartner;
