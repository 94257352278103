import React, { useState, createContext, useEffect } from 'react';

export const BtcPriceContext = createContext();

export const BtcPriceProvider = ({ children }) => {
  const [btcPrice, setBtcPrice] = useState({});

  const getBtcPrice = async () => {
    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    const API = 'https://api.coindesk.com/v1/bpi/historical/close.json'; // site that doesn’t send Access-Control-*
    const response = await fetch(proxyurl + API);
    const data = await response.json();
    const priceData = (data.bpi);
    setBtcPrice(priceData);
  };

  useEffect(() => {
    getBtcPrice();
  }, []);

  return <BtcPriceContext.Provider value={btcPrice}>{children}</BtcPriceContext.Provider>;
};
