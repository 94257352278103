import React from "react";
import { withRouter } from "react-router";
import "aos/dist/aos.css";

// Styled components
import { Section, Wrapper } from "styles/Styled";

import { PrimaryTextLanding, SecondaryTextLandingSec } from "styles/colorized";
import HomeStepper from "../../components/ConceStepper/index";
import imgBlog from "media/home/blog_icon.png";
import imgTelegram from "media/home/telegram_icon.png";
import imgYT from "media/home/youtube_icon.png";

import Styled from "./styles";

// Get the USD to PEN rate
import { ExchangeProvider } from "ExchangeContext";
// Get the time
import { DateProvider } from "DateContext";

import agentebtcapi from "agentebtcapi.js";

import { Helmet } from "react-helmet";

import { Paragraph } from "styles/common/index.js";

const MeetBTC = ({ location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "ConceBitcoin",
        },
    });

    return (
        <>
            <ExchangeProvider>
                <DateProvider>
                    <Styled.HomeContainer>
                        <div
                            style={{
                                backgroundColor: "#1A4BEA",
                                height: "52vh",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    marginTop: 60,
                                    width: "100%",
                                    textAlign: "center",
                                    display: "inline-grid",
                                }}
                            >
                                <Styled.MainTitle>
                                    Conoce{" "}
                                    <a style={{ color: "#FFC500" }}>Bitcoin</a>
                                </Styled.MainTitle>
                                <a style={{ fontSize: 23 }}>
                                    La primera criptomoneda del mundo
                                </a>
                                <Styled.ContainerButtons>
                                    <Styled.StyledButtonBlueExt
                                        href={`${agentebtcapi.webapp}/auth/register`}
                                        style={{
                                            background: `linear-gradient(#FFC500,#F7931E)`,
                                        }}
                                    >
                                        Comience con $15
                                    </Styled.StyledButtonBlueExt>
                                </Styled.ContainerButtons>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: 35,
                                }}
                            >
                                {/* <Styled.ChartCard></Styled.ChartCard> */}
                            </div>
                        </div>
                        <Styled.WhiteContainer />
                        <Section
                            style={{
                                paddingBottom: 15,
                                paddingTop: 0,
                            }}
                        >
                            <img
                                src={require("../../media/conce_background.png")}
                                style={{
                                    position: "absolute",
                                    top: 100,
                                    left: "50%",
                                    opacity: 0.7,
                                    transform: "translateX(-50%)",
                                    width: "70%",
                                    paddingLeft: "inherit",
                                    paddingRight: "inherit",
                                }}
                                width={"100%"}
                            />
                        </Section>
                    </Styled.HomeContainer>
                    <Section>
                        <Wrapper style={{ paddingInline: 0 }}>
                            <div
                                style={{
                                    paddingBottom: 25,
                                }}
                            >
                                <Styled.SectionTwoBox>
                                    <Styled.SectionTwoImageContainer>
                                        <Styled.SectionTwoImg
                                            src={require("../../media/conce_bitcoin_coins.png")}
                                            alt="comprar en peru, bitcoin a dolar"
                                        />
                                    </Styled.SectionTwoImageContainer>
                                    <Styled.SectionTwo>
                                        <Styled.TaglineHome>
                                            <PrimaryTextLanding>
                                                ¿Qué es
                                            </PrimaryTextLanding>
                                            &nbsp;
                                            <SecondaryTextLandingSec>
                                                BITCOIN?
                                            </SecondaryTextLandingSec>
                                        </Styled.TaglineHome>
                                        <div style={{ width: "65%" }}>
                                            <HomeStepper />
                                            <Styled.ContainerButtons
                                                style={{
                                                    justifyContent: "left",
                                                }}
                                            >
                                                <Styled.StyledButtonBlueExt
                                                    href={`${agentebtcapi.webapp}/auth/register`}
                                                    style={{
                                                        background: `linear-gradient(#FFC500,#F7931E)`,
                                                        paddingInline: 10,
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 30,
                                                            paddingRight: 5,
                                                        }}
                                                        src={require("../../media/icons/button_btc_icon.png")}
                                                    />
                                                    Comprar BTC
                                                    <img
                                                        style={{
                                                            width: 30,
                                                            paddingLeft: 20,
                                                        }}
                                                        src={require("../../media/icons/arrowIcon.png")}
                                                    />
                                                </Styled.StyledButtonBlueExt>
                                            </Styled.ContainerButtons>
                                        </div>
                                    </Styled.SectionTwo>
                                </Styled.SectionTwoBox>
                            </div>
                        </Wrapper>
                    </Section>
                    <Section>
                        <Wrapper
                            style={{
                                paddingInline: 0,
                                paddingTop: 40,
                                backgroundColor: "#F4FAFF",
                            }}
                        >
                            <Styled.TaglineHome
                                style={{
                                    textAlign: "center",
                                    paddingBottom: 30,
                                }}
                            >
                                <PrimaryTextLanding>¿Qué es</PrimaryTextLanding>
                                &nbsp;
                                <SecondaryTextLandingSec>
                                    BITCOIN?
                                </SecondaryTextLandingSec>
                            </Styled.TaglineHome>
                            <Styled.CardCenter>
                                <Styled.SingleCard>
                                    <Styled.TitleSection>
                                        1. Como instrumento de ahorro
                                    </Styled.TitleSection>
                                    <Styled.Devider />
                                    <Styled.TextSection>
                                        Las criptomonedas se compran a cierto
                                        precio esperando que el mismo incremente
                                        con el paso del tiempo, revalorizando
                                        así la inversión.
                                    </Styled.TextSection>
                                    <Styled.TextSection>
                                        <b>Bitcoin</b> ha conseguido incrementar
                                        su precio de forma{" "}
                                        <b>
                                            consistente y drástica a largo plazo
                                        </b>
                                        , justificando así su fama.
                                    </Styled.TextSection>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingTop: 20,
                                        }}
                                    >
                                        <Styled.TextSectionImg
                                            src={require("../../media/chart_conce_btc.png")}
                                        />
                                    </div>
                                </Styled.SingleCard>
                            </Styled.CardCenter>
                            <Styled.CardCenter>
                                <Styled.SingleCard>
                                    <Styled.TitleSection>
                                        2. Como moneda de Intercambio
                                    </Styled.TitleSection>
                                    <Styled.Devider />
                                    <Styled.TextSection>
                                        Existen miles de establecimientos a lo
                                        largo del mundo que aceptan
                                        criptomonedas como método de pago.
                                    </Styled.TextSection>
                                    <Styled.TextSection>
                                        De todas,{" "}
                                        <b>Bitcoin es la más aceptada</b> y
                                        puedes revisar su directorio online{" "}
                                        <span style={{ color: "#1A4BEA" }}>
                                            aquí
                                        </span>
                                        .
                                    </Styled.TextSection>
                                    <Styled.TextSection>
                                        En <b>Perú</b>, resaltamos que ya se ha
                                        comenzado a implementar en negocios
                                        físicos en el distrito de{" "}
                                        <span style={{ color: "#1A4BEA" }}>
                                            La Molina
                                        </span>
                                        .
                                    </Styled.TextSection>
                                </Styled.SingleCard>
                            </Styled.CardCenter>
                            <Styled.CardCenter>
                                <Styled.SingleCard>
                                    <Styled.TitleSection>
                                        3. Como instrumento de Trading
                                    </Styled.TitleSection>
                                    <Styled.Devider />
                                    <Styled.TextSection>
                                        Las personas pueden emplear las
                                        criptomonedas como “acciones” en
                                        plataformas de trading y obtener
                                        ganancias a corto plazo.
                                    </Styled.TextSection>
                                    <Styled.TextSection>
                                        Nuevamente,{" "}
                                        <b>Bitcoin es la más empleada</b> para
                                        estas actividades, gracias a su elevado
                                        volumen de compra/venta.
                                    </Styled.TextSection>
                                </Styled.SingleCard>
                            </Styled.CardCenter>
                            <Styled.CardCenter>
                                <Styled.SingleCard>
                                    <Styled.TitleSection>
                                        4. Como instrumento de Inversión
                                    </Styled.TitleSection>
                                    <Styled.Devider />
                                    <Styled.TextSection>
                                        Existen maneras de incrementar aún más
                                        el valor obtenido con el ahorro.
                                    </Styled.TextSection>
                                    <Styled.TextSection>
                                        “Staking”, “Farming” y “Liquidity Pool”,
                                        son algunos de estos métodos.{" "}
                                        <span style={{ color: "#1A4BEA" }}>
                                            Puedes leer más sobre ello aquí
                                        </span>
                                        .
                                    </Styled.TextSection>
                                </Styled.SingleCard>
                            </Styled.CardCenter>
                        </Wrapper>
                    </Section>
                    <Section>
                        <Wrapper
                            style={{
                                paddingInline: 0,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    paddingBottom: 25,
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Styled.TaglineHomeSec>
                                        <PrimaryTextLanding>
                                            ¿Cómo comprar Bitcoin?
                                        </PrimaryTextLanding>
                                        <p
                                            style={{
                                                fontSize: 18,
                                                color: "#3E5158cc",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Somos una{" "}
                                            <span
                                                style={{ color: "#FFC500cc" }}
                                            >
                                                exchange autorizada
                                            </span>
                                            . ¡Así que puedes comprarlo en
                                            AgenteBTC! Hemos simplificado el
                                            proceso en tan solo 3 pasos
                                        </p>
                                    </Styled.TaglineHomeSec>
                                </div>
                                <Styled.SectionTwoBox>
                                    <Styled.SectionTwoImageContainer>
                                        <Styled.SectionTwoImg
                                            style={{ width: "100%" }}
                                            src={require("../../media/conce_laptop.png")}
                                            alt="comprar en peru, bitcoin a dolar"
                                        />
                                    </Styled.SectionTwoImageContainer>
                                    <Styled.SectionTwo>
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: 80,
                                                        display: "table-cell",
                                                        paddingRight: 25,
                                                    }}
                                                    alt="wallet of criptomonedas"
                                                    src={require("../../media/icons/StepperIcon_1.png")}
                                                />

                                                <div
                                                    style={{
                                                        display: "table-cell",
                                                    }}
                                                >
                                                    <Styled.SectionTwoText>
                                                        Crea tu cuenta gratuita
                                                    </Styled.SectionTwoText>
                                                    <Styled.SectionTwoAdditionalText>
                                                        Y verificala para una
                                                        mayor seguridad
                                                    </Styled.SectionTwoAdditionalText>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: 80,
                                                        display: "table-cell",
                                                        paddingRight: 25,
                                                    }}
                                                    alt="calendar criptomoneda"
                                                    src={require("../../media/icons/StepperIcon_2.png")}
                                                />

                                                <div
                                                    style={{
                                                        display: "table-cell",
                                                    }}
                                                >
                                                    <Styled.SectionTwoText>
                                                        Deposita tus fondos
                                                    </Styled.SectionTwoText>
                                                    <Styled.SectionTwoAdditionalText>
                                                        En efectivo o
                                                        transferencia
                                                    </Styled.SectionTwoAdditionalText>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: 80,
                                                        display: "table-cell",
                                                        paddingRight: 25,
                                                    }}
                                                    alt="criptomoneda users"
                                                    src={require("../../media/icons/StepperIcon_3.png")}
                                                />

                                                <div
                                                    style={{
                                                        display: "table-cell",
                                                    }}
                                                >
                                                    <Styled.SectionTwoText>
                                                        ¡Obtén tu primer Bitcoin
                                                        !
                                                    </Styled.SectionTwoText>
                                                    <Styled.SectionTwoAdditionalText>
                                                        Invierte desde $15
                                                    </Styled.SectionTwoAdditionalText>
                                                </div>
                                            </div>
                                            <Styled.ButtonNewContainer>
                                                <Styled.ContainerButtons
                                                    style={{
                                                        justifyContent: "left",
                                                    }}
                                                >
                                                    <Styled.StyledButtonBlueExt
                                                        href={`${agentebtcapi.webapp}/auth/register`}
                                                        style={{
                                                            background: `linear-gradient(#FFC500,#F7931E)`,
                                                            paddingInline: 10,
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: 30,
                                                                paddingRight: 5,
                                                            }}
                                                            src={require("../../media/icons/button_btc_icon.png")}
                                                        />
                                                        Comprar BTC
                                                        <img
                                                            style={{
                                                                width: 30,
                                                                paddingLeft: 20,
                                                            }}
                                                            src={require("../../media/icons/arrowIcon.png")}
                                                        />
                                                    </Styled.StyledButtonBlueExt>
                                                </Styled.ContainerButtons>
                                            </Styled.ButtonNewContainer>
                                        </div>
                                    </Styled.SectionTwo>
                                </Styled.SectionTwoBox>
                            </div>
                        </Wrapper>
                    </Section>
                    <Section>
                        <Wrapper
                            style={{
                                paddingBottom: 30,
                                backgroundColor: "#1A4BEA",
                                display: "grid",
                                justifyContent: "center",
                            }}
                        >
                            <Styled.WrapperSection>
                                <Styled.ComprarTextHeader>
                                    ¿Puedo comprar menos de 1 BTC?
                                </Styled.ComprarTextHeader>
                                <Styled.ComprarText>
                                    <b>Claro que SI</b>, puedes comprar
                                    fracciones de Bitcoin (conocidas como
                                    “Satoshis”) desde 15 dólares o su
                                    equivalente en soles.
                                </Styled.ComprarText>
                            </Styled.WrapperSection>
                        </Wrapper>
                    </Section>
                    <Section>
                        <Wrapper
                            style={{
                                paddingInline: 0,

                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    paddingBottom: 25,
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Styled.TaglineHome
                                        style={{
                                            textAlign: "center",
                                            paddingBottom: 30,
                                            width: "70%",
                                            display: "grid",
                                        }}
                                    >
                                        <PrimaryTextLanding>
                                            ¿Como vender Bitcoin?
                                        </PrimaryTextLanding>
                                        <p
                                            style={{
                                                fontSize: 18,
                                                color: "#3E5158cc",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Puedes convertir tus Bitcoin a soles
                                            o dólares en nuestra plataforma con
                                            unos cuantos clics:
                                        </p>
                                    </Styled.TaglineHome>
                                </div>
                                <Styled.VenderSection>
                                    <Styled.VenderCards
                                        style={{ width: "100%" }}
                                    >
                                        <Styled.VenderCard
                                            style={{ flexDirection: "column" }}
                                        >
                                            <img
                                                style={{
                                                    width: 80,
                                                }}
                                                alt="criptomoneda users"
                                                src={require("../../media/icons/vender_icon_1.png")}
                                            />

                                            <Paragraph
                                                style={{
                                                    color: "#3E5158cc",
                                                    marginBlockStart: 10,
                                                    marginBlockEnd: 0,
                                                    paddingBottom: 10,
                                                    textAlign: "center",
                                                }}
                                            >
                                                Selecciona “Retirar” en tu panel
                                                de usuario.
                                            </Paragraph>
                                        </Styled.VenderCard>
                                        <Styled.VenderCard
                                            style={{
                                                flexDirection: "column",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: 80,
                                                }}
                                                alt="criptomoneda users"
                                                src={require("../../media/icons/vender_icon_2.png")}
                                            />

                                            <Paragraph
                                                style={{
                                                    color: "#3E5158cc",
                                                    marginBlockStart: 10,
                                                    marginBlockEnd: 0,
                                                    paddingBottom: 10,
                                                    textAlign: "center",
                                                }}
                                            >
                                                Bríndanos una cuenta para
                                                transferir tu dinero.
                                            </Paragraph>
                                        </Styled.VenderCard>
                                        <Styled.VenderCard
                                            style={{ flexDirection: "column" }}
                                        >
                                            <img
                                                style={{
                                                    width: 80,
                                                }}
                                                alt="criptomoneda users"
                                                src={require("../../media/icons/vender_icon_3.png")}
                                            />
                                            <Paragraph
                                                style={{
                                                    color: "#3E5158cc",
                                                    marginBlockStart: 10,
                                                    marginBlockEnd: 0,
                                                    paddingBottom: 10,
                                                    textAlign: "center",
                                                }}
                                            >
                                                ¡Recibe tu dinero en minutos!
                                            </Paragraph>
                                        </Styled.VenderCard>
                                    </Styled.VenderCards>
                                </Styled.VenderSection>
                                <Styled.ContainerButtons>
                                    <Styled.StyledButtonBlueExt
                                        href={`${agentebtcapi.webapp}/auth/register`}
                                        style={{
                                            background: `linear-gradient(#FFC500,#F7931E)`,
                                            paddingInline: 10,
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: 30,
                                                paddingRight: 5,
                                            }}
                                            src={require("../../media/icons/button_btc_icon.png")}
                                        />
                                        Vender BTC
                                        <img
                                            style={{
                                                width: 30,
                                                paddingLeft: 20,
                                            }}
                                            src={require("../../media/icons/arrowIcon.png")}
                                        />
                                    </Styled.StyledButtonBlueExt>
                                </Styled.ContainerButtons>
                            </div>
                        </Wrapper>
                    </Section>
                    <Section>
                        <Wrapper
                            style={{
                                paddingInline: 0,
                                paddingTop: 40,
                                paddingBottom: 40,
                                backgroundColor: "#F4FAFF",
                                width: "100%",
                            }}
                        >
                            <Styled.PaddingWrapper>
                                <Styled.TaglineHome
                                    style={{
                                        textAlign: "left",
                                        paddingBottom: 30,
                                    }}
                                >
                                    <PrimaryTextLanding
                                        style={{ color: "#215DF1" }}
                                    >
                                        ¿Quieres aprender más?
                                    </PrimaryTextLanding>
                                    <p
                                        style={{
                                            fontSize: 20,
                                            color: "#3E5158cc",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Sabemos que algunos conceptos son un
                                        poco difíciles de entender, por ello, te
                                        brindamos los siguientes espacios de
                                        aprendizaje sobre criptomonedas.
                                    </p>
                                </Styled.TaglineHome>
                            </Styled.PaddingWrapper>
                            <Styled.PressSection style={{ width: "100%" }}>
                                <Styled.CardsContainer>
                                    <Styled.SocialMedia>
                                        <Styled.CardFlexContainer>
                                            >
                                            <div
                                                style={{
                                                    display: "block",
                                                    width: "100%",
                                                }}
                                            >
                                                <Paragraph
                                                    style={{
                                                        fontWeight: "bold",
                                                        paddingLeft: 20,
                                                        marginBlockStart: 10,
                                                        marginBlockEnd: 0,
                                                        fontSize: 22,
                                                    }}
                                                >
                                                    Blog
                                                </Paragraph>
                                                <Paragraph
                                                    style={{
                                                        color: "#3E5158cc",
                                                        paddingLeft: 20,
                                                        marginBlockStart: 5,
                                                        marginBlockEnd: 0,
                                                        paddingBottom: 15,
                                                    }}
                                                >
                                                    Contenido educativo y de
                                                    tendencias.
                                                </Paragraph>
                                                <div
                                                    style={{
                                                        marginInline: 10,
                                                        height: 1,
                                                        opacity: 0.1,
                                                        backgroundColor:
                                                            "#3E5158",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        width: "100%",
                                                        padding: 10,
                                                    }}
                                                >
                                                    <Styled.SectionsButton
                                                        href="https://blog.agentebtc.com/"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        Más información >
                                                    </Styled.SectionsButton>
                                                </div>
                                            </div>
                                            <Styled.ImageBanks
                                                src={imgBlog}
                                                alt="criptomonedas que son, criptomonedas blog"
                                            />
                                        </Styled.CardFlexContainer>
                                    </Styled.SocialMedia>
                                </Styled.CardsContainer>
                                <Styled.CardsContainer>
                                    <Styled.SocialMedia>
                                        <Styled.CardFlexContainer>
                                            <div
                                                style={{
                                                    display: "block",
                                                    width: "100%",
                                                }}
                                            >
                                                <Paragraph
                                                    style={{
                                                        fontWeight: "bold",
                                                        paddingLeft: 20,
                                                        marginBlockStart: 10,
                                                        marginBlockEnd: 0,
                                                        fontSize: 22,
                                                    }}
                                                >
                                                    Youtube
                                                </Paragraph>
                                                <Paragraph
                                                    style={{
                                                        color: "#3E5158cc",

                                                        paddingLeft: 20,
                                                        marginBlockStart: 5,
                                                        marginBlockEnd: 0,
                                                        paddingBottom: 15,
                                                    }}
                                                >
                                                    Tutoriales / Entrevistas /
                                                    Webinars.
                                                </Paragraph>
                                                <div
                                                    style={{
                                                        marginInline: 10,
                                                        height: 1,
                                                        opacity: 0.1,
                                                        backgroundColor:
                                                            "#3E5158",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        width: "100%",
                                                        padding: 10,
                                                    }}
                                                >
                                                    <Styled.SectionsButton
                                                        href="https://www.youtube.com/c/AgenteBTC"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        Más información >
                                                    </Styled.SectionsButton>
                                                </div>
                                            </div>
                                            <div>
                                                <Styled.ImageBanks
                                                    src={imgYT}
                                                    alt="criptomonedas que son, criptomonedas youtube"
                                                />
                                            </div>
                                        </Styled.CardFlexContainer>
                                    </Styled.SocialMedia>
                                </Styled.CardsContainer>
                            </Styled.PressSection>
                        </Wrapper>
                    </Section>
                    <Section>
                        <Wrapper
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Styled.PhoneSection>
                                <Styled.PhoneImg
                                    alt="invertir y ahorrar en peru"
                                    src={require("../../media/home/banner_img_1.png")}
                                />

                                <Styled.BannerContainer>
                                    <Styled.PhoneContentText>
                                        Compra tu primer Bitcoin fácilmente
                                    </Styled.PhoneContentText>
                                    <Styled.PhoneHeaderText>
                                        empezando desde $15
                                    </Styled.PhoneHeaderText>
                                    <Styled.ContainerButtons
                                        style={{
                                            justifyContent: "left",
                                            zIndex: 2,
                                        }}
                                    >
                                        <Styled.StyledButtonBlueExt
                                            href={`${agentebtcapi.webapp}/auth/register`}
                                            style={{
                                                background: `linear-gradient(#FFC500,#F7931E)`,
                                            }}
                                        >
                                            Comenzar AHORA
                                            <img
                                                style={{
                                                    width: 30,
                                                    paddingLeft: 20,
                                                }}
                                                src={require("../../media/icons/arrowIcon.png")}
                                            />
                                        </Styled.StyledButtonBlueExt>
                                    </Styled.ContainerButtons>
                                </Styled.BannerContainer>
                            </Styled.PhoneSection>
                        </Wrapper>
                    </Section>
                </DateProvider>
            </ExchangeProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Conoce Bitcoin - Aprende todo sobre la primera criptomoneda
                </title>
                <meta
                    name="description"
                    content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://agentebtc.com/" />
                <meta
                    property="og:title"
                    content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
                />
                <meta
                    property="og:description"
                    content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
                />
                <meta
                    property="og:image"
                    content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
                />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://agentebtc.com/" />
                <meta
                    property="twitter:title"
                    content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
                />
                <meta
                    property="twitter:description"
                    content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
                />
                <meta
                    property="twitter:image"
                    content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
                />
            </Helmet>
        </>
    );
};

export default withRouter(MeetBTC);
