import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";
import agentebtcapi from "agentebtcapi.js";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";
// Get the Chart component
import heroImg from "media/heros/responsabilty.svg";
import imgCircleGray from "media/circle_gray.png";

// Media images

import waveBlueUp from "media/waves/wave_blue_up.svg";

// Photos
import CampRecidar from "images/campaign_recidar.png";
import CampWeBlockie from "images/campaign_weblockie.png";

import { Helmet } from "react-helmet";

const Responsability = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Responsability",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.HeroSection>
                    <Styled.HeroText>
                        <Styled.TaglineAbout>
                            <PrimaryText>Responsabilidad Social</PrimaryText>
                        </Styled.TaglineAbout>
                        <Styled.SubtaglineHome>
                            <PrimaryText>
                                Como parte de nuestra visíon es poder unir a mas
                                personas y nosotros damos nuestro grano de arena{" "}
                            </PrimaryText>
                        </Styled.SubtaglineHome>
                    </Styled.HeroText>
                    <Styled.HeroImgWrapper>
                        <Styled.HeroImg src={heroImg} alt="Network" />
                    </Styled.HeroImgWrapper>
                </Styled.HeroSection>
            </Styled.AboutContainer>

            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>Lo que buscamos</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Con nuestra tecnología tratamos en llegar y apoyar a
                            mas empresas a partir de lo que impartimos y es que
                            la tecnología esta en la palma de nuestras manos,
                            así que podemos ahora colaborar con iniciativas de
                            diferentes empresas y Colaboramos con talento humano
                            para poder crecer comunidades de como la “Blockie”,
                            que nos encanta ser parte de un equipo que se
                            desarrolla y imparte ayuda a más mujeres en el Perú.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>

            <Styled.TeamContainer>
                <Styled.ContainerTextTeam>
                    <Styled.TaglineAbout>
                        <PrimaryText>Comunidades y ONGs</PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.DescriptionTeam>
                        <PrimaryText>
                            Estas son algunas de las iniciativas y proyectos que
                            AgenteBTC viene realizando con algunas comunidades y
                            ONGs del ecosistema local.
                        </PrimaryText>
                    </Styled.DescriptionTeam>
                </Styled.ContainerTextTeam>
            </Styled.TeamContainer>

            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>Recidar: "DONA y GANA"</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Recidar.pe es una organización que ofrece un
                            servicio de recojo gratuito de bienes usados a
                            familias y empresas, como ropa o electrodomésticos.
                            Los bienes son puestos en valor, para así brindar
                            una experiencia de compra digna y legal a familias
                            con pocos recursos.
                        </PrimaryText>
                    </Styled.SubtaglineHome>

                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Motivamos a que las personas se sumen a donar y que
                            la restricción de inamovilidad, de la cuarentena
                            establecida en el Perú por el COVID-19, no sea un
                            impedimento. Esto consiste en que cualquier persona
                            separe lo que va a donar a Recidar, le toma una
                            fotografía y mande la fotografía a Recidar. Esta
                            donación la entregarán al terminar la cuarentena.
                            Por este simple pero significante acto recibes un
                            premio! El premio que recibes por tu donación lo
                            brinda AgenteBTC y es un cupón de Bitcoins.
                        </PrimaryText>
                    </Styled.SubtaglineHome>

                    <Styled.ImgWrapper src={CampRecidar} />
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>

            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>
                            WeBlockie - Mujeres de Blockchain
                        </PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Blockie es una comunidad de mujeres en Blockchain en
                            Perú, que fue creada por Carolina Herrera - CEO de
                            AgenteBTC, para compartir temas relacionados a
                            Blockchain con otras mujeres y de esa forma reducir
                            la brecha de conocimientos.
                        </PrimaryText>
                    </Styled.SubtaglineHome>

                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            AgenteBTC ha hecho una alianza con Blockie para
                            compartir sus publicaciones y eventos con el fin de
                            llegar a mayor público e incrementar Blockie.
                        </PrimaryText>
                    </Styled.SubtaglineHome>

                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Para inscripciones para Blockie envianos tu correo
                            electrónico con tu información a info@weblockie.org
                        </PrimaryText>
                    </Styled.SubtaglineHome>

                    <Styled.ImgWrapper src={CampWeBlockie} />
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>

            <Styled.TeamContainer>
                <Styled.ContainerSectionHeader>
                    <TitleSection>
                        Trabajamos por un mejor mundo usando la tecnología como
                        impulsor :){" "}
                    </TitleSection>
                </Styled.ContainerSectionHeader>
            </Styled.TeamContainer>

            <Styled.TeamContainer>
                <Styled.ContainerTextTeam>
                    <Styled.TaglineAbout>
                        <PrimaryText>
                            ¿Estás interesado en apoyar nuestra causa? Únete
                        </PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.DescriptionTeam>
                        <PrimaryText>
                            Si tienes una iniciativa en donde AgenteBTC puede
                            apoyar, escríbenos a soporte@agentebtc.com
                        </PrimaryText>
                    </Styled.DescriptionTeam>
                </Styled.ContainerTextTeam>
            </Styled.TeamContainer>

            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>
                            Únete con nosotros a la revolución financiera{" "}
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Apoya nuestra misión, comienza aquí
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSecExt
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comenzar ahora :)
                            </Styled.StyledButtonYellowSecExt>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Responsabilidad y ONGs | Agente BTC</title>
                <meta
                    name="description"
                    content="Responsabilidad de Agente BTC el primer exchange de bitcoin ayuda a ONGs"
                />
            </Helmet>
        </>
    );
};

export default withRouter(Responsability);
