import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import AccordionClient from "components/AccordionClient";
import AccordionPartner from "components/AccordionPartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";

// Media images

import waveBlueUp from "media/waves/wave_blue_up.svg";

import { Helmet } from "react-helmet";

import agentebtcapi from "agentebtcapi.js";

const Help = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Help",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    const handleBtnClient = () => {
        const btnCliente = document.querySelector(".btnClient");
        const btnPartner = document.querySelector(".btnSocio");
        const divCliente = document.querySelector(".FaqDivClient");
        const divSocio = document.querySelector(".FaqDivSocio");
        btnPartner.classList.remove("activeBtn2");
        btnCliente.classList.add("activeBtn2");
        divCliente.classList.add("activeDiv");
        divSocio.classList.remove("activeDiv");
    };

    const handleBtnSocio = () => {
        const btnCliente = document.querySelector(".btnClient");
        const btnPartner = document.querySelector(".btnSocio");
        const divCliente = document.querySelector(".FaqDivClient");
        const divSocio = document.querySelector(".FaqDivSocio");
        btnCliente.classList.remove("activeBtn2");
        btnPartner.classList.add("activeBtn2");
        divSocio.classList.add("activeDiv");
        divCliente.classList.remove("activeDiv");
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.ContainerSectionHeader>
                    <Styled.TaglineAbout>
                        <PrimaryText>Ayuda</PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            ¡Hola! Estamos aquí para ayudarte. Preguntas
                            frecuentes y asistencia.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerSectionHeader>
            </Styled.AboutContainer>

            <Styled.ContentBoxedContainer>
                <div style={{ justifyContent: "center", textAlign: "center" }}>
                    <button
                        type="button"
                        onClick={handleBtnClient}
                        className="btnClient btnFaq activeBtn2"
                    >
                        Usuario
                    </button>
                    <button
                        type="button"
                        onClick={handleBtnSocio}
                        className="btnSocio  btnFaq"
                    >
                        Socios
                    </button>

                    {/*<button
          type="button"
          onClick={handleBtnSocio}
          className="btnSocio  btnFaq"
        >
          Glosario Bitcoin
        </button>*/}
                </div>
                <Space />
                <div className="FaqDivClient activeDiv">
                    {" "}
                    <AccordionClient />
                </div>
                <div className="FaqDivSocio">
                    <AccordionPartner />
                </div>
                <Space />
            </Styled.ContentBoxedContainer>

            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>
                            ¿Resolviste tus dudas? Comienza la experiencia
                            cripto
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Todo un universo por explorar
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSec
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comienza ahora :)
                            </Styled.StyledButtonYellowSec>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title> Centro de Ayuda | AgenteBTC</title>
                <meta
                    name="description"
                    content="Centro de Asistencia y Preguntas Frecuentes (FAQ). También estamos aquí para resolver tus dudas"
                />
            </Helmet>
        </>
    );
};

export default withRouter(Help);
