import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";
// Get the Chart component
import heroImg from "media/heros/wallet.png";

import imgRetener from "media/icons/retener_icon.png";
import imgCompras from "media/icons/compras_icon.png";
import imgTransferencia from "media/icons/transferencia_icon.png";

import iconStep1 from "media/icons/paso1_otc.png";
import iconStep2 from "media/icons/paso2_otc.png";
import iconStep3 from "media/icons/paso3_otc.png";
import iconStep4 from "media/icons/paso4_otc.png";
import iconStep5 from "media/icons/paso5_otc.png";

import { Helmet } from "react-helmet";

import agentebtcapi from "agentebtcapi.js";

// Media images

import waveBlueUp from "media/waves/wave_blue_up.svg";

const Wallet = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Wallet",
        },
    });

    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.HeroSection>
                    <Styled.HeroText>
                        <Styled.TaglineAbout>
                            <PrimaryText>Wallet AgenteBTC</PrimaryText>
                        </Styled.TaglineAbout>
                        <Styled.SubtaglineHome>
                            <PrimaryText>
                                Tu propia billetera de Bitcoin, gratis y fácil
                                de usar
                            </PrimaryText>
                        </Styled.SubtaglineHome>
                    </Styled.HeroText>
                    <Styled.HeroImgWrapper>
                        <Styled.HeroImg src={heroImg} alt="Network" />
                    </Styled.HeroImgWrapper>
                </Styled.HeroSection>
            </Styled.AboutContainer>

            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>Además</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Con tu billetera (wallet) podrás aprender de manera
                            fácil como aprovechar todo el poder de Bitcoin y las
                            criptomonedas. En AgenteBTC la ofrecemos{" "}
                            <b>gratuitamente</b> a cada usuario validado.{" "}
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>

            <Styled.TeamContainer>
                <Styled.ContainerTextTeam>
                    <Styled.TaglineAbout>
                        <PrimaryText>
                            Envía y recibe Bitcoin de cualquier parte del mundo{" "}
                        </PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.DescriptionTeam>
                        <PrimaryText>
                            {" "}
                            Con tu billetera envia y recibe básicamente dinero
                            de cualquier parte del mundo (o fuera de este). Y
                            luego puedes convertirlo a Soles o Dólares cuando lo
                            desees.
                        </PrimaryText>
                        <br></br>
                        <br></br>
                        <PrimaryText>
                            {" "}
                            Tu usuario AgenteBTC te otorga una Wallet Bitcoin
                            con dirección propia :){" "}
                        </PrimaryText>
                    </Styled.DescriptionTeam>
                </Styled.ContainerTextTeam>
            </Styled.TeamContainer>

            <Styled.MissionContainer>
                <Styled.ContainerMissionLeft>
                    <Styled.TaglineAbout>
                        <PrimaryText>¿Qué es 'Wallet'?</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerMissionLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Una wallet o billetera de Bitcoin es el punto de
                            partida para comenzar a operar con Bitcoin. Con ella
                            podrás enviar y recibir bitcoins desde cualquier
                            lado del mundo, las 24 horas del día.
                        </PrimaryText>
                    </Styled.SubtaglineHome>

                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Con tu billetera tu puedes disponer de tus fondos en
                            cualquier momento. Una billetera vendría a ser como
                            tener una cuenta bancaria en el banco, solo que la
                            diferencia es que una billetera de Bitcoin está
                            disponible sin barreras y las transacciones son en
                            minutos.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.MissionContainer>

            <Styled.TeamContainer id="uses-cases-bitcoin">
                <Styled.ContainerTextTeam>
                    <Styled.TaglineAbout>
                        <PrimaryText>
                            ¿Cómo obtener mi propia Wallet AgenteBTC?
                        </PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerTextTeam>
            </Styled.TeamContainer>

            <Styled.StepContainer>
                <Styled.ContainerStepLeft>
                    <Styled.TaglineAbout>
                        <Styled.ImageStep src={iconStep1} />
                        <br></br>
                        <PrimaryText>Paso 1</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerStepLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Regístrate en AgenteBTC, con tan solo tu correo y
                            algunos datos.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.StepContainer>

            <Styled.StepContainer>
                <Styled.ContainerStepLeft>
                    <Styled.TaglineAbout>
                        <Styled.ImageStep src={iconStep2} />
                        <br></br>
                        <PrimaryText>Paso 2</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerStepLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Valida tu cuenta. Este paso es necesario para
                            verificar tu identidad sin problemas.{" "}
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.StepContainer>

            <Styled.StepContainer>
                <Styled.ContainerStepLeft>
                    <Styled.TaglineAbout>
                        <Styled.ImageStep src={iconStep3} />
                        <br></br>
                        <PrimaryText>Paso 3</PrimaryText>
                    </Styled.TaglineAbout>
                </Styled.ContainerStepLeft>
                <Styled.ContainerMissionRight>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            ¡Listo! Tu billetera ya está lista para usar, dentro
                            de AgenteBTC podrás ver las funcionalidades. Si
                            quieres saber más sobre como enviar y recibir
                            bitcoins con tu billetera AgenteBTC, entra aquí.{" "}
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerMissionRight>
            </Styled.StepContainer>

            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>
                            Compra tu primer Bitcoin de manera fácil{" "}
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Comienza con tan solo $ 15 doláres
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSecExt
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comenzar ahora :)
                            </Styled.StyledButtonYellowSecExt>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title> Wallet | AgenteBTC</title>
                <meta
                    name="description"
                    content="Ten tu propia billetera Bitcoin en Perú, gratis. Compra y vende Bitcoins al instante con AgenteBTC."
                />
            </Helmet>
        </>
    );
};

export default withRouter(Wallet);
