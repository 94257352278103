import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Carusel.css";

const cryptoArrayIcons = [
    { img: require("../../media/media_icons/Carusele_13.png"), name: "rpp" },
    { img: require("../../media/media_icons/Carusele_1.png"), name: "platzi" },
    {
        img: require("../../media/media_icons/Carusele_2.png"),
        name: "camara de comercio de lima",
    },
    {
        img: require("../../media/media_icons/Carusele_3.png"),
        name: "utec ventures",
    },
    {
        img: require("../../media/media_icons/Carusele_4.png"),
        name: "yahoo finance",
    },
    {
        img: require("../../media/media_icons/Carusele_5.png"),
        name: "aves Lair",
    },
    {
        img: require("../../media/media_icons/Carusele_6.png"),
        name: "criptonoticias",
    },
    { img: require("../../media/media_icons/Carusele_7.png"), name: "peru21" },
    { img: require("../../media/media_icons/Carusele_8.png"), name: "gestion" },
    {
        img: require("../../media/media_icons/Carusele_9.png"),
        name: "infomercado",
    },
    {
        img: require("../../media/media_icons/Carusele_10.png"),
        name: "contxto",
    },
    {
        img: require("../../media/media_icons/Carusele_11.png"),
        name: "cision pr newswire",
    },
    {
        img: require("../../media/media_icons/Carusele_12.png"),
        name: "ruta Startup",
    },
    {
        img: require("../../media/media_icons/Carusele_14.png"),
        name: "beincrypto",
    },
];

const createCarouselItemImage = (img, index) => (
    <img key={index} src={img.img} alt={`${img.name} criptomonedas`} />
);

const baseChildren = <>{cryptoArrayIcons.map(createCarouselItemImage)}</>;

export const MediaCarusel = () => (
    <div className="carousel-outer">
        <Carousel
            centerSlidePercentage={8}
            infiniteLoop
            centerMode
            showArrows={false}
            dynamicHeight
            autoPlay
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
        >
            {baseChildren.props.children}
        </Carousel>
    </div>
);
