import styled from "styled-components";
import colors from "styles/common/colors";

const HeaderText = styled.a`
    color: #1a4bea;
    font-size: 50px;
    font-weight: 600;
    font-family: "Poppins";
    z-index: 1;

    @media (max-width: 640px) {
        font-size: 26px;
    }
`;

const HeaderTextSecond = styled.a`
    color: #3e5158;
    font-size: 50px;
    font-weight: 600;
    font-family: "Poppins";
    z-index: 1;

    @media (max-width: 640px) {
        font-size: 26px;
    }
`;

const HeaderTextThird = styled.a`
    font-size: 22px;
    color: #3e5158cc;
    @media (max-width: 640px) {
        font-size: 16px;
    }
`;

const Section = styled.section`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media (max-width: 640px) {
        padding: 10px 0;
        padding-bottom: 32px;
    }
`;

const HomeContainer = styled.div`
    width: 100%;
    min-height: 30vh;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 640px) {
        margin-top: -30px;
    }
`;

const FirstTextSection = styled.div`
    padding-block-start: 5px;
    padding-block-end: 20px;
    padding-left: 60px;
    width: 100%;
    text-align: left;
    justify-items: left;
    z-index: -1;
    background-color: #f4faff;
    @media screen and (max-width: 640px) {
        text-align: center;
        padding-left: 40px;
    }
`;

const WrapperContactSection = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    @media (max-width: 640px) {
        align-items: center;
        flex-direction: column;
        padding: 0 24px;
    }
`;

const ContactSection = styled.div`
    width: 70%;
    box-sizing: border-box;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 640px) {
        flex-direction: column;
        padding: 0 24px;
    }
`;

const ContainerContact = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    transition: 0.5s;
    &:hover {
        transform: scale(0.9);
    }
`;

const ContactCard = styled.div`
    text-align: center;
    box-shadow: 4px 4px 4px 4px #eeeeee;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f4faffcc;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 70%;
        margin-block-end: 10px;
    }
    @media (max-width: 640px) {
        text-align: center;
        width: 65%;
    }
`;

const ContactCardTitle = styled.p`
    color: #3e5158cc;
    font-family: "Poppins", sans-serif;
    margin-block-end: 0px;
    padding-left: 10px;
    margin-block-start: 0px;
    font-size: 20px;
    font-weight: 500;
    align-self: center;
    @media (max-width: 640px) {
        font-size: 13px;
    }
`;

const ContactCardImage = styled.img`
    width: 40px;
    height: 40px;
    @media (max-width: 640px) {
        width: 30px;
        height: 30px;
    }
`;

const SectionForm = styled.section`
    margin-top: 100px;
    display: flex;
    margin-left: 5%;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;

    @media (max-width: 640px) {
        margin-left: 0%;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const SectionFromTwo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 1 100%;
    padding: 0 12px;

    @media (max-width: 640px) {
        margin-top: 30px;
        order: 2;
        flex: 0 1 100%;
        align-items: center;
        text-align: center;
    }
`;

export const SectionFromTwoImageContainer = styled.div`
    max-width: 100%;
    padding-top: 40px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        padding-top: 20px;
        order: 1;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media screen and (max-width: 640px) {
        order: 1;
        padding: 0px;
        padding-top: 20px;
    }
`;

const SectionFormTwoImg = styled.img`
    width: 70%;
    @media (max-width: 640px) {
        width: 90%;
    }
`;

const FormulageContainer = styled.div`
    box-shadow: 4px 4px 4px 4px #eeeeee;
    border-radius: 25px;
    padding-bottom: 30px;
    width: 75%;
    @media (max-width: 640px) {
        width: 90%;
    }
`;

const FormInput = styled.input`
    width: 80%;
    height: 40px;
    border-radius: 20px;
    border-color: #3e515880;
    margin-top: 5px;
    margin-bottom: 15px;
    border-width: 1px;
`;

const FormInputLong = styled.input`
    width: 80%;
    height: 160px;
    border-radius: 20px;
    border-color: #3e515880;
    margin-top: 5px;
    margin-bottom: 15px;
    border-width: 1px;
`;

const FormInputText = styled.a`
    color: #3e5158;
    @media (max-width: 640px) {
    }
`;

const FromWrapper = styled.div`
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    display: inline-grid;
    text-align: left;
    @media (max-width: 640px) {
        padding-left: 20px;
    }
`;

const StyledButtonBlueExt = styled.a`
  color: white;
  background: #1A4BEA;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-weight: 600;
  padding: 10px 35px;
  @media (max-width: 640px) {

    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px
    width: 120px;
    height: 35px;
  }
`;

const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 100%;
    margin-top: 20px;

    @media (max-width: 640px) {
        margin-top: 10px;
        flex-direction: column;
    }
`;

export default {
    HeaderTextThird,
    HeaderTextSecond,
    HeaderText,
    ContactCardImage,
    FromWrapper,
    ContainerButtons,
    StyledButtonBlueExt,
    FormInputLong,
    FormInputText,
    FormInput,
    FormulageContainer,
    SectionFormTwoImg,
    SectionFromTwoImageContainer,
    SectionFromTwo,
    SectionForm,
    ContactCardTitle,
    ContactCard,
    ContainerContact,
    ContactSection,
    WrapperContactSection,
    FirstTextSection,
    Section,
    HomeContainer,
};
