import styled from "styled-components";
import colors from "styles/common/colors";
import img from "../../media/home/background_nosotros.png";

export const HomeContainer = styled.div`
    width: 100%;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 640px) {
        margin-top: -30px;
    }
`;

const SingleCard = styled.div`
    box-shadow: 4px 4px 4px 4px #eeeeee;
    width: 48%;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 55%;
        margin-block-end: 10px;
    }
    @media (max-width: 640px) {
        width: 65%;
    }
`;

const CardContent = styled.p`
    color: #3e5158cc;
    font-size: 22px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    @media (max-width: 640px) {
        font-size: 11px;
    }
`;

const SectionTwoBox = styled.section`
    display: flex;
    margin-left: 10%;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;

    @media (max-width: 640px) {
        margin-left: 0%;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const SectionTwoImageContainer = styled.div`
    max-width: 100%;
    padding-top: 40px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        padding-top: 20px;
        order: 1;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media screen and (max-width: 640px) {
        order: 1;
        padding: 0px;
        padding-top: 20px;
    }
`;

const SectionTwoImg = styled.img`
    width: 70%;
    @media (max-width: 640px) {
        width: 100%;
    }
`;

const SectionTwo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 1 100%;
    padding: 0 12px;

    @media (max-width: 640px) {
        order: 2;
        flex: 0 1 100%;
        align-items: center;
        text-align: center;
    }
`;

const TaglineHome = styled.h3`
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-weight: normal;
    margin: 0;
    font-weight: 700;
    @media screen and (max-width: 640px) {
        margin-top: 10px;
        font-size: 25px;
        font-weight: 600;
    }

    @media screen and (max-width: 320px) {
        margin-top: 10px;
        font-size: 18px;
    }
`;

const SectionTwoText = styled.p`
    color: #3e5158cc;
    margin-block-end: 0px;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 640px) {
        font-size: 17px;
    }
`;

const PrincipiosSection = styled.div`
    width: 70%;
    box-sizing: border-box;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 640px) {
        flex-direction: column;
        padding: 0 24px;
    }
`;

const ContainerPrincipios = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    transition: 0.5s;
    &:hover {
        transform: scale(0.9);
    }
`;

const WrapperPrincipiosSection = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    @media (max-width: 640px) {
        align-items: center;
        flex-direction: column;
        padding: 0 24px;
    }
`;

const PrincipiosCard = styled.div`
    text-align: left;
    box-shadow: 4px 4px 4px 4px #eeeeee;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    height: 250px;
    background-color: white;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 70%;
        margin-block-end: 10px;
    }
    @media (max-width: 640px) {
        text-align: center;
        width: 65%;
    }
`;

const PrincipiosCardTitle = styled.p`
    color: #3e5158cc;
    font-family: "Poppins", sans-serif;
    margin-block-end: 0px;
    font-weight: bold;
    @media (max-width: 640px) {
        font-size: 16px;
    }
`;

const PrincipiosCardContent = styled.p`
    color: #3e5158cc;
    font-size: 16px;
    font-weight: 300;
    margin-block-start: 0px;
    font-family: "Poppins", sans-serif;
    @media (max-width: 1200px) {
        font-size: 13px;
    }
    @media (max-width: 640px) {
        font-size: 13px;
    }
`;

const SectionHeader = styled.h3`
    color: #3e5158;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-weight: normal;
    margin: 0;
    font-weight: 600;
    @media screen and (max-width: 640px) {
        margin-top: 10px;
        font-size: 25px;
        font-weight: 500;
    }

    @media screen and (max-width: 320px) {
        margin-top: 10px;
        font-size: 18px;
    }
`;

const TextSection = styled.p`
    color: #3e5158cc;
    font-size: 19px;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    @media (max-width: 640px) {
        font-size: 13px;
    }
`;

const TextSectionContainer = styled.div`
    margin-left: 20%;
    margin-right: 20%;
    font-family: "Poppins", sans-serif;
    @media (max-width: 640px) {
    }
`;

const DateSection = styled.div`
    box-sizing: border-box;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    @media (max-width: 640px) {
        flex-direction: column;
        padding-top: 0px;
    }
`;

const DateCard = styled.div`
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    height: 250px;
    background-color: #edf4f9;
    @media (max-width: 640px) {
        width: 65%;
    }
`;

const DateCardFirst = styled.div`
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    height: 250px;
    background-color: #edf4f9;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    @media (max-width: 640px) {
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0px;
        width: 65%;
    }
`;

const DateCardLast = styled.div`
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    height: 250px;
    background-color: #edf4f9;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    @media (max-width: 640px) {
        border-top-right-radius: 0px;
        border-bottom-left-radius: 25px;
        width: 65%;
    }
`;

const DateCardActual = styled.div`
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #edf4f9;
    height: 280px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: #1a4bea;

    @media (max-width: 640px) {
        height: 250px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 40px;
        width: 65%;
    }
`;

const ContainerDate = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    @media (max-width: 640px) {
        padding-top: 0px;
    }
`;

const FacesCard = styled.div`
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    height: 250px;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 55%;
        margin-block-end: 10px;
    }
    @media (max-width: 640px) {
        height: 200px;
        width: 65%;
    }
`;

const FaceCardContent = styled.p`
    color: #3e515880;
    font-size: 18px;
    font-weight: 600;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-family: "Poppins", sans-serif;
    @media (max-width: 640px) {
        font-size: 11px;
    }
`;

const FaceCardHeader = styled.p`
    color: #3e5158;
    font-size: 18px;
    font-weight: 600;
    margin-block-start: 0px;
    font-family: "Poppins", sans-serif;
    @media (max-width: 640px) {
        font-size: 11px;
    }
`;

const PhoneSection = styled.div`
    width: 100%;
    background-repeat: round;
    background-color: rgb(244, 250, 255);
    background-image: url(${img});
    height: 300px;
    z-index: -1;
    display: inline-flex;
    @media (max-width: 900px) {
        margin-bottom: 60px;
    }
    @media (max-width: 640px) {
        margin-bottom: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
        display: block;
        border-radius: 30px;
    }
`;

const PhoneWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;
    padding-inline-start: 10%;
    padding-inline-end: 10%;
    display: grid;
    justify-items: center;

    @media (max-width: 640px) {
        padding: 0 24px;
    }

    @media (max-width: 320px) {
        padding: 0 16px;
    }
`;

const PhoneImg = styled.img`
    height: 350px;
    margin-left: -70px;
    margin-top: -50px;
    @media (max-width: 1150px) {
        width: 65%;
    }
    @media (max-width: 640px) {
        height: 200px;
        margin-left: 0px;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 100%;
    margin-top: 20px;

    @media (max-width: 640px) {
        margin-top: 10px;
        flex-direction: column;
        white-space: nowrap;
    }
`;

const StyledButtonBlueExt = styled.a`
    color: #3e5158;
    background: ${colors.yellow_agente_btc};
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-weight: 600;
    padding: 10px 35px;
    @media (max-width: 900px) {
        font-size: 16px;
    }
    @media (max-width: 640px) {
        font-size: 15px;
        height: 35px;
    }
`;

const PhoneContentText = styled.p`
    color: white;
    font-size: 25px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-family: "Poppins", sans-serif;
    @media (max-width: 900px) {
        font-size: 20px;
    }
    @media (max-width: 640px) {
        margin-block-start: 10px;
        font-size: 14px;
    }
`;

const PhoneHeaderText = styled.p`
    color: white;
    font-size: 40px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    @media (max-width: 900px) {
        font-size: 30px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
`;

const TextCard = styled.div`
    margin-top: 130px;
    margin-bottom: 50px;
    text-align: center;
    box-shadow: 4px 4px 4px 4px #eeeeee;
    padding-left: 20px;
    padding-right: 20px;
    width: 70%;
    background-color: white;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 55%;
        margin-block-end: 10px;
    }
    @media (max-width: 640px) {
        width: 65%;
    }
`;

const TextCardTitle = styled.p`
    color: #3e5158;
    font-family: "Poppins", sans-serif;
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-size: 18px;
    font-weight: bold;
    @media (max-width: 640px) {
        font-size: 14px;
    }
`;

const TextCardContent = styled.p`
    color: #3e5158;
    font-size: 18px;
    font-weight: 300;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    @media (max-width: 640px) {
        font-size: 14px;
    }
`;

export default {
    TextCardContent,
    TextCardContent,
    TextCardTitle,
    TextCard,
    PhoneHeaderText,
    PhoneContentText,
    StyledButtonBlueExt,
    ContainerButtons,
    PhoneSection,
    PhoneImg,
    FaceCardHeader,
    FaceCardContent,
    FacesCard,
    DateCardFirst,
    DateCardLast,
    DateCardActual,
    ContainerDate,
    DateCard,
    DateSection,
    TextSectionContainer,
    TextSection,
    SectionHeader,
    PrincipiosCardContent,
    PrincipiosCardTitle,
    PrincipiosCard,
    WrapperPrincipiosSection,
    PhoneWrapper,
    ContainerPrincipios,
    PrincipiosSection,
    SectionTwoText,
    TaglineHome,
    SectionTwo,
    SectionTwoImg,
    SectionTwoImageContainer,
    SectionTwoBox,
    CardContent,
    HomeContainer,
    SingleCard,
};
