import styled from "styled-components";

const AppContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    position: absolute;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    top: 0px;
    left: 0px;
    overflow: hidden;
`;

export default {
    AppContainer,
};
