import styled from "styled-components";
import colors from "styles/common/colors";
import img from "../../media/home/background_nosotros.png";

const MainTitle = styled.a`
    font-size: 70px;
    font-weight: bold;
    @media (max-width: 640px) {
        font-size: 40px;
    }
`;

const WhiteContainer = styled.div`
    /* height: 50vh; */
    height: 0vh;
    width: 100%;
    @media (max-width: 640px) {
        /* height: 30vh; */
    }
`;

const HomeContainer = styled.div`
    width: 100%;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 640px) {
        margin-top: -30px;
    }
`;

const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 100%;
    margin-top: 20px;

    @media (max-width: 640px) {
        margin-top: 10px;
        flex-direction: column;
    }
`;

const StyledButtonBlueExt = styled.a`
  color: #3E5158;
  background: ${colors.yellow_agente_btc};
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-weight: 600;
  padding: 10px 35px;
  @media (max-width: 640px) {

    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px
    height: 35px;
  }
`;

const ChartCard = styled.div`
    box-shadow: 1px 1px 1px 1px #dddddd;
    width: 48%;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    height: 300px;
    z-index: 1;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 55%;
        margin-block-end: 10px;
    }
    @media (max-width: 640px) {
        width: 65%;
    }
`;

const SectionTwoBox = styled.section`
    display: flex;
    margin-left: 10%;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;

    @media (max-width: 640px) {
        margin-left: 0%;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const SectionTwoImageContainer = styled.div`
    max-width: 100%;
    padding-top: 40px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        padding-top: 20px;
        order: 1;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media screen and (max-width: 640px) {
        order: 1;
        padding: 0px;
        padding-top: 20px;
    }
`;

const SectionTwoImg = styled.img`
    width: 70%;
    @media (max-width: 640px) {
        width: 100%;
    }
`;

const SectionTwo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 1 100%;
    padding: 0 12px;

    @media (max-width: 640px) {
        order: 2;
        flex: 0 1 100%;
        align-items: center;
        text-align: center;
    }
`;

const TaglineHome = styled.h3`
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-weight: normal;
    margin: 0;
    font-weight: 700;
    margin-block-end: 20px;
    @media screen and (max-width: 640px) {
        margin-top: 10px;
        font-size: 25px;
        font-weight: 600;
    }

    @media screen and (max-width: 320px) {
        margin-top: 10px;
        font-size: 18px;
    }
`;

const TaglineHomeSec = styled.h3`
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-weight: normal;
    margin: 0;
    font-weight: 700;
    margin-block-end: 20px;
    text-align: center;
    padding-bottom: 30px;
    width: 50%;
    display: grid;
    @media screen and (max-width: 640px) {
        margin-top: 10px;
        font-size: 25px;
        font-weight: 600;
        width: 70%;
    }

    @media screen and (max-width: 320px) {
        width: 70%;
        margin-top: 10px;
        font-size: 18px;
    }
`;

const ButtonNewContainer = styled.div`
    display: table-cell;
    padding-top: 30px;
    @media screen and (max-width: 640px) {
        display: block;
    }
`;

const SectionTwoText = styled.p`
    color: #3e5158cc;
    margin-block-end: 0px;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 640px) {
        font-size: 17px;
    }
`;

const SectionTwoAdditionalText = styled.p`
    color: #3e515899;
    margin-block-start: 0px;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 640px) {
        font-size: 17px;
    }
`;

const SingleCard = styled.div`
    box-shadow: 1px 1px 1px 1px #dddddd;
    width: 80%;
    border-radius: 15px;
    padding-left: 60px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 25px;
    background-color: white;
    z-index: 1;
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 55%;
        margin-block-end: 10px;
    }
    @media (max-width: 640px) {
        padding-left: 20px;
        width: 65%;
    }
`;

const TitleSection = styled.a`
    color: #1b4be8;
    font-size: 32px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    @media (max-width: 640px) {
        font-size: 20px;
    }
`;

const TextSection = styled.p`
    color: #3e5158cc;
    font-size: 21px;
    @media (max-width: 640px) {
        font-size: 16px;
    }
`;

const TextSectionImg = styled.img`
    width: 60%;
    @media (max-width: 640px) {
        width: 100%;
    }
`;

const Devider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #3e51584d;
    margin-top: 5px;
    margin-bottom: 10px;
`;

const CardCenter = styled.div`
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardFlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 640px) {
        display: block;
    }
`;

const VenderSection = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: auto;
    box-sizing: border-box;
    padding: 0 32px;
    display: flex;
    padding-bottom: 50px;
    flex-direction: row;
    align-items: flex-start;
    justfify-content: center;
    @media (max-width: 900px) {
        flex-direction: column;
        padding: 0 24px;
    }
`;

const VenderCards = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 900px) {
        flex-direction: column;
        padding: 0 24px;
    }
`;

const VenderCard = styled.div`
    box-shadow: 4px 4px 4px 4px #eeeeee;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    border-radius: 20px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    transition: 0.5s;
    @media (max-width: 640px) {
        width: 80%;
        margin-block-end: 25px;
    }
`;

const PaddingWrapper = styled.div`
    padding-left: 100px;
    padding-right: 350px;
    @media (max-width: 640px) {
        padding-left: 20px;
        padding-right: 0px;
    }
`;

const SocialMedia = styled.div`
    text-align: left;
    background-color: white;
    height: 140px;
    width: 80%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 6px 9px 6px rgba(0, 0, 0, 0.1);
    @media (max-width: 640px) {
        height: auto;
        width: 100%;
    }
`;

const SectionsButton = styled.a`
    align-self: center;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    margin-right: 10px;
    padding: 10px;
    color: #1a4bea;
    font-weight: 400;
`;

const ImageBanks = styled.img`
    height: 120px;
    width: auto;
    padding: 10px;
    @media (max-width: 640px) {
        height: auto;
        width: 80%;
    }
`;

const CardsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-top: 30px;
    transition: 0.5s;
    margin-bottom: 0px;
    @media (max-width: 640px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

const PressSection = styled.div`
    padding-inline: 100px;
    width: 80%;
    box-sizing: border-box;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 900px) {
        flex-direction: column;
        padding: 0 24px;
    }
`;

const PhoneSection = styled.div`
    width: 80%;
    background-repeat: round;
    background-color: rgb(244, 250, 255);
    background-image: url(${img});
    height: 300px;
    z-index: -1;
    display: inline-flex;
    @media (max-width: 900px) {
        margin-bottom: 60px;
    }
    @media (max-width: 640px) {
        padding-block-end: 20px;
        padding-inline: 20px;
        margin-bottom: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
        display: block;
        border-radius: 30px;
    }
`;

const PhoneWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;
    padding-inline-start: 10%;
    padding-inline-end: 10%;
    display: grid;
    justify-items: center;

    @media (max-width: 640px) {
        padding: 0 24px;
    }

    @media (max-width: 320px) {
        padding: 0 16px;
    }
`;

const PhoneImg = styled.img`
    height: 260px;
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
    @media (max-width: 1150px) {
        width: 65%;
    }
    @media (max-width: 640px) {
        height: auto;
        width: 50%;
        margin-left: 0px;
    }
`;

const PhoneContentText = styled.p`
    color: white;
    font-size: 28px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-family: "Poppins", sans-serif;
    @media (max-width: 900px) {
        font-size: 20px;
    }
    @media (max-width: 640px) {
        margin-block-start: 10px;
        font-size: 14px;
    }
`;

const WrapperSection = styled.div`
    padding-inline: 120px;
    @media (max-width: 640px) {
        padding-inline: 30px;
    }
`;

const ComprarText = styled.p`
    margin-block-start: 10px;
    font-size: 22px;
    font-weight: 500;
    @media (max-width: 640px) {
        font-size: 18px;
    }
`;

const ComprarTextHeader = styled.p`
    margin-block-start: 0px;
    font-size: 35px;
    font-weight: bold;
    @media (max-width: 640px) {
        margin-block-start: 5px;
        font-size: 22px;
    }
`;

const PhoneHeaderText = styled.p`
    color: white;
    font-size: 40px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    @media (max-width: 900px) {
        font-size: 30px;
    }
    @media (max-width: 640px) {
        font-size: 14px;
    }
`;

const BannerContainer = styled.div`
    text-align: left;
    align-self: center;
    @media (max-width: 640px) {
        text-align: center;
    }
`;

export default {
    BannerContainer,
    PhoneSection,
    PhoneWrapper,
    PhoneImg,
    PhoneContentText,
    PhoneHeaderText,
    PressSection,
    CardsContainer,
    ImageBanks,
    WhiteContainer,
    SectionsButton,
    SocialMedia,
    PaddingWrapper,
    VenderCard,
    VenderCards,
    VenderSection,
    TaglineHomeSec,
    CardCenter,
    Devider,
    TextSection,
    TitleSection,
    SingleCard,
    SectionTwoText,
    TaglineHome,
    SectionTwoAdditionalText,
    SectionTwoImageContainer,
    SectionTwoImg,
    SectionTwoBox,
    MainTitle,
    SectionTwo,
    WrapperSection,
    ChartCard,
    StyledButtonBlueExt,
    HomeContainer,
    ButtonNewContainer,
    TextSectionImg,
    ComprarText,
    ComprarTextHeader,
    ContainerButtons,
    CardFlexContainer,
};
