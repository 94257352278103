import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
import Accordion from "../../components/Accordion";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";

// Media images

import waveBlueUp from "media/waves/wave_blue_up.svg";

import { Helmet } from "react-helmet";

const Fees = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Fees",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.ContainerSectionHeader>
                    <Styled.TaglineAbout>
                        <PrimaryText>Comisiones</PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            Nuestras comisiones de servicio{" "}
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerSectionHeader>
            </Styled.AboutContainer>

            <Styled.ContentBoxedContainer>
                <Paragraph>
                    En AgenteBTC queremos darte el mejor servicio, y para que
                    nuestros productos funcionen correctamente, cobramos una
                    comisión en algunas de las transacciones.
                </Paragraph>

                <Accordion
                    title="Compra y venta básica de Bitcoin"
                    state={true}
                    shortStyle={true}
                >
                    <Paragraph>
                        Estas son las comisiones que se aplican en la compra y
                        la venta habitual de Bitcoin dentro del sitio.
                    </Paragraph>

                    <p>
                        <table class="unstyledTable">
                            <thead>
                                <tr>
                                    <th>Forma</th>
                                    <th>Comisión</th>
                                    <th>Descripción</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Compra y venta instantánea</td>
                                    <td>1%</td>
                                    <td>
                                        Operaciones a través de nuestra
                                        calculadora. Respetando el precio
                                        elegido durante 30 segundos.{" "}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </p>
                </Accordion>

                <Accordion
                    title="Abonos de Soles (PEN) y Dólares (USD)"
                    shortStyle={true}
                    state={true}
                >
                    <div class="contentTable">
                        <h3>Depósitos</h3>

                        <table class="unstyledTable">
                            <thead>
                                <tr>
                                    <th>Forma</th>
                                    <th>Tiempo</th>
                                    <th>Comisión</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Transferencia bancaria</td>
                                    <td>&#60; 1 día util</td>
                                    <td>Sin costo</td>
                                </tr>

                                <tr>
                                    <td>Transferencia Interbancaria</td>
                                    <td>&#60; 1 día útil</td>
                                    <td>Sin costo</td>
                                </tr>

                                <tr>
                                    <td>Transferencia Bancaria SWIFT*</td>
                                    <td> 3-5 días</td>
                                    <td>3% del monto enviado</td>
                                </tr>
                            </tbody>

                            <i>* Clientes Internacionales </i>
                        </table>

                        <h3>Retiros</h3>

                        <table class="unstyledTable">
                            <thead>
                                <tr>
                                    <th>Forma</th>
                                    <th>Tiempo</th>
                                    <th>Comisión</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Transferencia Bancaria</td>
                                    <td>1-2 días utiles</td>
                                    <td>S/ 3 o $ 1 (en caso de dólares) </td>
                                </tr>

                                <tr>
                                    <td>Transferencia Interbancaria</td>
                                    <td>1-2 días utiles</td>
                                    <td>S/ 3 o $ 1 (en caso de dólares) </td>
                                </tr>

                                <tr>
                                    <td>Transferencia Bancaria (SWIFT)* </td>
                                    <td>3-5 días utiles</td>
                                    <td>1% del monto total a retirar</td>
                                </tr>
                            </tbody>

                            <i>
                                * Clientes Internacionales, los tiempos de
                                proceso del envió SWIFT dependerán de las
                                entidades bancarias, se adjunta un tiempo
                                referencial{" "}
                            </i>
                        </table>
                    </div>
                </Accordion>

                <Accordion
                    className="active"
                    title="Criptomonedas"
                    state={false}
                    shortStyle={true}
                >
                    <div class="contentTable">
                        <h3>Depósitos</h3>

                        <table class="unstyledTable">
                            <thead>
                                <tr>
                                    <th>Criptomoneda</th>
                                    <th>Tiempo</th>
                                    <th>Comisión</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Bitcoin</td>
                                    <td>6 confirmaciones</td>
                                    <td>Sin costo</td>
                                </tr>
                            </tbody>
                        </table>

                        <h3>Retiros</h3>

                        <table class="unstyledTable">
                            <thead>
                                <tr>
                                    <th>Criptomoneda</th>
                                    <th>Comisión</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Bitcoin*</td>
                                    <td>0.0005 BTC </td>
                                </tr>
                            </tbody>

                            <i>* El monto mínimo para retirar es 0.002 BTC </i>
                        </table>
                    </div>
                </Accordion>

                <Accordion
                    title="Compra y venta especializada (OTC)"
                    shortStyle={true}
                >
                    <Paragraph>
                        Comisiones aplicadas a operaciones de mayor inversión.
                        Apartir de 40 mil dólares.
                    </Paragraph>

                    <p>
                        <table class="unstyledTable">
                            <thead>
                                <tr>
                                    <th>Forma</th>
                                    <th>Comisión</th>
                                    <th>Descripción</th>
                                    <th>Monto</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        Compra/Venta Asesor (solo en dólares)
                                    </td>
                                    <td>% variable</td>
                                    <td>
                                        Asesores especializados para ayudarte en
                                        tus inversiones
                                    </td>
                                    <td>
                                        A partir de 40 mil dólares americanos
                                        (USD)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </p>
                </Accordion>
            </Styled.ContentBoxedContainer>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Comisiones de servicio | Agente BTC</title>
                <meta
                    name="description"
                    content="Comisiones del Servicio de Agente BTC, el primer exchange de bitcoins legal en el Perú. Existen distintas comisiones según la moneda y el tipo de depósito."
                />
            </Helmet>
        </>
    );
};

export default withRouter(Fees);
