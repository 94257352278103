import React from "react";
import { withRouter } from "react-router";
import "aos/dist/aos.css";

// Styled components
import { Section, Wrapper } from "styles/Styled";

import { PrimaryTextLanding, SecondaryTextLandingSec } from "styles/colorized";

import Styled from "./styles";

// Get the USD to PEN rate
import { ExchangeProvider } from "ExchangeContext";
// Get the time
import { DateProvider } from "DateContext";

import agentebtcapi from "agentebtcapi.js";

import { Helmet } from "react-helmet";

const Nosotros = ({ location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Nosotros",
        },
    });

    return (
        <>
            <ExchangeProvider>
                <DateProvider>
                    <>
                        <Styled.HomeContainer>
                            <Section style={{ paddingBottom: 15 }}>
                                <img
                                    src={require("../../media/home/LandBack_1.png")}
                                    style={{
                                        position: "absolute",
                                        zIndex: -1,
                                        top: 0,
                                        left: 0,
                                        opacity: 0.7,
                                    }}
                                    width={"100%"}
                                />
                                <div
                                    style={{
                                        textAlign: "center",
                                        justifyItems: "center",
                                        display: "grid",
                                    }}
                                >
                                    <a
                                        style={{
                                            color: "#FFC500",
                                            fontSize: 50,
                                            fontWeight: "bold",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        Nosotros
                                    </a>
                                    <Styled.SingleCard>
                                        <div style={{ padding: 15 }}>
                                            <Styled.CardContent>
                                                Creemos que las criptomonedas
                                                serán una parte integral de
                                                nuestro futuro y por ello
                                                combinamos esfuerzos para
                                                lograrlo juntos.
                                            </Styled.CardContent>
                                        </div>
                                    </Styled.SingleCard>
                                </div>
                            </Section>
                        </Styled.HomeContainer>
                        <Section>
                            <Wrapper style={{ paddingInline: 0 }}>
                                <div
                                    style={{
                                        backgroundColor: "#F4FAFF",
                                        paddingBottom: 25,
                                    }}
                                >
                                    <Styled.SectionTwoBox>
                                        <Styled.SectionTwo>
                                            <img
                                                style={{
                                                    width: 45,
                                                    paddingBottom: 15,
                                                }}
                                                alt="wallet of criptomonedas"
                                                src={require("../../media/icons/Nosotros_icon.png")}
                                            />
                                            <Styled.TaglineHome>
                                                <PrimaryTextLanding>
                                                    ¿Quienes
                                                </PrimaryTextLanding>
                                                &nbsp;
                                                <SecondaryTextLandingSec>
                                                    somos ?
                                                </SecondaryTextLandingSec>
                                            </Styled.TaglineHome>
                                            <div style={{ width: "65%" }}>
                                                <Styled.SectionTwoText>
                                                    AgenteBTC es la primera
                                                    exchange de criptomonedas en
                                                    Perú que propone una
                                                    amigable introducción en el
                                                    mercado de monedas
                                                    digitales.
                                                </Styled.SectionTwoText>
                                            </div>
                                        </Styled.SectionTwo>
                                        <Styled.SectionTwoImageContainer>
                                            <Styled.SectionTwoImg
                                                src={require("../../media/icons/nosotros_section_two_image.png")}
                                                alt="comprar en peru, bitcoin a dolar"
                                            />
                                        </Styled.SectionTwoImageContainer>
                                    </Styled.SectionTwoBox>
                                    <div style={{ textAlign: "center" }}>
                                        <Styled.SectionHeader>
                                            Principios
                                        </Styled.SectionHeader>
                                        <Styled.WrapperPrincipiosSection>
                                            <Styled.PrincipiosSection
                                                style={{ width: "100%" }}
                                            >
                                                <Styled.ContainerPrincipios
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Styled.PrincipiosCard>
                                                        <div
                                                            style={{
                                                                padding: 15,
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: 64,
                                                                }}
                                                                alt="invertir y ahorrar en peru"
                                                                src={require("../../media/icons/principios_icon_1.png")}
                                                            />
                                                            <Styled.PrincipiosCardTitle>
                                                                Accesibilidad
                                                            </Styled.PrincipiosCardTitle>
                                                            <Styled.PrincipiosCardContent>
                                                                Democratizamos
                                                                la inversión en
                                                                criptos a través
                                                                de procesos
                                                                simples y al
                                                                alcance de
                                                                plataformas
                                                                tradicionales.
                                                            </Styled.PrincipiosCardContent>
                                                        </div>
                                                    </Styled.PrincipiosCard>
                                                </Styled.ContainerPrincipios>
                                                <Styled.ContainerPrincipios
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Styled.PrincipiosCard>
                                                        <div
                                                            style={{
                                                                padding: 15,
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: 43,
                                                                }}
                                                                alt="invertir y ahorrar en peru"
                                                                src={require("../../media/icons/principios_icon_2.png")}
                                                            />
                                                            <Styled.PrincipiosCardTitle>
                                                                Seguridad
                                                            </Styled.PrincipiosCardTitle>
                                                            <Styled.PrincipiosCardContent>
                                                                Utilizamos
                                                                protocolos
                                                                informáticos
                                                                estrictos para
                                                                resguardar la
                                                                integridad de
                                                                los activos
                                                                digitales.
                                                            </Styled.PrincipiosCardContent>
                                                        </div>
                                                    </Styled.PrincipiosCard>
                                                </Styled.ContainerPrincipios>
                                                <Styled.ContainerPrincipios
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Styled.PrincipiosCard>
                                                        <div
                                                            style={{
                                                                padding: 15,
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: 43,
                                                                }}
                                                                alt="invertir y ahorrar en peru"
                                                                src={require("../../media/icons/principios_icon_3.png")}
                                                            />
                                                            <Styled.PrincipiosCardTitle>
                                                                Rapidez
                                                            </Styled.PrincipiosCardTitle>
                                                            <Styled.PrincipiosCardContent>
                                                                La compra es tan
                                                                intuitiva y
                                                                sencilla que
                                                                puede
                                                                completarse en
                                                                tan solo unos
                                                                pasos.
                                                            </Styled.PrincipiosCardContent>
                                                        </div>
                                                    </Styled.PrincipiosCard>
                                                </Styled.ContainerPrincipios>
                                            </Styled.PrincipiosSection>
                                        </Styled.WrapperPrincipiosSection>
                                    </div>
                                </div>
                            </Wrapper>
                        </Section>
                        <Section>
                            <Wrapper style={{ paddingInline: 0 }}>
                                <div style={{ textAlign: "center" }}>
                                    <Styled.SectionHeader>
                                        Cómo nació AgenteBTC
                                    </Styled.SectionHeader>
                                    <Styled.TextSectionContainer>
                                        <Styled.TextSection>
                                            Empezamos en 2019 dentro de un
                                            pequeño salón en Jesus María, a
                                            partir del entusiasmo,poco
                                            comprendido en ese entonces, entorno
                                            a las criptomonedas y la
                                            revolucionaría tecnología
                                            blockchain.
                                        </Styled.TextSection>
                                        <Styled.TextSection>
                                            Poco a poco fuimos contagiando este
                                            entusiasmo a otros profesionales,
                                            conformando un equipo sólido de
                                            innovadores. Pronto despertamos el
                                            interés de una importante
                                            aceleradora y así, el sueño de una
                                            exchange peruana de criptomonedas se
                                            hizo realidad.
                                        </Styled.TextSection>
                                    </Styled.TextSectionContainer>
                                </div>
                                <Styled.WrapperPrincipiosSection>
                                    <Styled.DateSection>
                                        <Styled.ContainerDate>
                                            <Styled.DateCardFirst>
                                                <div
                                                    style={{
                                                        padding: 15,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: 45,
                                                            width: 45,
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: 43,
                                                            }}
                                                            alt="invertir y ahorrar en peru"
                                                            src={require("../../media/icons/date_icon_1.png")}
                                                        />
                                                    </div>
                                                    <Styled.PrincipiosCardTitle>
                                                        2019
                                                    </Styled.PrincipiosCardTitle>
                                                    <Styled.PrincipiosCardContent
                                                        style={{
                                                            display:
                                                                "list-item",
                                                        }}
                                                    >
                                                        AgenteBTC lanza su
                                                        primer{" "}
                                                        <b>MVP de Exchange.</b>
                                                    </Styled.PrincipiosCardContent>
                                                    <Styled.PrincipiosCardContent
                                                        style={{
                                                            display:
                                                                "list-item",
                                                        }}
                                                    >
                                                        AgenteBTC obtiene su
                                                        ingreso a{" "}
                                                        <b>UTEC Ventures</b>{" "}
                                                        (aceleradora).
                                                    </Styled.PrincipiosCardContent>
                                                </div>
                                            </Styled.DateCardFirst>
                                        </Styled.ContainerDate>

                                        <Styled.ContainerDate>
                                            <Styled.DateCard>
                                                <div
                                                    style={{
                                                        padding: 15,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: 45,
                                                            width: 45,
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: 43,
                                                            }}
                                                            alt="invertir y ahorrar en peru"
                                                            src={require("../../media/icons/date_icon_2.png")}
                                                        />
                                                    </div>
                                                    <Styled.PrincipiosCardTitle>
                                                        2020
                                                    </Styled.PrincipiosCardTitle>
                                                    <Styled.PrincipiosCardContent
                                                        style={{
                                                            display:
                                                                "list-item",
                                                        }}
                                                    >
                                                        AgenteBTC lanza su{" "}
                                                        <b>
                                                            trading básico para
                                                            usuarios.
                                                        </b>
                                                    </Styled.PrincipiosCardContent>
                                                    <Styled.PrincipiosCardContent
                                                        style={{
                                                            display:
                                                                "list-item",
                                                        }}
                                                    >
                                                        AgenteBTC lanza{" "}
                                                        <b>
                                                            Remesas con criptos,
                                                            Batan.
                                                        </b>
                                                    </Styled.PrincipiosCardContent>
                                                </div>
                                            </Styled.DateCard>
                                        </Styled.ContainerDate>
                                        <Styled.ContainerDate>
                                            <Styled.DateCardActual>
                                                <div
                                                    style={{
                                                        padding: 15,
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 43,
                                                        }}
                                                        alt="invertir y ahorrar en peru"
                                                        src={require("../../media/icons/date_icon_3.png")}
                                                    />

                                                    <Styled.PrincipiosCardTitle
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        2021
                                                    </Styled.PrincipiosCardTitle>
                                                    <Styled.PrincipiosCardContent
                                                        style={{
                                                            display:
                                                                "list-item",
                                                            color: "white",
                                                        }}
                                                    >
                                                        AgenteBTC supera el{" "}
                                                        <b>$500 Medio Millón</b>{" "}
                                                        en volumen procesado.
                                                    </Styled.PrincipiosCardContent>
                                                </div>
                                            </Styled.DateCardActual>
                                        </Styled.ContainerDate>
                                        <Styled.ContainerDate>
                                            <Styled.DateCardLast>
                                                <div
                                                    style={{
                                                        padding: 15,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: 45,
                                                            width: 45,
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: 43,
                                                            }}
                                                            alt="invertir y ahorrar en peru"
                                                            src={require("../../media/icons/date_icon_4.png")}
                                                        />
                                                    </div>
                                                    <Styled.PrincipiosCardTitle>
                                                        2022
                                                    </Styled.PrincipiosCardTitle>
                                                    <Styled.PrincipiosCardContent>
                                                        A la fecha AgenteBTC
                                                        supera{" "}
                                                        <b>$5 Millones</b> en
                                                        volumen procesado.
                                                    </Styled.PrincipiosCardContent>
                                                </div>
                                            </Styled.DateCardLast>
                                        </Styled.ContainerDate>
                                    </Styled.DateSection>
                                </Styled.WrapperPrincipiosSection>
                            </Wrapper>
                        </Section>
                        <Section>
                            <Wrapper style={{ paddingInline: 0 }}>
                                <div style={{ textAlign: "center" }}>
                                    <Styled.SectionHeader>
                                        Somos{" "}
                                        <a style={{ color: "#FFC500" }}>
                                            AgenteBTC
                                        </a>
                                    </Styled.SectionHeader>
                                    <Styled.TextSection
                                        style={{
                                            fontSize: 20,
                                        }}
                                    >
                                        Los responsables de que tu inversión en
                                        el mundo cripto sea muy sencilla
                                    </Styled.TextSection>
                                </div>
                                <Styled.WrapperPrincipiosSection>
                                    <Styled.PrincipiosSection
                                        style={{ width: "100%" }}
                                    >
                                        <Styled.ContainerDate
                                            style={{
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Styled.FacesCard>
                                                <img
                                                    style={{ height: 140 }}
                                                    alt="invertir y ahorrar en peru"
                                                    src={require("../../media/face_images/berkeley_icon.png")}
                                                />
                                                <Styled.FaceCardContent>
                                                    Berkeley Wanner
                                                </Styled.FaceCardContent>
                                                <Styled.FaceCardHeader>
                                                    COO
                                                </Styled.FaceCardHeader>
                                            </Styled.FacesCard>
                                        </Styled.ContainerDate>
                                        <Styled.ContainerDate
                                            style={{
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Styled.FacesCard>
                                                <img
                                                    style={{ height: 140 }}
                                                    alt="invertir y ahorrar en peru"
                                                    src={require("../../media/face_images/miguel_icon.png")}
                                                />
                                                <Styled.FaceCardContent>
                                                    Miguel Torres
                                                </Styled.FaceCardContent>
                                                <Styled.FaceCardHeader>
                                                    CTO
                                                </Styled.FaceCardHeader>
                                            </Styled.FacesCard>
                                        </Styled.ContainerDate>
                                        <Styled.ContainerDate
                                            style={{
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Styled.FacesCard>
                                                <img
                                                    style={{ height: 140 }}
                                                    alt="invertir y ahorrar en peru"
                                                    src={require("../../media/face_images/victor_icon.png")}
                                                />
                                                <Styled.FaceCardContent>
                                                    Victor Egoavil
                                                </Styled.FaceCardContent>
                                                <Styled.FaceCardHeader>
                                                    CEO - Founder
                                                </Styled.FaceCardHeader>
                                            </Styled.FacesCard>
                                        </Styled.ContainerDate>
                                        <Styled.ContainerDate
                                            style={{
                                                alignItems: "center",
                                            }}
                                        >
                                            <Styled.FacesCard>
                                                <img
                                                    style={{ height: 140 }}
                                                    alt="invertir y ahorrar en peru"
                                                    src={require("../../media/face_images/rodrigo_icon.png")}
                                                />
                                                <Styled.FaceCardContent>
                                                    Rodrigo Neira
                                                </Styled.FaceCardContent>
                                                <Styled.FaceCardHeader>
                                                    CFO
                                                </Styled.FaceCardHeader>
                                            </Styled.FacesCard>
                                        </Styled.ContainerDate>
                                    </Styled.PrincipiosSection>
                                </Styled.WrapperPrincipiosSection>
                            </Wrapper>
                        </Section>
                        <Section>
                            <Styled.PhoneWrapper>
                                <Styled.PhoneSection>
                                    <Styled.PhoneImg
                                        alt="invertir y ahorrar en peru"
                                        src={require("../../media/home/phone_section_nosotros.png")}
                                    />

                                    <div
                                        style={{
                                            textAlign: "center",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <Styled.PhoneContentText>
                                            Ahora que nos conoces un poco más
                                        </Styled.PhoneContentText>
                                        <Styled.PhoneHeaderText>
                                            ¡Súmate a Nosotros!
                                        </Styled.PhoneHeaderText>
                                        <Styled.ContainerButtons>
                                            <Styled.StyledButtonBlueExt
                                                href={`${agentebtcapi.webapp}/auth/register`}
                                                style={{
                                                    background: `linear-gradient(#FFC500,#F7931E)`,
                                                }}
                                            >
                                                Comenzar AHORA
                                                <img
                                                    style={{
                                                        width: 30,
                                                        paddingLeft: 20,
                                                    }}
                                                    src={require("../../media/icons/arrowIcon.png")}
                                                />
                                            </Styled.StyledButtonBlueExt>
                                        </Styled.ContainerButtons>
                                    </div>
                                </Styled.PhoneSection>
                                <Styled.TextCard>
                                    <div
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        <Styled.TextCardContent>
                                            “Las huellas de las personas que
                                            caminan juntas nunca se borran”
                                        </Styled.TextCardContent>
                                        <Styled.TextCardTitle>
                                            (Proverbio Africano)
                                        </Styled.TextCardTitle>
                                    </div>
                                </Styled.TextCard>
                            </Styled.PhoneWrapper>
                        </Section>
                    </>
                </DateProvider>
            </ExchangeProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Nosotros | Agente BTC</title>
                <meta
                    name="description"
                    content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://agentebtc.com/" />
                <meta
                    property="og:title"
                    content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
                />
                <meta
                    property="og:description"
                    content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
                />
                <meta
                    property="og:image"
                    content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
                />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://agentebtc.com/" />
                <meta
                    property="twitter:title"
                    content="Compra y venta de Bitcoin en Perú. Invierte fácil y seguro en nuestra plataforma mediante transferencia bancaria."
                />
                <meta
                    property="twitter:description"
                    content="La experiencia más simple y segura de compra y venta de criptomonedas en Perú. Deposita dinero sin comisiones, operaciones seguras las 24h, asesoría personalizada."
                />
                <meta
                    property="twitter:image"
                    content="https://www.agentebtc.com/static/media/WebappLanding.2fb3f1b4.png"
                />
            </Helmet>
        </>
    );
};

export default withRouter(Nosotros);
