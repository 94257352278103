import React, {useState, useEffect, useContext} from 'react';
import { withRouter } from 'react-router';

import agentebtcapi from 'agentebtcapi.js';
import swal from 'sweetalert';
import {AuthContext} from 'Auth';
import queryString from 'query-string';

import { Header1 } from 'styles/common/index';
import StyledAuth from '../styles';

const Login = ({ history, location }) => {
  const {updateToken, updateExpiration} = useContext(AuthContext);
  const [buttonEnabled, setButtonEnabled] = useState(true);


  const values = queryString.parse(location.search);

  if(values.show_message == 'account_verified'){
    swal({text: "Muchas gracias. Su cuenta ha sido verificada", button: false, timer: 4000});
  }

  const handleLogin = (e)=>{
    e.preventDefault();
    setButtonEnabled(false);

    const { email, password } = e.target.elements;
    let body = {email: email.value, password: password.value};
    let data =  {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body) // body data type must match "Content-Type" header
    }
    let endpoint = '/auth/login';
    let url = `${agentebtcapi}${endpoint}`;



    fetch(url, data)
      .then((response) => {

        setButtonEnabled(true);


        if(response.ok){
          return response.json();
        }
        else if(response.status === 403){
            throw {'type': 'not-confirmed'}
        }else{
          throw {'type': 'incorrect-data'}
        }
      })
      .then((responseJson)=>{
          setButtonEnabled(true);

          if(!responseJson.token){
            swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
          }else{
            swal({text: "Bienvenido", button: false, timer: 1000});
            updateToken(responseJson.token);
            updateExpiration(responseJson.dateExpiration);
            history.push('/app');
          }
      })
      .catch((error)=>{
        setButtonEnabled(true);

        switch (error.type) {
          case 'incorrect-data':
            swal({text: "Usuario o contraseña incorrecto", button: false, timer: 1000});
            break;
          case 'not-confirmed':
            swal({text: "Por favor, revisa tu correo para confirmar tu cuenta", button: false, timer: 1000});
            break;
          default:
            swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
        }
      })
  }

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {




    scrollTop();
  });



  return (
    <>
      <Header1>Inicio de sesión</Header1>
      <StyledAuth.AuthForm onSubmit={handleLogin}>
        <StyledAuth.AuthInput name="email" type="email" placeholder="Tu email" />
        <StyledAuth.AuthInput name="password" type="password" placeholder="Tu contraseña" />
        <StyledAuth.AuthButton type="submit">Iniciar sesión</StyledAuth.AuthButton >
      </StyledAuth.AuthForm>
      <StyledAuth.AuthText>
        ¿Aún no estás registrado? &nbsp;
        <StyledAuth.AuthLink to="/test">
          Crea una cuenta
        </StyledAuth.AuthLink>
      </StyledAuth.AuthText>
      <StyledAuth.AuthText>
        ¿Olvidaste tu contraseña? &nbsp;
        <StyledAuth.AuthLink to="/auth/recover">
          Recuperar tu contraseña
        </StyledAuth.AuthLink>
      </StyledAuth.AuthText>
    </>
  );
};

export default withRouter(Login);
