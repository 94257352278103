import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";

import heroImg from "media/heros/about_us@2x.png";
import imgLuis from "images/luis@2x.png";
import imgVictor from "images/victor@2x.png";
import imgCarolina from "images/carolina@2x.png";
import imgCristian from "images/cristian@2x.png";
import imgBrayan from "images/brayan@2x.png";
import imgFabian from "images/fabian@2x.png";

import logoBcp from "images/logo-bcp-w.png";
import logoBbva from "images/logo-bbva-w.png";
import logoInterbank from "images/logo-interbank-w.png";

import bitcoin from "media/icons/icon_bitcoin.png";
import fiat from "media/fiat@2x.png";
import time from "media/time@2x.png";

// Media images
import iconHouse from "media/icons/icon_house.png";
import iconJoin from "media/icons/icon_join.png";
import iconSecurity from "media/icons/icon_security.png";
import iconCurrency from "media/icons/icon_currency.png";

import heroGetStarted from "media/heros/get_started.png";
import heroWhyChoose from "media/heros/why_we_choose.png";
import heroUseCases from "media/heros/unlock_potential.png";

import bankLogos from "media/logo_banks.png";
import logoRecidar from "media/logo_recidar.png";
import logoUsil from "media/logo_usil.png";
import logoTasaTop from "media/logo_tasatop.png";

import waveGrayUp from "media/waves/wave_gray_up.svg";
import waveGrayDown from "media/waves/wave_gray_down.svg";
import waveBlueUp from "media/waves/wave_blue_up.svg";

import { Helmet } from "react-helmet";

const Terms = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Terms",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.ContainerSectionHeader>
                    <Styled.TaglineAbout>
                        <PrimaryText>Términos y condiciones</PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            A continuación encontrarás los Términos y
                            Condiciones del sitio. <br></br>
                            Forman un contrato entre AgenteBTC.com (Compañia de
                            Activos Digitales S.A.C) y el usuario.
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerSectionHeader>
            </Styled.AboutContainer>

            <Styled.ContentBoxedContainer>
                <Paragraph>
                    A continuación encontrarás los Términos y Condiciones (o
                    “Términos de Uso”) del sitio web www.agentebtc.com (el
                    “Sitio”), los que, conjuntamente con la Política de
                    Privacidad, forman un contrato entre AgenteBTC.com (Compañia
                    de Activos Digitales S.A.C con RUC: 20606053194, en adelante
                    “AgenteBTC.com”) y el usuario (en adelante “tú” o el
                    “Usuario”). En los presentes Términos de Uso, cuando nos
                    referimos a AgenteBTC.com, se incluye por referencia a sus
                    propietarios, directores, administradores, inversionistas,
                    empleados o cualquier persona natural o jurídica
                    relacionada. Dependiendo del contexto, AgenteBTC.com puede
                    también hacer referencia a los servicios, productos, sitio
                    web, contenido o cualquier material que AgenteBTC.com provea
                    en el Sitio o fuera del mismo.
                </Paragraph>

                <Header3>
                    Riesgos en relación a las Criptomonedas y Uso del Sitio
                </Header3>

                <Paragraph>
                    El intercambio de bienes o productos, reales o virtuales,
                    así como también de dinero o monedas, conllevan en si un
                    riesgo significativo. Los precios de las mismas pueden
                    variar con el paso del tiempo y por el acontecimiento de
                    distintos hechos. Producto de estas variaciones en el
                    precio, el valor de tus activos puede aumentar o disminuir
                    drásticamente en cualquier momento y sin previo aviso.
                </Paragraph>

                <Paragraph>
                    Asimismo, tú declaras y aceptas que el intercambio de
                    Criptomonedas tiene riesgos especiales, los que no son
                    necesariamente compartidos por las monedas, activos o
                    productos de libre intercambio. A diferencia de las monedas
                    o divisas del resto del mundo, las que se encuentran
                    respaldadas por reservas federales, bancos centrales,
                    gobiernos u otras entidades, o por commodities tales como el
                    oro y plata, las Criptomonedas sólo cuentan con el respaldo
                    de la tecnología y la confianza mutua de los usuarios que
                    las poseen. Es decir, no existe una reserva federal, banco
                    central, gobierno u otra entidad que proteja su valor, y el
                    mismo se alcanza exclusivamente bajo el equilibrio entre
                    oferta y demanda.
                </Paragraph>

                <Paragraph>
                    Aunque no es nuestra misión ni nuestra intención definir
                    exactamente qué es una Criptomoneda y su sistema de
                    generación, podemos señalar, en pocas palabras, y tomando
                    como claro referente al bitcoin, que corresponde a un
                    sistema global, descentralizado y autónomo, autorregulado, a
                    través del cual se genera y circula un commodity virtual, o
                    activo digital, llamado Criptomoneda, y cuyo valor se basa
                    en la confianza que los mismos usuarios le dan al sistema.
                </Paragraph>

                <Paragraph>
                    A este respecto, tú declaras conocer y aceptar que el
                    comercio o intercambio de Criptomonedas podría ser
                    susceptible de burbujas irracionales (o racionales), estar
                    sujeto a la pérdida de confianza, y en consecuencia podrían
                    conllevar a un colapso o desplome en su demanda respecto de
                    la oferta. Solo a modo de ejemplo, pues los escenarios
                    pueden ser variados, la confianza en las Criptomonedas
                    podría desplomarse producto de un cambio inesperado impuesto
                    por un desarrollador de software, por la prohibición que
                    algún gobierno o estado pueda imponer sobre el mismo, por la
                    creación de una alternativa técnicamente superior, o
                    simplemente por un espiral inflacionario o deflacionario.
                    Asimismo, la confianza en las Criptomonedas podría colapsar
                    por eventuales problemas técnicos, tales como si el dinero
                    de los propietarios o personas que interactúan u operan con
                    Criptomonedas fuera robado o se pierda, o si hackers o
                    gobiernos logran prevenir que se realicen transacciones.
                </Paragraph>

                <Paragraph>
                    En cuanto a las transacciones de compra y venta de
                    Criptomonedas, tú entiendes y aceptas que el precio que se
                    indica en la cotización de compra/venta dentro de
                    AgenteBTC.com es un aproximado que puede cambiar (incluso
                    para el momento en que se coloque la orden de compra o venta
                    respectiva) en la medida que cambien las condiciones del
                    mercado. Dado que son los clientes los que a través de sus
                    requerimientos de compra y venta de las Criptomonedas en
                    AgenteBTC.com, no es posible para esta última asegurar un
                    precio en la cotización respectiva. Por lo anterior, una vez
                    hecho el requerimiento e ingresadas a la Plataforma y las
                    mismas son procesadas por AgenteBTC.com. Cada Usuario es
                    responsable por el activo que está comprando.{" "}
                    <b>
                        Asimismo, los Usuarios serán responsable por la
                        manipulación o movimiento de criptomonedas desde sus
                        propias wallets.
                    </b>
                </Paragraph>

                <Paragraph>
                    AgenteBTC.com se reserva el derecho de rehusarse a procesar
                    cualquier orden de compra, venta o retiro de Criptomonedas o
                    moneda de curso legal, a su sola discreción, si es que
                    AgenteBTC.com sospecha que la orden involucra (o tiene un
                    alto riesgo de involucrar) actividades relacionadas al
                    lavado de activos, financiamiento del terrorismo, fraude, o
                    cualquier tipo de crimen financiero, u actividad que no sea
                    acorde a lo establecido en estos Términos y Condiciones; o
                    en caso de ser requeridos judicial o administrativamente por
                    alguna autoridad competente. En tales circunstancias,
                    AgenteBTC.com cancelará o se rehusará a procesar la orden,
                    sin obligación alguna a permitir nuevamente una orden
                    posterior al mismo precio o en los mismos términos que la
                    orden cancelada o rehusada.
                </Paragraph>

                <Paragraph>
                    Por último, es menester señalar que es posible que haya
                    otros riesgos no considerados o identificados en estos
                    Términos de Uso, los cuales los Usuarios Registrados están
                    dispuestos a soportar, liberando a AgenteBTC.com de
                    cualquier responsabilidad sobre los mismos.
                </Paragraph>

                <Paragraph>
                    Por lo anterior y lo más importante, tú debes analizar
                    cuidadosamente tu situación financiera y tu tolerancia al
                    riesgo antes de comprar o vender Criptomonedas en el Sitio.
                </Paragraph>

                <Header3>
                    Cobro por servicio, Costos de Transacción y Compensación
                </Header3>

                <Paragraph>
                    AgenteBTC.com cobra por todas las transacciones que suceden
                    dentro de nuestra plataforma, sean de compra como también de
                    venta (cobro por servicio). Por otro lado, pueden existir
                    costos asociados a abonos y retiros, tanto de moneda de
                    curso legal como de Criptomonedas, los que son cobrados por
                    bancos, medios de pago, proveedores de servicios de
                    transferencia, y las redes de cada una de las Criptomonedas
                    soportadas por la Plataforma. AgenteBTC.com se guarda el
                    derecho de modificar sus comisiones de servicio en cualquier
                    momento.
                </Paragraph>
                <Paragraph>
                    La comisión de servicio es cobrada en su totalidad al
                    momento de la transacción, por lo que no existen ni
                    existirán cobros posteriores asociados a éstas.
                </Paragraph>
                <Paragraph>
                    AgenteBTC.com informa que existen ciertos costos de
                    transacción asociados a cobros que realizan las redes o
                    infraestructura detrás de las Criptomonedas, como por
                    ejemplo al retirar bitcoins desde tu Cuenta a tu billetera
                    Bitcoin personal. Estos costos, no obstante se ajustan
                    diariamente por reglas de mercado, se te notificarán
                    previamente al momento de solicitar el retiro. A este
                    respecto, todo retiro en Criptomonedas desde tu cuenta
                    AgenteBTC.com a una billetera de Criptomonedas externa
                    deberá ser por un monto al menos superior a los costos de
                    transacción de la red, vigentes al momento del retiro. Tú
                    declaras estar en pleno conocimiento de lo anterior y lo
                    aceptas.
                </Paragraph>
                <Paragraph>
                    En caso de haberse facturado cobros por servicio, costos de
                    transacción o cargos que no hubiesen correspondido, el
                    Usuario Registrado deberá comunicarse con nuestro equipo de
                    Atención al Cliente para resolver dicha situación a
                    soporte@agentebtc.com.
                </Paragraph>

                <Header3>Medios de Pago y Abono</Header3>

                <Paragraph>
                    El único medio de pago y abono autorizado en el Sitio, salvo
                    que se señale expresamente de otra manera, pueden ser,
                    aunque no son una obligación para AgenteBTC.com, el
                    siguiente:
                </Paragraph>

                <Paragraph>
                    Transferencia bancaria: Éstas deben ser realizadas hacia las
                    cuentas bancarias indicada por AgenteBTC.com y desde una
                    cuenta bancaria de propiedad del Usuario titular que realiza
                    el respectivo abono o pago. No se aceptan transferencias
                    desde cuentas bancarias de terceros y no respetar esta
                    restricción resultará en la devolución total de los fondos,
                    menos los cargos que establezca el banco de AgenteBTC.com, a
                    la misma cuenta bancaria desde donde provienen los fondos.
                </Paragraph>

                <Paragraph>
                    Socios AgenteBTC: Son los Socios de AgenteBTC los únicos
                    entes recaudadores de dinero a nombre de AgenteBTC.com en
                    efectivo, una vez iniciado el proceso de venta de las
                    monedas digitales por parte de nuestros Socios AgenteBTC la
                    transacción no puede ser cancelada (ya que las operaciones
                    son irreversibles).
                </Paragraph>

                <Paragraph>
                    Se deja expresa constancia que en cumplimiento de la
                    legislación aplicable cabe la posibilidad que los adelantos
                    realizados por los Usuarios Registrado sean reportados a la
                    respectiva agencia gubernamental encargada del control del
                    lavado de activo y financiamiento del terrorismo.
                </Paragraph>

                <Paragraph>
                    Devoluciones: Las devoluciones están sin efecto ya que una
                    vez realizado y entregado el activo, no se hará ningún tipo
                    de devolución de fondos. El Usuario declara entender y
                    aceptar lo anterior.
                </Paragraph>

                <Header3>Disponibilidad del Sitio</Header3>

                <Paragraph>
                    Tú aceptas y acuerdas que AgenteBTC.com no se responsabiliza
                    por cualquier daño, perjuicio o pérdida al Usuario
                    Registrado causados por fallas en el sistema, en el
                    servidor, en Internet o en el Sitio. AgenteBTC.com tampoco
                    será responsable por cualquier virus que pudiera infectar el
                    equipo del Usuario Registrado como consecuencia del acceso,
                    uso o examen del Sitio o a raíz de cualquier transferencia
                    de datos, archivos, imágenes, textos, o audio contenidos en
                    el mismo. Los Usuarios NO podrán imputarle responsabilidad
                    alguna ni exigir pago por lucro cesante o daño alguno, en
                    virtud de perjuicios resultantes de dificultades técnicas o
                    fallas en los sistemas, Internet o en el Sitio.
                    AgenteBTC.com no garantiza el acceso y uso continuado o
                    ininterrumpido del Sitio. El sistema puede eventualmente no
                    estar disponible debido a dificultades técnicas o fallas de
                    Internet, o por cualquier otra circunstancia ajena o propia
                    a AgenteBTC.com; en tales casos se procurará restablecerlo
                    con la mayor celeridad posible sin que por ello pueda
                    imputársele algún tipo de responsabilidad. AgenteBTC.com no
                    será responsable por ningún error u omisión de contenidos en
                    el Sitio.
                </Paragraph>

                <Header3>Derecho de Uso Limitado</Header3>

                <Paragraph>
                    A no ser que se exprese lo contrario, todo el material en
                    este Sitio es de propiedad de AgenteBTC.com y están
                    protegidos por derechos de autor, copyright, derechos de
                    marca y otras leyes internacionales que son aplicables. El
                    Usuario puede revisar, imprimir y/o descargar copias del
                    material en el Sitio para fines exclusivamente personales,
                    informativos y de usos no-comerciales.
                </Paragraph>

                <Paragraph>
                    La marca y logo AgenteBTC.com usados en el Sitio ("Marca
                    Comercial") son propiedad de AgenteBTC.com y sus respectivos
                    dueños. El software, textos, informes, reportes, imágenes,
                    gráficos, información, precios, intercambios, videos y
                    audios utilizados en el Sitio (el “Material”) son asimismo
                    propiedad de AgenteBTC.com, a menos que se indique
                    expresamente lo contrario. La Marca Comercial y el Material
                    no deben ser copiados, reproducidos, modificados,
                    republicados, cargados, posteados, transmitidos,
                    descompuestos (scrapped), coleccionados o distribuidos de
                    manera comercial, sea automatiza o manualmente. El uso de
                    cualquier material en otro sitio o red de computadores, para
                    cualquier fin ajeno al propio del Sitio de AgenteBTC.com,
                    queda estrictamente prohibido; cualquier uso sin
                    autorización significará una violación de los derechos de
                    autor, copyright, derechos de marca y otras leyes
                    internacionales que son aplicables, pudiendo resultar en
                    penas civiles o criminales.
                </Paragraph>

                <Header3>Sitios web externos</Header3>

                <Paragraph>
                    AgenteBTC.com no se hace responsable de ninguna manera de
                    los sitios web externos o de terceros a los que puedas
                    acceder desde el Sitio. A este respecto, ocasionalmente el
                    Sitio puede hacer referencia o tener links a otros sitios
                    web ("Sitios Externos"). AgenteBTC.com no controla estos
                    Sitios Externos o la información contenida en ellos, por lo
                    que no se hace responsable de tu interacción con los mismos,
                    ni tampoco te sugerimos que accedas o utilices los mismos.
                </Paragraph>

                <Paragraph>
                    Los Sitios Externos tienen políticas y términos de uso
                    distintos e independientes a los del Sitio de AgenteBTC, por
                    lo que su acceso y uso es muy diferente a lo que pueda
                    resultar en este Sitio y por lo mismo te sugerimos revisar
                    las políticas, reglas, términos y regulación de cada Sitio
                    Externo que visites. Es su responsabilidad tomar las
                    precauciones necesarias para asegurar que todo el acceso o
                    uso de dichos Sitios Externos esté libre de virus, worms
                    (gusanos) Troyanos y otros elementos de naturaleza
                    destructiva.
                </Paragraph>

                <Header3>Limitación de responsabilidad</Header3>

                <Paragraph>
                    AgenteBTC.com no se hace responsable de ningún daño, pérdida
                    de beneficios, pérdida de ingresos, pérdida de negocio,
                    pérdida de oportunidades, pérdida de datos, indirectos o
                    consecuenciales, a menos que la pérdida se haya producido
                    por culpa grave o dolo. Es el Usuario quien deberá probar
                    dichas circunstancias.
                </Paragraph>

                <Paragraph>
                    Sin perjuicio de lo anterior, la mayor responsabilidad que
                    le pueda caber a AgenteBTC.com bajo o en conexión con el uso
                    del Sitio está limitada a:
                </Paragraph>

                <Paragraph>
                    Para estos efectos tú autorizas a AgenteBTC.com para que
                    compruebe el estado, monto o cantidad que cada Usuario
                    Registrado posea en la Cuenta referencial de nuestra base de
                    datos y el procedimiento a través del cual se hayan
                    realizado las transacciones objeto de un reclamo
                </Paragraph>

                <Paragraph>
                    AgenteBTC.com no se hace responsable por los fondos
                    adquiridos hacia las wallets que recomienda, ya que
                    AgenteBTC.com no opera con blockchain ni wallets propias,
                    sólo es un distribuidor de criptomonedas
                </Paragraph>

                <Header3>Indemnización</Header3>

                <Paragraph>
                    El Usuario Registrado indemnizará, mantendrá indemne y
                    defenderá a AgenteBTC.com, sus filiales, empresas
                    subsidiarias y controladoras, y a sus respectivos
                    directores, gerentes, administradores y empleados, por
                    cualquier acción, reclamo o demanda de otros Usuarios
                    Registrados o terceros por sus actividades en el Sitio o por
                    su propio incumplimiento de los Términos de Uso y demás
                    Políticas que se entienden incorporadas a los Términos de
                    Uso o por la violación de cualesquiera leyes o derechos de
                    terceros, incluyendo los honorarios de abogados en una
                    cantidad razonable.
                </Paragraph>

                <Header3>Modificaciones de los Términos de Uso</Header3>

                <Paragraph>
                    AgenteBTC.com se reserva el derecho a modificar estos
                    Términos de Uso, en cualquier momento, y esos cambios serán
                    efectivos a contar del momento en que se publiquen.
                    AgenteBTC.com se compromete a hacer esfuerzos razonables por
                    informar los cambios materiales en los Términos de Uso, a
                    través de las vías que AgenteBTC.com considere pertinentes,
                    pero es responsabilidad última del Usuario revisar
                    periódicamente los Términos de Uso del Sitio.
                </Paragraph>

                <Paragraph>
                    Si realizados los cambios en los Término de Uso los Usuarios
                    continúan usando y accediendo al Sitio, significará que
                    aceptan dichos cambios.
                </Paragraph>

                <Header3>Compra y Venta</Header3>

                <Paragraph>
                    AgenteBTC tiene ventas mínimas y máximas por:
                </Paragraph>

                <Paragraph>
                    La compra mínima por parte de un usuario es de 15 USD y un
                    máximo de 5000 USD en valor referencial de criptomonedas
                    (bitcoin) por día.
                </Paragraph>

                <Paragraph>
                    AgenteBTC tiene compras mínimas y máximas por:
                </Paragraph>

                <Paragraph>
                    La venta mínima por parte de un usuario es de 15 USD y un
                    máximo de 5000 USD en valor referencial de criptomonedas
                    (bitcoin) por día.
                </Paragraph>

                <Paragraph>
                    AgenteBTC.com acepta la compra de todos los activos
                    (criptomonedas) vendidos a sus usuarios, no sobrepasando los
                    montos máximos y mínimos arriba mencionados y cantidades
                    adquiridas únicamente dentro de la plataforma de AgenteBTC.
                    AgenteBTC.com se reserva el derecho a sólo comprarle a sus
                    usuarios.
                </Paragraph>

                <Header3>Para otro tipo de Producto</Header3>

                <Paragraph>
                    Sea OTC, o alguno de nuestros servicios de movimiento de
                    volúmenes más altos de Criptomonedas, nosotros ofrecemos la
                    gestions y el movimiento. Más no somos gestores de
                    portafolio o Captadores de dinero, nuestra función con estos
                    servicios es ser un facilitador de las operaciones que
                    hagamos sea nacionales o internacionales.
                </Paragraph>
            </Styled.ContentBoxedContainer>

            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>
                            Ahora que nos conoces un poco más ¿Listo para el
                            futuro?{" "}
                        </Header1White>
                        <ParagraphSubtitleWhite>
                            Registrate en AgenteBTC en minutos
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSec to="/auth/login">
                                Comienza ahora :)
                            </Styled.StyledButtonYellowSec>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Términos de uso del sitio | Agente BTC</title>
                <meta
                    name="description"
                    content="Términos de Uso de Agente BTC, el primer exchange de bitcoins legal de Perú."
                />
            </Helmet>
        </>
    );
};

export default withRouter(Terms);
