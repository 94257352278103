import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


import analyticsCode from 'analyticsCode.js';

ReactGA.initialize(analyticsCode);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.set({ hostname: 'dev.agentebtc.com' });

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

ReactPixel.init('718439645423154', {}, options);
ReactPixel.pageView(); // For tracking page view
ReactPixel.fbq('track', 'PageView');

toast.configure()

//ReactDOM.render(<App />, document.getElementById('root')); 

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
