import React, { useContext, useEffect, useState } from "react";
import { Roller } from "react-awesome-spinners";
import anime from "animejs/lib/anime.es";
import ObjectCalculator from "./object-calculator/ObjectCalculator";
import { CurrencyContext } from "CurrencyContext";
import {
  StatsHeading,
  StatsPrice,
  StatsCurrency,
  StatsBox
} from "styles/Styled";
import bitcoinLogo from "images/bitcoin-logo.png";

const CurrencyPrice = () => {
  const [calculatorObj, setCalculatorObj] = useState(undefined);
  const { updateCurrencyValue } = useContext(CurrencyContext);

  //* ******************************//
  //* ******************************//
  //* ******************************//
  const [tabViewer, setTabViewer] = useState({
    rates: {
      BTCUSDBuy: 0.0 // This is shown on landing
    }
  });

  const calculatorObserver = async data => {
    setTabViewer(data);
    updateCurrencyValue(parseFloat(data.rates.BTCUSDBuy));
  };

  const animateBox = () => {
    const tl = anime.timeline({
      easing: "linear"
    });

    tl.add({
      targets: ".StatsBox",
      translateX: -260,
      duration: 1,
      delay: 0,
      autoplay: true
    });
  };

  useEffect(() => {
    const calculatorObjTmp = new ObjectCalculator(calculatorObserver);
    calculatorObjTmp.convert();
    setCalculatorObj(calculatorObjTmp);
    setInterval(() => {
      calculatorObjTmp.convert();
    }, 10000);
    animateBox();
  }, []);

  return (


    <div class="box-prices">
    <div class="content-prices">
      <div class="column">
        <div class="title">
          Precios
        </div>
        <div class="subtitle">
          Actualizados en tiempo real
        </div>
      </div>

      <div class="column center">
        <div class="title">
          BITCOIN
        </div>


        {tabViewer.rates.BTCUSDBuy !== "8097.03" ? (
          <div class="subtitle blue">
            {tabViewer.rates.BTCUSDBuy} USD
          </div>
        ) : (
          <>
            <Roller />
            {/* <StatsCurrency>Loading...</StatsCurrency> */}
          </>
        )}

      </div>

    </div>
    </div>



  );
};

export default CurrencyPrice;
