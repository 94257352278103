import styled from "styled-components";
import colors from 'styles/common/colors';
import { Link } from "react-router-dom";

export const AuthContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  @media (max-width: 640px) {
    padding: 0 24px;
  }

  @media (max-width: 320px) {
    padding: 0 16px;
  }
`;

export const AuthBox = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AuthInput = styled.input`
  position: relative;
  height: 40px;
  max-width: 400px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
  margin: 10px 0;
  border: 1px solid #3C4A5B;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
  font-weight: 400;
  display: flex;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const AuthButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: ${colors.dark_blue_agente_btc};
  background: ${colors.yellow_agente_btc};
  font-family: Montserrat;
  font-weight: 600;
  width: 100%;
  height: 45px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: 2px 2px 3px rgba(44, 44, 44, 0.2);
  transition: box-shadow 0.3s;

  &:hover{
    box-shadow: 2px 2px 8px rgba(44, 44, 44, 0.2);
  }
  &:active{
    box-shadow: 2px 2px 3px rgba(44, 44, 44, 0.2);
  }

`

export const AuthText = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
`;

export const AuthLink = styled(Link)`
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue_agente_btc};
  font-size: 13px;
  text-decoration: none;
  &:hover{
    opacity: 0.8;
  }
`;

export const AuthForm = styled.form`
  width: 100%;
  display
`

export default {
  AuthContainer,
  AuthBox,
  AuthInput,
  AuthButton,
  AuthText,
  AuthLink,
  AuthForm
}
