import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
    Section,
    BTCPartnersBox,
    BTCPartners,
    BTCText,
    LogoPartner,
    Wrapper,
    Space,
    Heading2,
    BoxBitcoin,
    Card,
    CardHeading,
    Advantages,
    LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
    Header1,
    Header3,
    Paragraph,
    ParagraphSubtitle,
    TitleSection,
    Header1White,
    ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";

import heroImg from "media/heros/about_us@2x.png";
import imgLuis from "images/luis@2x.png";
import imgVictor from "images/victor@2x.png";
import imgCarolina from "images/carolina@2x.png";
import imgCristian from "images/cristian@2x.png";
import imgBrayan from "images/brayan@2x.png";
import imgFabian from "images/fabian@2x.png";

import logoBcp from "images/logo-bcp-w.png";
import logoBbva from "images/logo-bbva-w.png";
import logoInterbank from "images/logo-interbank-w.png";

import bitcoin from "media/icons/icon_bitcoin.png";
import fiat from "media/fiat@2x.png";
import time from "media/time@2x.png";

// Media images
import iconHouse from "media/icons/icon_house.png";
import iconJoin from "media/icons/icon_join.png";
import iconSecurity from "media/icons/icon_security.png";
import iconCurrency from "media/icons/icon_currency.png";

import heroGetStarted from "media/heros/get_started.png";
import heroWhyChoose from "media/heros/why_we_choose.png";
import heroUseCases from "media/heros/unlock_potential.png";

import bankLogos from "media/logo_banks.png";
import logoRecidar from "media/logo_recidar.png";
import logoUsil from "media/logo_usil.png";
import logoTasaTop from "media/logo_tasatop.png";

import waveGrayUp from "media/waves/wave_gray_up.svg";
import waveGrayDown from "media/waves/wave_gray_down.svg";
import waveBlueUp from "media/waves/wave_blue_up.svg";

import { Helmet } from "react-helmet";

import agentebtcapi from "agentebtcapi.js";

const Developers = ({ history, location }) => {
    window.dataLayer.push({
        event: "pageview",
        page: {
            url: location,
            title: "Developers",
        },
    });
    // Scroll to top on all pages
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Styled.AboutContainer>
                <Styled.ContainerSectionHeader>
                    <Styled.TaglineAbout>
                        <PrimaryText>Desarrolladores</PrimaryText>
                    </Styled.TaglineAbout>
                    <Styled.SubtaglineHome>
                        <PrimaryText>
                            ¡Developers, Developers, Developers...! Este es tu
                            lugar{" "}
                        </PrimaryText>
                    </Styled.SubtaglineHome>
                </Styled.ContainerSectionHeader>
            </Styled.AboutContainer>

            <Styled.ContentBoxedContainer>
                <Header3>Introducción</Header3>

                <Paragraph>
                    Creemos que los desarrolladores forman parte importante del
                    ecosistema de criptomonedas. Las soluciones y casos de uso
                    que se pueden implementar son múltiples. Desde integrar como
                    medio de pago a las monedas digitales en tu sitio web o
                    aplicación hasta automatizar procesos de compra/venta.
                </Paragraph>

                <Header3>Documentación y API REST (BETA)</Header3>

                <Paragraph>
                    AgenteBTC viene desarrollando una API REST, actualmente en
                    estado Beta cerrada. Si eres desarrolador y estas interesado
                    en probar nuestros servicios, ¡Eres bienvenido!. Todo apoyo
                    o sugerencia es necesario para ayudarnos a construir mejores
                    productos para ti. <br></br>
                    <br></br>
                    Escribenos a soporte@agentebtc.com para mayor información
                </Paragraph>

                <Styled.ContainerButtons>
                    {/*<Styled.StyledButtonYellowSec   target="_blank">
                     Solicitar acceso anticipado →
                </Styled.StyledButtonYellowSec>*/}
                </Styled.ContainerButtons>

                <Paragraph></Paragraph>
            </Styled.ContentBoxedContainer>

            <Styled.SectionWave src={waveBlueUp} />
            <Styled.SectionBlueBg>
                <Styled.WrapperCalculatorSection>
                    <Styled.ContainerAboutSection>
                        <Header1White>Súmate a la movida cripto </Header1White>
                        <ParagraphSubtitleWhite>
                            Registrate en AgenteBTC en minutos
                        </ParagraphSubtitleWhite>
                    </Styled.ContainerAboutSection>
                    <Styled.ContainerRight>
                        <Styled.ContainerButtons>
                            <Styled.StyledButtonYellowSecExt
                                href={`${agentebtcapi.webapp}/auth/register`}
                            >
                                Comenzar ahora :)
                            </Styled.StyledButtonYellowSecExt>
                        </Styled.ContainerButtons>
                    </Styled.ContainerRight>
                </Styled.WrapperCalculatorSection>
            </Styled.SectionBlueBg>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Desarrolladores | Agente BTC</title>
                <meta
                    name="description"
                    content="Experiencia fácil de integración Widget(monetiza tu web), Wallet(pasarela de pagos) y desarrollo sobre nuestra tecnología.Democratizar los bitcoins en Perú."
                />
            </Helmet>
        </>
    );
};

export default withRouter(Developers);
