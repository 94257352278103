import React from "react";
import { Link } from "react-router-dom";
import Accordion from "./Accordion";

function AccordionClient() {
  return (
    <>

      <h3>General</h3>

      <Accordion
        className="active"
        title="¿Por qué es necesario validar mi Identidad?"
      >
        <p>
          <ul>
          La validación de identidad nos permite garantizar la seguridad de nuestros usuarios y su información personal previniendo fraudes y/o suplantaciones de identidad.

          </ul>
        </p>

        <p>
          <ul>
          Esto además, nos asegura el cumplimiento de lo dispuesto en el Decreto de Urgencia No. 007-2020 que aprueba el Marco de Confianza Digital y establece medidas para su fortalecimiento, señalando que las empresas que presten servicios digitales se encuentran obligadas a implementar mecanismos para verificar la identidad de las personas que acceden a sus plataformas.
          </ul>
        </p>
      </Accordion>

      <Accordion
        className="active"
        title="¿Cuál es el horario de atención y que ocurre con operaciones realizadas fuera de ese horario?"
      >
        <p>
          <ul>
            El horario de atención para operaciones en la plataforma virtual es
            de lunes a viernes de 9:00 am a 7:00 pm, todas las operaciones
            ingresadas fuera de ese horario, serán procesadas el siguiente día
            útil a primera hora.
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿Cómo puedo obtener ayuda en tiempo real?">
        <p>
          <ul>
            Puede contactarnos al Whatsapp 962882082, inmediatamente lo
            atenderemos.
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿Cómo puedo comprar bitcoins virtualmente y en los puntos físicos?">
        <p>
          <ul>
            Para poder comprar bitcoins con AgenteBTC es indispensable estar
            registrado, puede realizar la compra desde nuestro sitio web o en
            alguno de nuestros socios de AgenteBTC. En caso de no estar
            registrado en la plataforma de AgenteBTC, en nuestras casas de
            cambio asociadas también se puede realizar el proceso de registro.
            Al finalizar la transacción le llegará un correo confirmando la
            operación realizada.
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿Cuánta comisión se cobra por transacción?">
        <p>
          <ul>
            Cobramos el 1% del total de cada transacción. Puede encontrar mayor
            información en{" "}
            <Link to="/comisiones" style={{ color: "orange" }}>
              Comisiones
            </Link>
            .
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿En que monedas puedo comprar criptomonedas?">
        <p>
          <ul>
            Por el momento, AgenteBTC realizamos transacciones en soles y
            dólares.
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿Por qué confiar en AgenteBTC?">
        <p>
          <ul>
            AgenteBTC no sólo es una exchange de bitcoins donde puedes comprar y
            vender de manera sencilla y segura, adicionalmente contamos con
            casas de cambio afiliadas como puntos físicos para realizar
            transacciones, en AgenteBTC te explicamos cómo puedes usar tus
            fondos virtuales.
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿Puedo tener varias wallets?">
        <p>
          <ul>
            Si. Sin embargo, para cada transacción solo será necesario una sola
            dirección de wallet.
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿Cual es el monto mínimo y máximo para comprar bitcoins en AgenteBTC?">
        <p>
          <ul>
            El monto mínimo de compra o venta es de 15 USD y el monto máximo es de 5000 USD por día.
          </ul>
        </p>
      </Accordion>


      <Accordion title="¿Cual es el monto mínimo y máximo de bitcoins para poder retirar en AgenteBTC?">
        <p>
          <ul>
            El monto mínimo de retiro es 0.0015 BTC y el máximo 1 BTC por operación. Es decir, minimamente necesitas 0.0015 BTC para enviar 
            a otras billeteras externas. 
          </ul>
        </p>
      </Accordion>

      <h3>Abonos y retiros de dinero</h3>


      <Accordion title="Sobre las tarifas interbancarias">
        <p>
          <ul>
          AgenteBTC no se responsabiliza de los costos interbancarios por las transferencias realizadas. Estos se
          detallarán automáticamente al momento de realizar la transacción y deberán ser asumidos por el usuario.

          <br></br>  

          Si quiere saber más acerca de las comisiones y costos que involucran una transferencia interbancaria inmediata o diferida puede consultar 
          <a target="_blank" href="https://www.transferenciasinterbancarias.pe/"> aquí</a>. 
          Consulte segun su entidad bancaria origen.
          </ul>
        </p>
      </Accordion>

      <Accordion title="Sobre el tiempo estimado de los bancos">
        <p>
          <ul>
          El tiempo estimado que demora la transferencia está sujeto al tiempo que demoren en llegar los fondos transferidos a las cuentas de AgenteBTC. Nosotros no tenemos ningún control sobre el tiempo que pueda llegar a demorar la transferencia de los fondos.
          AgenteBTC no realizará ninguna transacción sin antes haber recibido los fondos por parte del cliente. Por lo general, el tiempo total para completar la transacción no debería ser mayor a dos días hábiles. 
          
          <br></br> 

          Son días hábiles, los comprendidos de lunes a viernes, sin considerar feriados o festivos. Los sábados y los domingos se entienden como días inhábiles o no hábiles.

          
          </ul>
        </p>
      </Accordion>

      <Accordion title="Sobre la validación del depósito">
        <p>
          <ul>
          La validación del depósito se dará cuando recibamos en nuestras cuentas el dinero y lo validemos con el voucher de la operación.
          Una vez confirmada la operación en nuestras cuentas recibirás una confirmación vía mail y en nuestro portal web. El proceso de validación demora como máximo un día hábil.
          </ul>
        </p>
      </Accordion>

      <h3>Recomendaciones para compras y ventas</h3>

      <Accordion title="¿Cómo subir mi comprobante?">
        <p>
          <ul>
          Al realizar tu orden de compra, se te solicitará enviar el comprobante de la transacción de fondos realizada. Para hacer esto, adjunta el screenshot del voucher de la transacción en la ventana de confirmación de la orden de compra o también puedes enviarnos el comprobante de pago a soporte@agentebtc.com y en el Asunto colocar el número de la operación.
          </ul>
        </p>
      </Accordion>

      <Accordion title="¿Problemas con el Navegador?">
        <p>
          <ul>
          Para garantizar el óptimo funcionamiento de agentebtc.com, se recomienda contar la última versión del navegador de tu preferencia. Si aún así tienes problemas de navegación, te recomendamos eliminar la caché y las cookies del navegador. De persistir los problemas, te sugerimos contactarte con nosotros para evaluar la situación.
          </ul>
        </p>
      </Accordion>



      <Accordion title="¿Cómo eliminar tu caché?">
        <p>
        <ul><b>En Chrome:</b>
        <li>1. En la barra de herramientas de tu navegador, haz clic en Más.</li>
        <li>2. Coloca el cursor sobre la opción Más herramientas y, a continuación, haz clic en Borrar datos de navegación.</li>
        <li>3. En el cuadro “Borrar datos de navegación”, haz clic en las casillas de verificación de las opciones Imágenes y archivos almacenados en caché y Cookies y otros datos de sitios y complementos. Usa el menú en la parte superior para seleccionar la cantidad de datos que deseas borrar.</li>
        <li>4. Selecciona el origen de los tiempos para borrar todo.</li>
        <li>5. Haz clic en Borrar datos de navegación.</li>
        </ul>

        <ul>

        <b>En Mozilla Firefox:</b>
        <li>1. Haz clic en el botón de menú, elige la opción Historial y, por último, Limpiar el historial reciente.</li>
        <li>2. Establece el Intervalo de tiempo a borrar a Todo.</li>
        <li>3. Haz clic en la flecha que está al lado de Detalles para expandir la lista de elementos del historial.</li>
        <li>4. Selecciona Cookies y asegúrate de que no estén seleccionados otros elementos que desees mantener.</li>
        </ul>

        <ul>
        <b>
        En Internet Explorer:</b>
        <li>1. En Internet Explorer, selecciona el botón Herramientas , ve a Seguridad y selecciona Eliminar el historial de exploración.</li>
        <li>2. Activa la casilla Cookies y datos del sitio web y selecciona Eliminar</li>
        </ul>

        </p>
      </Accordion>







    </>
  );
}

export default AccordionClient;
